import React, {
  FunctionComponent,
  LazyExoticComponent,
  Suspense,
  lazy,
} from "react";
import { createBrowserRouter } from "react-router-dom";

import ToastProvider from "context/ToastProvider";
import LoadingSuspense from "components/LoadingSuspense";
import Dashboard from "pages/Dashboard";
import ProjectDetail from "pages/ProjectDetail";
import GitlabOAuthCallback from "routes/GitlabOAuthCallback";
import ProtectedRoute from "routes/ProtectedRoute";

const Loadable =
  (Component: FunctionComponent | LazyExoticComponent<any>) => (props: any) =>
    (
      <Suspense fallback={<LoadingSuspense />}>
        <Component {...props} />
      </Suspense>
    );

const PageNotFound = Loadable(lazy(() => import("./pages/404")));
const Login = Loadable(lazy(() => import("./pages/Login")));
const Logout = Loadable(lazy(() => import("./routes/Logout")));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ToastProvider>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </ToastProvider>
    ),
  },
  {
    path: "/projects/:projectId",
    element: (
      <ToastProvider>
        <ProtectedRoute>
          <ProjectDetail />
        </ProtectedRoute>
      </ToastProvider>
    ),
  },
  {
    path: "login",
    element: (
      <ToastProvider>
        <Login />
      </ToastProvider>
    ),
  },
  { path: "logout", element: <Logout /> },
  { path: "*", element: <PageNotFound /> },
  { path: "gitlab-callback", element: <GitlabOAuthCallback /> },
]);

export default router;

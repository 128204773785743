import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import { twMerge } from "tailwind-merge";

import ToastProvider from "context/ToastProvider";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

import "react-modern-drawer/dist/index.css";

interface Props {
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

const Root = ({ children, className, contentClassName }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <ToastProvider>
      <main className={twMerge(className, "flex min-h-screen flex-col")}>
        <div className="block lg:hidden">
          <Drawer
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            direction="left"
            lockBackgroundScroll
            size={320}
            overlayColor="#2C4258"
            overlayOpacity={0.38}
          >
            <Sidebar setIsMenuOpen={setIsMenuOpen} />
          </Drawer>
        </div>

        <TopBar toggleMenu={toggleMenu} />

        <div className="mt-16 flex flex-1 flex-row">
          <div className="hidden w-64 border-r lg:block">
            <Sidebar setIsMenuOpen={setIsMenuOpen} />
          </div>

          <div className={twMerge(contentClassName, "max-w-screen-2xl flex-1")}>
            {children}
          </div>
        </div>
      </main>
    </ToastProvider>
  );
};

export default Root;

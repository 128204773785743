import React from "react";
import { twMerge } from "tailwind-merge";

type TextFieldProps = {
  label: string;
  onChange: (value: string) => void;
  required?: boolean;
  value: string;
  errors?: string;
};

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, onChange, required, value, errors }, ref) => (
    <div className="flex flex-col">
      <label className="mb-2 text-sm font-semibold">
        <span className="text-slate opacity-70">{label}</span>
        {required && <span className="text-error-main">*</span>}
      </label>
      <input
        name={label}
        className={twMerge(
          "color-slate placeholder:color-slate-lighter h-10 rounded-lg border border-gray-300 px-3 py-2 text-base focus:outline-none",
          errors && "border-red-500"
        )}
        onChange={(event) => onChange(event.currentTarget.value)}
        ref={ref}
        type="text"
        value={value}
      />
      <span className="text-error-main">{errors}</span>
    </div>
  )
);

export default TextField;

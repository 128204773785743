import {
  Button,
  Popper,
  createFilterOptions,
  useAutocomplete,
} from "@mui/base";
import Avatar from "components/Avatar";
import { User } from "models";
import React from "react";
import { twMerge } from "tailwind-merge";

export type MultiselectOption = {
  label: string;
  // ***: to be able to add fields for filtering
  otherfilterLabels?: string[];
  key: string;
  sublabel?: string;
  icon?: string;
  user?: User;
  errored?: boolean;
} & Record<string, unknown>;

type MultiselectProps = {
  label: string;
  onChange: (value: MultiselectOption[]) => void;
  options: MultiselectOption[];
  value: MultiselectOption[];
  className?: string;
  optionsListClassName?: string;
  optionsAreCheckboxes?: boolean;
  addIconClassName?: string;
  isShowLabelAtTop?: boolean;
  isOptionsWithIcon?: boolean;
  isArrowDropdown?: boolean;
  isShowSelectedInInputField?: boolean;
};

const POPPER_MARGIN_BOTTOM_PX = 16;

const Multiselect = React.forwardRef<HTMLInputElement, MultiselectProps>(
  (
    {
      label,
      onChange,
      options,
      value,
      className,
      optionsListClassName,
      optionsAreCheckboxes = false,
      addIconClassName,
      isOptionsWithIcon = false,
      isShowLabelAtTop = true,
      isArrowDropdown = false,
      isShowSelectedInInputField = false,
    },
    _ref
  ) => {
    const filterOptions = createFilterOptions({
      matchFrom: "any",
      stringify: (option: MultiselectOption) =>
        `${option.label} ${option.sublabel} ${option.otherfilterLabels?.join(
          " "
        )}`,
    });

    const autocomplete = useAutocomplete<MultiselectOption, true>({
      componentName: "Multiselect",
      isOptionEqualToValue: (option, value) => option.key === value.key,
      multiple: true,
      onChange: () => {},
      options,
      value,
      filterOptions,
    });

    const anchorRef = React.useRef<HTMLDivElement>(null);

    const selectedOptionKeys = value.map((item) => item.key);

    const selectOption = (optionToSelect: MultiselectOption) => {
      if (selectedOptionKeys.includes(optionToSelect.key)) {
        return;
      }
      onChange([...value, optionToSelect]);
    };

    const unselectOption = (optionToUnselect: MultiselectOption) => {
      if (!selectedOptionKeys.includes(optionToUnselect.key)) {
        return;
      }
      onChange(
        value.filter(
          (selectedOption) => selectedOption.key !== optionToUnselect.key
        )
      );
    };

    const toggleOption = (optionToToggle: MultiselectOption) => {
      selectedOptionKeys.includes(optionToToggle.key)
        ? unselectOption(optionToToggle)
        : selectOption(optionToToggle);
    };

    const getPopperMaxHeight = () => {
      if (anchorRef.current === null) {
        return;
      }

      const popperAppearsAbove = getPopperPlacement() === "top-start";
      if (popperAppearsAbove) {
        return (
          anchorRef.current.getBoundingClientRect().top -
          POPPER_MARGIN_BOTTOM_PX
        );
      }

      return (
        window.innerHeight -
        anchorRef.current.getBoundingClientRect().bottom -
        POPPER_MARGIN_BOTTOM_PX
      );
    };

    const getPopperPlacement = () => {
      const inputHeight =
        anchorRef?.current?.getBoundingClientRect()?.height ?? 0;
      const threshold = inputHeight * 2;

      const remainingSpaceBelow =
        window.innerHeight -
        (anchorRef?.current?.getBoundingClientRect()?.bottom ?? 0);

      return remainingSpaceBelow < threshold ? "top-start" : "bottom-start";
    };

    const limitValueDisplayed = (item: MultiselectOption[]) => {
      if (!item) return "";
      const text = value.map((item) => item.label).join(", ");
      return text.length > 12 ? `${text.slice(0, 11)}...` : text;
    };

    return (
      <div>
        <div className="mb-1">
          {isShowLabelAtTop && (
            <label className="mb-2 text-sm font-semibold">
              <span className="text-slate opacity-70">{label}</span>
            </label>
          )}
        </div>
        <div
          {...autocomplete.getRootProps()}
          className="relative flex flex-col"
        >
          <div className="relative flex" ref={anchorRef}>
            <input
              {...autocomplete.getInputProps()}
              className={twMerge(
                "color-slate placeholder:color-slate-lighter h-10 max-w-[100%] grow rounded-lg border border-gray-300 px-3 py-2 text-sm focus:outline-none",
                className
              )}
              placeholder={label}
              type="text"
              value={
                isShowSelectedInInputField
                  ? limitValueDisplayed(value)
                  : undefined
              }
            />
            <Button
              {...autocomplete.getPopupIndicatorProps()}
              className={twMerge("absolute right-2 top-2", addIconClassName)}
            >
              <span
                className={twMerge(
                  "material-icons text-dark-900 text-opacity-70 transition-transform",
                  autocomplete.popupOpen && "rotate-180"
                )}
              >
                {isArrowDropdown ? "arrow_drop_down" : "add_circle_outline"}
              </span>
            </Button>
          </div>
        </div>
        {!isShowSelectedInInputField && (
          <div className="mt-3 flex flex-wrap justify-start gap-2 empty:hidden">
            {value.map((option) => (
              <div
                className={twMerge(
                  "relative flex h-8 items-center rounded-2xl border border-[#0C59AC80] bg-[#F9FBFE] p-10 py-1 pl-3.5 text-xs font-normal",
                  isOptionsWithIcon && "pl-1",
                  option.errored && "border-[#E01D6580] bg-[#FEDDE0]"
                )}
                key={option.key}
              >
                {isOptionsWithIcon && (
                  <Avatar
                    user={option.user}
                    width="w-6"
                    className="mr-2 h-6"
                    fontSize="text-base"
                  />
                )}
                <span className={option.errored ? "line-through" : ""}>
                  {option.label}
                </span>
                <span
                  className={twMerge(
                    "material-icons absolute right-1 cursor-pointer text-primary-900",
                    option.errored && "text-red-900"
                  )}
                  onClick={() => unselectOption(option)}
                >
                  cancel
                </span>
              </div>
            ))}
          </div>
        )}
        {anchorRef.current && (
          <Popper
            anchorEl={anchorRef.current}
            disablePortal
            open={autocomplete.popupOpen}
            slotProps={{
              root: {
                className:
                  "bg-white rounded-lg box-border z-[510] overflow-y-hidden shadow-dropdown",
              },
            }}
            placement={getPopperPlacement()}
            modifiers={[
              { name: "flip", enabled: false },
              { name: "preventOverflow", enabled: true },
            ]}
          >
            <ul
              {...autocomplete.getListboxProps()}
              className={twMerge("overflow-y-auto p-2", optionsListClassName)}
              style={{
                width: anchorRef.current.clientWidth,
                maxHeight: getPopperMaxHeight(),
              }}
            >
              {(autocomplete.groupedOptions as MultiselectOption[]).map(
                (option, index) => {
                  const optionProps = autocomplete.getOptionProps({
                    option,
                    index,
                  });

                  const optionIsSelected = selectedOptionKeys.includes(
                    option.key
                  );

                  const optionDisabled = !optionIsSelected && option.errored;
                  const highlightSelected =
                    optionIsSelected && !optionsAreCheckboxes;

                  return (
                    <li
                      {...optionProps}
                      className={twMerge(
                        "flex cursor-pointer flex-row items-center rounded-lg px-3 py-2",
                        highlightSelected && "bg-[#C8DA2B14]",
                        optionDisabled && "pointer-events-none text-slate/20"
                      )}
                      key={option.key}
                      onClick={(event) => {
                        if (optionDisabled) return;

                        toggleOption(option);
                        optionProps.onClick!(event);
                      }}
                    >
                      {optionsAreCheckboxes && (
                        <input
                          className="mr-2 flex h-5 w-5 shrink-0 cursor-pointer"
                          type="checkbox"
                          checked={optionIsSelected}
                          onChange={() => {}}
                          disabled={optionDisabled}
                        />
                      )}
                      {isOptionsWithIcon && (
                        <Avatar
                          user={option.user}
                          width="w-10"
                          className="mr-2 h-10"
                          fontSize="text-base"
                        />
                      )}
                      <div>
                        {option.label}
                        {option.sublabel && (
                          <>
                            <br />{" "}
                            <span className="text-xs">{option.sublabel}</span>
                          </>
                        )}
                      </div>
                    </li>
                  );
                }
              )}
              {autocomplete.groupedOptions.length === 0 && (
                <li className="truncate rounded-lg px-3 py-2">No results</li>
              )}
            </ul>
          </Popper>
        )}
      </div>
    );
  }
);

export default Multiselect;

import { twMerge } from "tailwind-merge";
import { ReactComponent as MahusaiLogo } from "assets/svg/Mahusai-Logo.svg";

type LoadingScreenProps = {
  height?: string;
  width?: string;
};

export default function LoadingScreen({
  height = "h-screen",
  width = "max-w-[82.5rem]",
}: LoadingScreenProps) {
  return (
    <div className={twMerge("mx-auto grid place-items-center", height, width)}>
      <div className="flex justify-center">
        <MahusaiLogo className="animate-pulse" width="98.4" height="48" />
      </div>
    </div>
  );
}

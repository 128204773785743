import React from "react";

import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";

type ProjectInfoHeaderActionsProps = {
  isArchived: boolean;
  isEditing: boolean;
  isSaving: boolean;
  onArchiveProject: () => void;
  onRestoreProject: () => void;
  onToggleEdit: () => void;
  onCancelEdit: () => void;
  onSubmitEdit: () => void;
};

const ProjectInfoHeaderActions = ({
  isArchived,
  isEditing,
  isSaving,
  onArchiveProject,
  onRestoreProject,
  onToggleEdit,
  onCancelEdit,
  onSubmitEdit,
}: ProjectInfoHeaderActionsProps) => (
  <div className="mt-6 flex gap-2 lg:mt-0">
    {isArchived && (
      <Button
        category="borderless"
        endIcon="restore"
        onClick={onRestoreProject}
      >
        Restore Project
      </Button>
    )}
    {!isArchived && isEditing && (
      <>
        <Button
          category="primaryOutline"
          disabled={isSaving}
          onClick={onCancelEdit}
        >
          Cancel
        </Button>
        <Button
          category="primary"
          disabled={isSaving}
          endIcon="save"
          onClick={onSubmitEdit}
        >
          Save
          {isSaving && <LoadingIndicator />}
        </Button>
      </>
    )}
    {!isArchived && !isEditing && (
      <>
        <Button
          category="borderless"
          className="text-danger-400"
          endIcon="delete"
          onClick={onArchiveProject}
        >
          Archive Project
        </Button>
        <Button category="primaryOutline" endIcon="edit" onClick={onToggleEdit}>
          Edit Details
        </Button>
      </>
    )}
  </div>
);

export default ProjectInfoHeaderActions;

import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Project } from "models";
import { useStore } from "stores";

import LoadingScreen from "components/LoadingScreen";
import Button from "components/Button";
import { ActivityLogType, TableColHeader } from "types";
import {
  formatDateDisplayed,
  formatTimeDisplayed,
  limitEmailDisplayed,
} from "utils";
import { useToast } from "components/toast/context";
import Avatar from "components/Avatar";

type Props = {
  project: Project;
};

const ProjectActivity = ({ project }: Props) => {
  const { projectStore, userStore } = useStore();
  const { showToastVariant } = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const [activitylogsList, setActivitylogsList] = useState<ActivityLogType[]>(
    []
  );

  const columnHeaders: TableColHeader[] = [
    { title: "User", fieldName: "user" },
    { title: "Activity", fieldName: "activity" },
    { title: "Date", fieldName: "createdAt" },
    { title: "Time", fieldName: null },
  ];
  const noAcitivityLogsList = !activitylogsList || !activitylogsList.length;

  const listProjectActivityLogs = async () => {
    setLoading(true);
    const response = await projectStore.listProjectActivityLogs(project._id);
    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: "Unable to fetch activity logs of the project",
      });
      return;
    }
    const logs = response.results as ActivityLogType[];
    const logsWithAvatarUrl = logs.map((item) => {
      item.user = userStore.getOrCreateUser(item.user);
      return item;
    });
    setActivitylogsList(logsWithAvatarUrl);

    setLoading(false);
  };

  const formatActivityDisplayed = (desc: string) => {
    const count = desc.split("\n").length;

    if (count <= 1) return desc;

    return desc.split("\n").map((str, idx) => (
      <p
        className={twMerge(
          "pb-2",
          idx === 0 && "pt-2",
          idx === count - 2 && "pb-0"
        )}
        key={idx}
      >
        {str}
      </p>
    ));
  };

  useEffect(() => {
    listProjectActivityLogs();
  }, []);

  return (
    <div className="overflow-hidden md:m-6 md:rounded-[16px] md:shadow-[0px_0px_0px_1px_rgba(93,107,131,0.05),0px_1px_2px_0px_rgba(93,107,131,0.12)]">
      <div className="overflow-x-auto">
        {loading ? (
          <LoadingScreen height="h-[7rem]" />
        ) : (
          <table className="w-max table-fixed md:w-full">
            <thead>
              <tr className="h-[54px] border-b border-solid border-[#E0E0E0] bg-[#F9FBFE] text-left">
                {columnHeaders.map((col) => (
                  <th
                    key={`th-${col.title}`}
                    className={twMerge(
                      "w-fit p-[1rem]",
                      col.title === "User" && "w-[299px]",
                      ["Date", "Time"].includes(col.title) && "w-[15%]"
                    )}
                  >
                    <Button
                      category="borderless"
                      className="flex w-[90%] justify-start bg-transparent px-0 text-dark-900 hover:opacity-100"
                      onClick={() => {}}
                    >
                      <div className="text-base">{col.title}</div>
                    </Button>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {noAcitivityLogsList ? (
                <tr className="border-t border-[#E0E0E0] bg-[#FFFFFF]">
                  <td colSpan={4}>
                    <div className="relative py-24">
                      <div>
                        <p className="mt-6 text-center text-sm font-semibold text-dark-900/[0.38]">
                          No activity log yet
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                activitylogsList.map((log: ActivityLogType) => (
                  <tr
                    key={log._id}
                    className="border-x md:border-x-0 md:border-y-2"
                  >
                    <td>
                      <a
                        href={`${process.env.REACT_APP_HR_PORTAL_WEBSITE_URL}/members/${log.user.hrPortalId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="flex items-center gap-2 p-4"
                      >
                        <div className="shrink-0">
                          <Avatar
                            user={log.user}
                            width="w-[34px]"
                            fontSize="text-base"
                          />
                        </div>
                        <div className="flex flex-col overflow-hidden">
                          <p className="truncate text-sm">
                            {log.user.fullName}
                          </p>
                          <div className="text-sm text-[#2C4258B2]">
                            {limitEmailDisplayed(log.user.mahusaiEmail)}
                          </div>
                        </div>
                      </a>
                    </td>
                    <td className="py-4 pl-4 text-left text-sm">
                      {formatActivityDisplayed(log.activity)}
                    </td>
                    <td className="py-4 pl-4 text-left text-sm">
                      {log.createdAt && formatDateDisplayed(log.createdAt)}
                    </td>
                    <td className="py-4 pl-4 text-left text-sm">
                      {log.createdAt && formatTimeDisplayed(log.createdAt)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ProjectActivity;

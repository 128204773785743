// import { computed } from "mobx";
import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  modelFlow,
  prop,
} from "mobx-keystone";

import {
  ErrorMessage,
  API_ERROR_MESSAGES,
  APIError,
} from "types/errors/APIError";

import User from "../models/User";
import fetchAPI from "../utils/fetchAPI";

@model("portal/AuthStore")
export default class AuthStore extends Model({
  isAuthenticated: prop<boolean>(false).withSetter(),
  isReady: prop<boolean>(false).withSetter(),
  currentUser: prop<User | undefined>().withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @modelAction
  reset = () => {
    this.currentUser = undefined;
    this.isAuthenticated = false;
    this.isReady = false;
    this.errors = [];
  };

  @modelAction
  removeAuthentication = () => {
    this.currentUser = undefined;
    this.isAuthenticated = false;
    this.isReady = true;
  };

  @modelFlow
  load = _async(function* (this: AuthStore) {
    const response = yield* _await(this.checkAuthentication());

    return response;
  });

  @modelFlow
  postLoginViaGitlab = _async(function* (this: AuthStore, code: string) {
    let response: Response;
    try {
      response = yield* _await(
        fetchAPI(`auth/gitlab/callback/?code=${code}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
      );
    } catch {
      this.errors.push({
        source: "postLoginViaGitlab",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Update failed", ok: false } as const;
    }
    if (!response.ok) {
      const responseData = yield* _await(response.json());
      this.setErrors([
        {
          source: "postLoginViaGitlab",
          title: "Login failed",
          message: responseData.error,
        },
      ]);

      return {
        details: "Could not obtain a token with the given code",
        ok: false,
      };
    }
    yield* _await(this.checkAuthentication());
    return { details: "Successfully logged in", ok: true };
  });

  @modelFlow
  checkAuthentication = _async(function* (this: AuthStore, date?: string) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI("users/profile"));
    } catch {
      this.errors.push({
        source: "checkAuthentication",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Check authentication failed", ok: false };
    }

    if (!response.ok) {
      this.removeAuthentication();
      return {
        details: "Could not authenticate with current credentials",
        ok: false,
      };
    }

    try {
      const user = yield* _await(response.json());
      const newUser = new User({
        ...user,
        id: user?._id,
      });

      this.setCurrentUser(newUser);
    } catch (error) {
      console.error("[DEBUG] could not parse json");
      this.removeAuthentication();
      return { details: error, ok: false };
    }

    this.setIsReady(true);
    this.setIsAuthenticated(true);
    return { details: "Sucessfully authenticated", ok: true };
  });

  @modelFlow
  postLogout = _async(function* (this: AuthStore) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI("logout/"));
    } catch {
      this.errors.push({
        source: "postLogout",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Logout failed", ok: false };
    }

    if (!response.ok) {
      return { details: "Logout failed with current credentials", ok: false };
    }

    this.removeAuthentication();

    return { details: "Successfully logged out", ok: true };
  });
}

import { SelectOption } from "components/inputs/Select";

export enum MemberRoles {
  Designer = "Designer",
  LeadDesigner = "Lead Designer",
  Engineer = "Engineer",
  LeadEngineer = "Lead Engineer",
  ProductManager = "Product Manager",
  LeadProductManager = "Lead Product Manager",
}

export const shortenedRoleMap = new Map([
  [MemberRoles.Designer, "DSG"],
  [MemberRoles.LeadDesigner, "DSG - L"],
  [MemberRoles.Engineer, "ENG"],
  [MemberRoles.LeadEngineer, "ENG - L"],
  [MemberRoles.ProductManager, "PM"],
  [MemberRoles.LeadProductManager, "PM - L"],
]);

export enum ProjectStatus {
  Active = "Active",
  Proposal = "Proposal",
  Completed = "Completed",
  Archived = "Archived",
}

export enum ProjectClassification {
  Global = "GLOBAL",
  PH = "PH",
}

export const projectClassificationOptions: SelectOption[] = [
  {
    label: "Mahusai Global",
    key: ProjectClassification.Global,
  },
  {
    label: "Mahusai PH",
    key: ProjectClassification.PH,
  },
];

import { Dispatch, SetStateAction, useState } from "react";
import { twMerge } from "tailwind-merge";

import DateRangePicker, {
  CustomInput,
} from "components/dateRangePicker/DateRangePicker";
import MultiSelect, { MultiselectOption } from "components/inputs/Multiselect";

import { formatDateRange } from "components/dateRangePicker/dates";
import {
  InvoiceOptionalColumn,
  InvoiceSplitOption,
  invoiceSplitOptions,
  visibleColumnOptions,
} from "constants/invoice";
import { ProjectInvoiceSettings } from "types";

type InvoiceGeneratorProps = {
  invoiceSettings: ProjectInvoiceSettings;
  setInvoiceSettings: Dispatch<SetStateAction<ProjectInvoiceSettings>>;
};

const ProjectInvoiceGenerator = ({
  invoiceSettings,
  setInvoiceSettings,
}: InvoiceGeneratorProps) => {
  const { startDate, endDate, splitType, visibleColumns } = invoiceSettings;

  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);

  const updateInvoiceSettings = (update: Partial<ProjectInvoiceSettings>) => {
    setInvoiceSettings((prev) => ({ ...prev, ...update }));
  };

  const disableRateForServiceOnly = ({
    selectedSplit,
    selectedColumns,
  }: {
    selectedSplit?: MultiselectOption[];
    selectedColumns?: MultiselectOption[];
  }) => {
    const invoiceSplit = selectedSplit || splitType;
    const columns = selectedColumns || visibleColumns;
    const invoiceIsServiceOnly =
      invoiceSplit?.map((e) => e.key).toString() === InvoiceSplitOption.Service;

    if (invoiceIsServiceOnly) {
      const columnsWithDisabledRate = columns?.map((e) =>
        e.key === InvoiceOptionalColumn.Rate ? { ...e, errored: true } : e
      );

      updateInvoiceSettings({
        visibleColumns: columnsWithDisabledRate,
      });
    } else {
      const allEnabledColumns = columns?.map((e) => ({
        ...e,
        errored: false,
      }));
      updateInvoiceSettings({ visibleColumns: allEnabledColumns });
    }
  };

  return (
    <div className="flex w-full justify-center">
      <div className="flex w-[42vw] flex-col gap-[24px]">
        <div className="flex flex-col overflow-y-auto overflow-x-hidden">
          <label className="mb-2 text-sm font-semibold">
            <span className="text-left text-slate opacity-70">
              Invoice Date Range
            </span>
          </label>
          <CustomInput
            className={twMerge("md:flex", isShowDatePicker && "hidden")}
            value={formatDateRange(startDate, endDate)}
            placeholder="Invoice Date Range"
            onClick={() => setIsShowDatePicker(!isShowDatePicker)}
          />
          {isShowDatePicker && (
            <div className="m-auto w-full items-center">
              <DateRangePicker
                onSubmit={(start, end) => {
                  setIsShowDatePicker(false);

                  updateInvoiceSettings({
                    startDate: start,
                    endDate: end,
                  });
                }}
                onCancel={() => setIsShowDatePicker(false)}
              />
            </div>
          )}
        </div>
        <div>
          <MultiSelect
            label="Invoice split"
            onChange={(value) => {
              updateInvoiceSettings({
                splitType: value,
              });
              disableRateForServiceOnly({ selectedSplit: value });
            }}
            options={invoiceSplitOptions}
            value={invoiceSettings.splitType || []}
            optionsAreCheckboxes={true}
          />
        </div>
        <div>
          <MultiSelect
            label="Details included"
            onChange={(value) => {
              updateInvoiceSettings({
                visibleColumns: value,
              });
              disableRateForServiceOnly({ selectedColumns: value });
            }}
            options={visibleColumnOptions}
            value={invoiceSettings.visibleColumns || []}
            optionsAreCheckboxes={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectInvoiceGenerator;

import { twMerge } from "tailwind-merge";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  category:
    | "primary"
    | "secondary"
    | "borderless"
    | "danger"
    | "primaryOutline"
    | "dangerOutline"
    | "neutral"
    | "multiDelete"
    | "multiReject"
    | "multiApprove";
  icon?: string;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
};

const categoryClasses: Record<ButtonProps["category"], string> = {
  primary: "bg-primary-900 text-white hover:opacity-90",
  primaryOutline:
    "border border-primary-900/50 text-primary-900 bg-transparent hover:opacity-70",
  secondary: "bg-primary-900 text-white",
  borderless: "bg-white text-primary-900 hover:opacity-70",
  neutral: "border border-dark-900/[0.7] text-dark-900/[.7] hover:opacity-70",
  danger: "bg-danger-400 text-white hover:opacity-90",
  dangerOutline: "border border-danger-400 text-danger-400 hover:opacity-90",
  multiDelete:
    "border border-[#FDE2D1] text-[#FDE2D1] disabled:border-[#FDE2D1] disabled:text-[#FDE2D1] disabled:opacity-60",
  multiReject:
    "bg-[#FDE2D1] text-danger-400 disabled:bg-[#FDE2D1] disabled:text-danger-400 disabled:opacity-60",
  multiApprove:
    "bg-[#C8DA2B] text-dark-900 disabled:bg-[#C8DA2B] disabled:text-dark-900 disabled:opacity-60",
};

export default function Button({
  category,
  children,
  className,
  icon,
  endIcon,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      className={twMerge(
        "flex items-center justify-center gap-3 rounded-full px-4 py-[.375rem] text-center text-sm font-semibold disabled:pointer-events-none disabled:bg-black/[.12] disabled:text-black/[.26]",
        categoryClasses[category],
        className
      )}
    >
      {icon && <span className="material-icons text-sm">{icon}</span>}
      {children}
      {endIcon && <span className="material-icons text-sm">{endIcon}</span>}
    </button>
  );
}

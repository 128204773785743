import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  modelFlow,
  prop,
} from "mobx-keystone";

import AuthStore from "./AuthStore";
import IndustryStore from "./IndustryStore";
import ProjectStore from "./ProjectStore";
import TechStackStore from "./TechStackStore";
import UserStore from "./UserStore";
import ProjectSearchFilter from "models/ProjectSearchFilter";

@model("client/RootStore")
export default class RootStore extends Model({
  authStore: prop<AuthStore>(),
  industryStore: prop<IndustryStore>(),
  projectStore: prop<ProjectStore>(),
  techStackStore: prop<TechStackStore>(),
  userStore: prop<UserStore>(),
  isReady: prop<boolean>(false).withSetter(),
}) {
  @modelFlow
  load = _async(function* (this: RootStore) {
    yield* _await(this.authStore.load());
    this.setIsReady(true);
  });

  @modelAction
  reset = () => {
    this.authStore.reset();
    this.authStore = new AuthStore({});
    this.industryStore = new IndustryStore({});
    this.projectStore = new ProjectStore({
      searchFilters: new ProjectSearchFilter({}),
    });
    this.techStackStore = new TechStackStore({});
    this.userStore = new UserStore({});
  };
}

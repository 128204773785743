import {
  Option as BaseOption,
  OptionProps as BaseOptionProps,
  Select as BaseSelect,
  prepareForSlot,
} from "@mui/base";
import React from "react";
import { twMerge } from "tailwind-merge";

type ButtonProps = React.HTMLProps<HTMLButtonElement>;
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <button
      {...props}
      className={twMerge(
        "color-slate h-13 flex min-w-[120px] max-w-[100%] grow justify-between rounded-lg border border-gray-300 px-3 pb-2 pt-3 text-left text-sm text-slate focus:outline-none 2xl:h-10 2xl:min-w-[190px] 2xl:pb-3 2xl:pt-2 2xl:text-base",
        typeof props.children === "string" &&
          props.children.includes(" ") &&
          "pb-5 2xl:pb-3"
      )}
      ref={ref}
      type="button"
    >
      {props.children}
      <span className="material-icons">arrow_drop_down</span>
    </button>
  )
);
const ButtonSlot = prepareForSlot(Button);

type UListProps = React.HTMLProps<HTMLUListElement>;
const Listbox = React.forwardRef<HTMLUListElement, UListProps>((props, ref) => (
  <ul
    {...props}
    className="z-[510] box-border min-w-[120px] overflow-y-hidden rounded-lg bg-white p-2 text-sm shadow-dropdown 2xl:min-w-[190px]"
    ref={ref}
  >
    {props.children}
  </ul>
));
const ListboxSlot = prepareForSlot(Listbox);

type OptionProps = BaseOptionProps<string>;
const Option: React.FC<OptionProps> = (props) => (
  <BaseOption
    {...props}
    slotProps={{
      root: { className: "cursor-pointer truncate rounded-lg px-3 py-2" },
    }}
  />
);

type SelectProps = {
  onChange: (newValue: string) => void;
  options: { label: string; value: string }[];
  value: string;
};
export const Select: React.FC<SelectProps> = ({ onChange, options, value }) => (
  <BaseSelect
    slots={{ root: ButtonSlot, listbox: ListboxSlot }}
    onChange={(_, newValue) => onChange(newValue!)}
    value={value}
    defaultValue={options[0].value}
  >
    {options.map(({ label, value }) => (
      <Option key={value} label={label} value={value}>
        {label}
      </Option>
    ))}
  </BaseSelect>
);

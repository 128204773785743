import { useRef } from "react";
import { twMerge } from "tailwind-merge";

import { Project, ProjectSearchFilter } from "models";

import Button from "components/Button";
import OverflowContainer, {
  OverflowButton,
} from "components/OverflowContainer";
import { LeftArrowIcon, RightArrowIcon } from "../Icons";

type FooterProps = {
  projectsList: Project[];
  allProjectsInStore: number;
  filters: ProjectSearchFilter;
  filterListedProject: (
    inFilterModal?: boolean
  ) => Promise<Project[] | undefined>;
};

const ProjectsTableFooter = ({
  projectsList,
  allProjectsInStore,
  filters,
  filterListedProject,
}: FooterProps) => {
  const { rowsPerPage, currentPage } = filters;

  const rowsRef = useRef(null);

  const setRows = (rowNum: number) => {
    filters?.setRowsPerPage(rowNum);
    // @ts-ignore
    rowsRef?.current?.click();
  };

  const startIdx =
    projectsList.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0;
  const endIdx =
    rowsPerPage > 0
      ? Math.min(rowsPerPage * currentPage, allProjectsInStore)
      : allProjectsInStore;

  const noProjects = !projectsList?.length;

  const disablePrevButton = currentPage <= 1 || noProjects;
  const disableNextButton = endIdx >= allProjectsInStore || noProjects;

  const pageLimitOptions = [10, 50, 100];

  return (
    <tfoot className="hidden md:table-footer-group">
      <tr className="h-16 border-t">
        <td colSpan={6}>
          <div className="flex items-center justify-end">
            <span
              className={twMerge(
                "pr-4 text-sm text-dark-900",
                noProjects && "opacity-[0.38]"
              )}
            >
              Rows per page:
            </span>

            <div className="pr-4">
              <OverflowContainer
                disabled={noProjects}
                buttonContent={
                  <div ref={rowsRef} className="flex flex-row">
                    <span
                      className={twMerge(
                        "text-base font-semibold text-dark-900",
                        noProjects && "opacity-[0.38]"
                      )}
                    >
                      {rowsPerPage > 0 ? rowsPerPage : "All"}
                    </span>
                    <span
                      className={twMerge(
                        "material-icons-outlined text-dark-900",
                        noProjects && "opacity-[0.38]"
                      )}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                }
                popperProps={{
                  placement: "bottom",
                }}
              >
                {pageLimitOptions.map((limit) => (
                  <OverflowButton
                    className="flex w-full items-center text-center font-normal text-dark-900"
                    onClick={() => setRows(limit)}
                  >
                    {limit.toString()}
                  </OverflowButton>
                ))}
                <OverflowButton
                  className="flex w-full items-center text-center font-normal text-dark-900"
                  // ***: set rows to 0 to fetch all
                  onClick={() => setRows(0)}
                >
                  All
                </OverflowButton>
              </OverflowContainer>
            </div>
            <span
              className={twMerge(
                "pr-4 text-sm text-dark-900",
                noProjects && "opacity-[0.38]"
              )}
            >
              {startIdx}-{endIdx} of {allProjectsInStore}
            </span>
            <Button
              disabled={disablePrevButton}
              category="borderless"
              className="flex w-12 items-center disabled:bg-transparent"
              onClick={() => {
                if (currentPage <= 1) return;
                filters.setCurrentPage(currentPage - 1);
                filterListedProject();
              }}
            >
              <LeftArrowIcon disabled={disablePrevButton} />
            </Button>
            <Button
              disabled={disableNextButton}
              category="borderless"
              className="mr-2 flex w-12 items-center disabled:bg-transparent"
              onClick={() => {
                if (disableNextButton) return;
                filters.setCurrentPage(currentPage + 1);
                filterListedProject();
              }}
            >
              <RightArrowIcon disabled={disableNextButton} />
            </Button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default ProjectsTableFooter;

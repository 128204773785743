import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import MemberTableRowDesktop from "./TableRowDesktop";

import Button from "components/Button";
import OverflowContainer, {
  OverflowButton,
} from "components/OverflowContainer";

import { TableProps } from "types";
import { membersTableColumnHeaders } from "../common";
import { ProjectMember } from "types";

const TableDesktop: React.FC<TableProps> = ({
  members,
  openRateHistoryModal,
  openRemoveMemberModal,
  setMember,
  colsArrowDown,
  selectedSortFilter,
  fetchAndUpdateMembersList,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [displayedMembers, setDisplayedMembers] = useState<ProjectMember[]>(
    members.slice(0, rowsPerPage)
  );

  const [hideArrow, setHideArrow] = useState<boolean[]>(
    Array(membersTableColumnHeaders.length - 1).fill(true)
  );

  const rowsRef = useRef(null);

  const setRows = (rowNum: number) => {
    setRowsPerPage(rowNum);
    // @ts-ignore
    rowsRef?.current?.click();
  };

  const updateHideArrowDown = (idx: number) => {
    const value = membersTableColumnHeaders.map((_, i) =>
      i !== idx ? true : false
    );
    setHideArrow(value);
  };

  const startIdx = members.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0;
  const endIdx = Math.min(rowsPerPage * currentPage, members.length);

  const disablePrevButton = currentPage <= 1;
  const disableNextButton = endIdx >= members.length;
  const noMembersList = !members.length;

  const RightArrowIcon = () => (
    <span
      className={twMerge(
        "material-icons-outlined text-dark-900",
        (noMembersList || disableNextButton) && "opacity-[0.38]"
      )}
    >
      chevron_right
    </span>
  );
  const LeftArrowIcon = () => (
    <span
      className={twMerge(
        "material-icons-outlined text-dark-900",
        (noMembersList || disablePrevButton) && "opacity-[0.38]"
      )}
    >
      chevron_left
    </span>
  );

  useEffect(() => {
    setDisplayedMembers(members.slice(startIdx - 1, endIdx));
    // eslint-disable-next-line
  }, [members, currentPage, rowsPerPage]);

  return (
    <div className="hidden overflow-x-auto md:block">
      <table className="w-full table-fixed">
        <thead>
          <tr className="h-[54px] border-b border-solid border-[#E0E0E0] bg-[#F9FBFE] text-left">
            {membersTableColumnHeaders.map((col, idx) => (
              <th
                key={`th-${col.title}`}
                className={twMerge(
                  "p-[1rem]",
                  col.title === "Name" && "w-[299px]",
                  col.title === "Actions" && "w-[10%]"
                )}
              >
                <Button
                  category="borderless"
                  className={twMerge(
                    "flex h-[1.5rem] w-[90%] items-center justify-between bg-transparent p-0 text-dark-900 hover:opacity-100",
                    col.title === "Actions" &&
                      "w-full justify-center hover:cursor-auto",
                    ["Position", "Role"].indexOf(col.title) >= 0 &&
                      "min-w-[80px]"
                  )}
                  onClick={() =>
                    !!fetchAndUpdateMembersList &&
                    fetchAndUpdateMembersList(col, idx)
                  }
                  onMouseEnter={() => updateHideArrowDown(idx)}
                  onMouseLeave={() =>
                    setHideArrow(Array(hideArrow.length).fill(true))
                  }
                >
                  {col.title}
                  {col.title !== "Actions" && (
                    <span
                      className={twMerge(
                        "material-icons-outlined text-2xl",
                        hideArrow[idx] &&
                          col.fieldName !== selectedSortFilter &&
                          "opacity-0"
                      )}
                    >
                      {!!colsArrowDown && colsArrowDown[idx]
                        ? "arrow_drop_down"
                        : "arrow_drop_up"}
                    </span>
                  )}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedMembers.map((member) => (
            <MemberTableRowDesktop
              key={member._id}
              member={member}
              openRateHistoryModal={openRateHistoryModal}
              openRemoveMemberModal={openRemoveMemberModal}
              setMember={setMember}
            />
          ))}
        </tbody>
        <tfoot className="hidden md:table-footer-group">
          <tr className="h-16 border-t">
            <td colSpan={5}>
              <div className="flex items-center justify-end">
                <span
                  className={twMerge(
                    "pr-4 text-sm text-dark-900",
                    noMembersList && "opacity-[0.38]"
                  )}
                >
                  Rows per page:
                </span>

                <div className="pr-4">
                  <OverflowContainer
                    disabled={noMembersList}
                    buttonContent={
                      <div ref={rowsRef} className="flex flex-row">
                        <span
                          className={twMerge(
                            "text-base font-semibold text-dark-900",
                            noMembersList && "opacity-[0.38]"
                          )}
                        >
                          {rowsPerPage}
                        </span>
                        <span
                          className={twMerge(
                            "material-icons-outlined text-dark-900",
                            noMembersList && "opacity-[0.38]"
                          )}
                        >
                          arrow_drop_down
                        </span>
                      </div>
                    }
                    popperProps={{
                      placement: "bottom",
                    }}
                  >
                    <OverflowButton
                      className="z-50 flex w-full items-center text-center font-normal text-dark-900"
                      onClick={() => setRows(10)}
                    >
                      10
                    </OverflowButton>
                    <OverflowButton
                      className="flex w-full items-center text-center font-normal text-dark-900"
                      onClick={() => setRows(50)}
                    >
                      50
                    </OverflowButton>
                    <OverflowButton
                      className="flex w-full items-center text-center font-normal text-dark-900"
                      onClick={() => setRows(100)}
                    >
                      100
                    </OverflowButton>
                  </OverflowContainer>
                </div>
                <span
                  className={twMerge(
                    "pr-4 text-sm text-dark-900",
                    noMembersList && "opacity-[0.38]"
                  )}
                >
                  {startIdx}-{endIdx} of {members.length}
                </span>
                <Button
                  disabled={disablePrevButton}
                  category="borderless"
                  className="flex w-12 items-center disabled:bg-transparent"
                  onClick={() => {
                    if (currentPage <= 1) return;
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  <LeftArrowIcon />
                </Button>
                <Button
                  disabled={disableNextButton}
                  category="borderless"
                  className="mr-2 flex w-12 items-center disabled:bg-transparent"
                  onClick={() => {
                    if (disableNextButton) return;
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  <RightArrowIcon />
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableDesktop;

import React from "react";

import { ToastVariant, VariantButtonHandler } from "./ToastVariants";

export type ToastProps = {
  timeoutDuration?: number;
  parentClassName?: string;
  useToastFunc: () => ToastContextData;
} & (
  | {
      simpleMessage: string;
      children?: undefined;
    }
  | {
      simpleMessage?: undefined;
      children: React.ReactNode;
    }
);

export type VariantProps = {
  variant: ToastVariant;
  title: string;
  subtitle?: string;
  button?: VariantButtonHandler;
  timeoutDuration?: number;
};

export type ToastContextData = {
  showToast: (props: ToastProps, timeoutDuration?: number) => void;
  showToastVariant: (props: VariantProps) => void;
  isToastShown: boolean;
  setIsToastShown: React.Dispatch<React.SetStateAction<boolean>>;
};

// In order for toasts to have links that work, then they must be
// rendered within a react-router browser router, but toasts are also
// needed at the root of an app for unrecoverable errors such as not
// being able to connect to the server
export const RootToastContext = React.createContext<ToastContextData>(
  {} as ToastContextData
);
export const useRootToast = () => React.useContext(RootToastContext);
export const { Provider: RootToastContextProvider } = RootToastContext;

export const ToastContext = React.createContext<ToastContextData>(
  {} as ToastContextData
);
export const useToast = () => React.useContext(ToastContext);
export const { Provider: ToastContextProvider } = ToastContext;

import "./App.css";
import { RouterProvider } from "react-router-dom";

import { useInit } from "hooks/useInit";
import router from "router";

import { StoreProvider } from "./stores";

function App() {
  const { store, ready } = useInit();

  if (!ready || !store) {
    // return <LoadingScreen />;
    return null;
  }

  return (
    <StoreProvider value={store}>
      <RouterProvider router={router} />
    </StoreProvider>
  );
}

export default App;

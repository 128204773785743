import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { MembersFilter, ProjectMember, TableColHeader } from "types";

import { Project } from "models";
import { useStore } from "stores";

import { useToast } from "components/toast/context";

import TableMobile from "./mobile";
import TableDesktop from "./desktop";
import TableFilters from "./TableFilters";
import RateHistoryModal from "components/modals/RateHistoryModal";
import RemoveMemberModal from "components/modals/RemoveMemberModal";
import LoadingScreen from "components/LoadingScreen";

import { membersTableColumnHeaders } from "./common";
import { timeout } from "utils";

type TableRootProps = {
  project: Project;
};

const TableRoot: React.FC<TableRootProps> = ({ project }) => {
  const { projectStore, userStore } = useStore();
  const { showToastVariant } = useToast();

  const { isEditing } = project;
  const { loading: projectStoreLoading } = projectStore;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [nameFilter, setNameFilter] = React.useState<string>("");
  const [member, setMember] = React.useState<ProjectMember>();
  const [membersData, setMembersData] = React.useState<ProjectMember[]>([]);

  const [rateHistoryModalIsOpen, setRateHistoryModalIsOpen] =
    React.useState<boolean>(false);
  const [removeMemberModalIsOpen, setRemoveMemberModalIsOpen] =
    React.useState<boolean>(false);

  const openRateHistoryModal = () => setRateHistoryModalIsOpen(true);
  const openRemoveMemberModal = () => setRemoveMemberModalIsOpen(true);

  const listProjectMembers = async (filters?: MembersFilter) => {
    const response = await projectStore.listProjectMembers(
      project._id,
      filters
    );
    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: "Unable to fetch members of the project",
      });
      return;
    }
    const sortedMembers = response.results as ProjectMember[];
    const newData = addProjectAndUserInfo(sortedMembers);
    setMembersData(newData);
  };

  const addProjectAndUserInfo = (
    membersList: ProjectMember[] | null
  ): ProjectMember[] =>
    (membersList ?? [])
      .filter((member) => !member.user.isDeactivated)
      .map((member) => {
        const populatedUser = userStore.getOrCreateUser(member.user);
        member.user = populatedUser;
        member.projectId = project?._id;
        return member;
      })
      .filter(({ user: { fullName } }) =>
        fullName?.toLocaleLowerCase().includes(nameFilter.toLowerCase())
      );

  const [selectedSortFilter, setSelectedSortFilter] = useState<string>("");
  const [colsArrowDown, setColsArrowDown] = useState<boolean[]>(
    Array(membersTableColumnHeaders.length - 1).fill(true)
  );
  const [filters, setFilters] = useState<MembersFilter>();

  const updateColsArrowDown = (idx: number) => {
    const value = membersTableColumnHeaders.map((_, i) =>
      i !== idx ? true : !colsArrowDown[idx]
    );
    setColsArrowDown(value);
  };

  const fetchAndUpdateMembersList = (col: TableColHeader, idx: number) => {
    updateColsArrowDown(idx);
    if (!col.fieldName) return;

    let newFilters: MembersFilter = {};
    // @ts-ignore
    newFilters[col.fieldName] = colsArrowDown[idx] ? "1" : "-1";
    setFilters(newFilters);
    setSelectedSortFilter(col.fieldName);
  };

  useEffect(() => {
    listProjectMembers(filters);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (projectStoreLoading) {
      setLoading(true);
      return;
    }

    (async () => {
      await timeout(1000);
      await listProjectMembers(filters);
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [projectStoreLoading]);

  useEffect(() => {
    if (isEditing) return;

    const nonProxyMembers = toJS(project.members);
    const newData = addProjectAndUserInfo(nonProxyMembers);

    setMembersData(newData);
    // eslint-disable-next-line
  }, [project.members, isEditing, nameFilter]);

  return (
    <>
      <div className="overflow-hidden md:m-6 md:rounded-[16px] md:shadow-[0px_0px_0px_1px_rgba(93,107,131,0.05),0px_1px_2px_0px_rgba(93,107,131,0.12)]">
        <hr className="my-3 md:hidden" />
        <TableFilters
          isEditing={isEditing}
          setNameFilter={setNameFilter}
          columnHeaders={membersTableColumnHeaders}
          fetchAndUpdateMembersList={fetchAndUpdateMembersList}
        />
        {loading ? (
          <LoadingScreen height="h-[7rem]" />
        ) : (
          <>
            <TableDesktop
              members={membersData}
              listProjectMembers={listProjectMembers}
              openRateHistoryModal={openRateHistoryModal}
              openRemoveMemberModal={openRemoveMemberModal}
              setMember={setMember}
              colsArrowDown={colsArrowDown}
              selectedSortFilter={selectedSortFilter}
              fetchAndUpdateMembersList={fetchAndUpdateMembersList}
            />
            <TableMobile
              members={membersData}
              listProjectMembers={listProjectMembers}
              openRateHistoryModal={openRateHistoryModal}
              openRemoveMemberModal={openRemoveMemberModal}
              setMember={setMember}
            />
          </>
        )}
      </div>
      <RateHistoryModal
        open={rateHistoryModalIsOpen}
        setOpen={setRateHistoryModalIsOpen}
        user={member?.user}
      />
      <RemoveMemberModal
        open={removeMemberModalIsOpen}
        setOpen={setRemoveMemberModalIsOpen}
        projectId={project._id}
        memberToBeRemoved={member}
      />
    </>
  );
};

export default observer(TableRoot);

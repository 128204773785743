import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  setIsMenuOpen: (open: boolean) => void;
}

const Sidebar = ({ setIsMenuOpen }: Props) => (
  <>
    <div className="flex items-center justify-end px-6 py-2 lg:hidden">
      <button
        className="material-icons py-3 text-dark-900/70"
        onClick={() => setIsMenuOpen(false)}
      >
        close
      </button>
    </div>
    <nav className="sticky pt-4 lg:top-16 lg:pt-6">
      <ul>
        <li onClick={() => setIsMenuOpen(false)}>
          <div className="px-4">
            <NavLink
              to=""
              className={({ isActive }) =>
                `${
                  isActive
                    ? "bg-primary-50 text-primary-900"
                    : "text-dark-900/70"
                } flex flex-row items-center gap-2 rounded-lg py-3 pl-4 pr-2 text-sm font-semibold leading-6 hover:bg-blue-lighter lg:px-[.875rem] lg:py-4`
              }
              children={({ isActive }) => (
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <span
                      className={`text-lg material-icons${
                        isActive ? "" : "-outlined"
                      }`}
                    >
                      dashboard
                    </span>
                    Projects
                  </div>
                </div>
              )}
            />
          </div>
        </li>
      </ul>
      {/* <ul>
        <li onClick={() => setIsMenuOpen(false)}>
          <div className="px-4">
            <a
              href="#"
              target="_blank"
              className="flex flex-row items-center gap-2 rounded-lg py-3 pl-4 pr-2 text-sm font-semibold leading-6 text-dark-900/70 hover:bg-blue-lighter lg:px-[.875rem] lg:py-4"
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="material-icons-outlined text-lg">
                    person_2
                  </span>
                  <span>Profile</span>
                </div>
              </div>
            </a>
          </div>
        </li>
      </ul> */}
    </nav>
  </>
);

export default Sidebar;

import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

import MultiselectGrouped from "components/inputs/MultiselectGrouped";
import Select, { SelectOption } from "components/inputs/Select";
import TextInput from "components/inputs/TextInput";
import { ProjectStatus, projectClassificationOptions } from "constants/project";
import { useStore } from "stores";
import { Client, ProjectInfoEditInputs } from "types";
import { Select as BaseSelect } from "./ProjectMembersComponents/Select";
import {
  LinkWrapper,
  InputWrapper,
  FieldLabel,
  InfoHeader,
} from "./InputWrappers";

import { ReactComponent as CustomLinkIcon } from "assets/svg/circle-custom-link.svg";
import { ReactComponent as FigmaIcon } from "assets/svg/circle-figma.svg";
import { ReactComponent as GitlabIcon } from "assets/svg/circle-gitlab.svg";
import { ReactComponent as GoogleIcon } from "assets/svg/circle-google.svg";
import { ReactComponent as MattermostIcon } from "assets/svg/circle-mattermost.svg";
import { ReactComponent as RedmineIcon } from "assets/svg/circle-redmine.svg";

import { City, Country, State } from "country-state-city";

type Props = {
  isSaving: boolean;
  project: ProjectInfoEditInputs;
  isWithMattermost?: boolean;
  isWithRedmine?: boolean;
  isWithGdrive?: boolean;
  setProjectEdits: React.Dispatch<React.SetStateAction<ProjectInfoEditInputs>>;
};

type ClientAddressFields = Pick<Client, "country" | "state" | "city">;
type DropdownValues = {
  [k in keyof ClientAddressFields]: SelectOption;
};

const statusOptions = [
  ProjectStatus.Proposal,
  ProjectStatus.Active,
  ProjectStatus.Completed,
].map((e) => ({ label: e, value: e }));

const ProjectInfoEdit = ({
  isSaving,
  project,
  setProjectEdits,
  isWithMattermost,
  isWithRedmine,
  isWithGdrive,
}: Props) => {
  const { industryStore, techStackStore } = useStore();
  const linkCount = useRef(0);

  const { control, watch } = useForm<ProjectInfoEditInputs>({
    defaultValues: project,
  });
  const industryEdit = watch("industries");
  const techStacksEdit = watch("techStacks");
  const classificationEdit = watch("classification");

  useEffect(() => {
    updateProjectEdits({
      classification: classificationEdit,
    });
    // eslint-disable-next-line
  }, [classificationEdit]);

  useEffect(() => {
    updateProjectEdits({ industries: industryEdit });
    // eslint-disable-next-line
  }, [industryEdit]);

  useEffect(() => {
    updateProjectEdits({
      techStacks: techStacksEdit,
    });
    // eslint-disable-next-line
  }, [techStacksEdit]);

  const industryOptions = useMemo(
    () =>
      industryStore.industries.map((item) => ({
        label: item.name,
        key: item._id,
      })),
    [industryStore.industries]
  );

  const techStackOptions = useMemo(
    () =>
      techStackStore.techStacks.map((item) => ({
        label: item.name,
        key: item._id,
        category: item.category,
      })),
    [techStackStore.techStacks]
  );

  const updateProjectEdits = (update: Partial<ProjectInfoEditInputs>) => {
    setProjectEdits((prev) => ({ ...prev, ...update }));
  };

  const updateClient = (update: Partial<Client>) => {
    updateProjectEdits({
      client: {
        ...project.client,
        ...update,
      },
    });
  };

  const { client } = project;

  const initialDropdownValues: DropdownValues = useMemo(() => {
    const defaultCountry = Country.getAllCountries().find(
      ({ name }) => name === client?.country
    );
    const defaultState = State.getStatesOfCountry(defaultCountry?.isoCode).find(
      ({ name }) => name === client?.state
    );
    const defaultCity = City.getCitiesOfState(
      defaultCountry?.isoCode || "",
      defaultState?.isoCode || ""
    ).find(({ name }) => name === client?.city);

    return {
      country: {
        label: defaultCountry?.name || "",
        key: defaultCountry?.isoCode || "",
      },
      state: {
        label: defaultState?.name || "",
        key: defaultState?.isoCode || "",
      },
      city: { label: defaultCity?.name || "", key: defaultCity?.name || "" },
    };
  }, []);

  const [dropdowns, setDropdowns] = useState<DropdownValues>(
    initialDropdownValues
  );
  const { city, state, country } = dropdowns;

  const updateDropdowns = (update: Partial<DropdownValues>) => {
    setDropdowns({
      ...dropdowns,
      ...update,
    });

    const clientUpdate = Object.entries(update).reduce<Partial<Client>>(
      (update, [fieldName, selectOption]) => {
        const address = fieldName as keyof Client;
        update[address] = selectOption?.label;
        return update;
      },
      {}
    );
    updateClient(clientUpdate);
  };

  const getCountriesAsync = () => {
    const countries = Country.getAllCountries().map(({ name, isoCode }) => ({
      label: name,
      key: isoCode,
    }));
    return Promise.resolve(countries);
  };

  const getStatesAsync = useCallback(() => {
    if (!country) {
      return Promise.resolve([]);
    }

    const states = State.getStatesOfCountry(country?.key).map(
      ({ name, isoCode }) => ({
        label: name,
        key: isoCode,
      })
    );
    return Promise.resolve(states);
  }, [country]);

  const getCitiesAsync = useCallback(() => {
    if (!country || !state) {
      return Promise.resolve([]);
    }

    const cities = City.getCitiesOfState(
      country?.key ?? "",
      state?.key ?? ""
    ).map(({ name }) => ({
      label: name,
      key: name,
    }));

    return Promise.resolve(cities);
  }, [country, state]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setProjectEdits((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleStatusChange = (value: string) => {
    updateProjectEdits({
      status: value,
    });
  };

  const handleClientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    updateClient({ [name]: value });
  };

  const handleCustomLinkAdd = () => {
    setProjectEdits((prev) => ({
      ...prev,
      customLinks: [
        ...prev.customLinks,
        { id: `-${linkCount.current}`, name: "", url: "" },
      ],
    }));
    linkCount.current += 1;
  };

  const handleCustomLinkChange = (
    field: string,
    value: string,
    idx: string
  ) => {
    setProjectEdits((prev) => {
      const newCustomLinks = prev.customLinks.map((cl) => {
        if (cl.id === idx && field in cl) {
          cl[field as keyof typeof cl] = value;
        }
        return cl;
      });
      return {
        ...prev,
        customLinks: [...newCustomLinks],
      };
    });
  };

  const handleCustomLinkRemove = (idx: string) => {
    setProjectEdits((prev) => {
      const newCustomLinks = prev.customLinks.filter((v) => v.id !== idx);
      return {
        ...prev,
        customLinks: [...newCustomLinks],
      };
    });
  };

  return (
    <div className="pb-12">
      <InfoHeader text={"PROJECT INFO"} />

      <div className="items-center gap-6">
        <InputWrapper label={"Project Name"}>
          <TextInput
            disabled={isSaving}
            name="name"
            onChange={handleChange}
            value={project.name}
          />
        </InputWrapper>
        <div className="mx-6 pb-6">
          <div className="flex flex-col">
            <Controller
              name="classification"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  isRadio
                  label="Classification"
                  onChange={onChange}
                  options={projectClassificationOptions}
                  value={value as SelectOption}
                />
              )}
            />
          </div>
        </div>
        <InputWrapper label={"Project Status"}>
          <div>
            <BaseSelect
              onChange={handleStatusChange}
              options={statusOptions}
              value={project.status}
            />
          </div>
        </InputWrapper>
      </div>

      <InfoHeader text={"CLIENT"} />

      <div className="items-center gap-6">
        <InputWrapper label={"Client Name"}>
          <TextInput
            disabled={isSaving}
            name="name"
            onChange={handleClientChange}
            value={project.client.name}
          />
        </InputWrapper>
        <InputWrapper label="Client Email">
          <TextInput
            disabled={isSaving}
            name="email"
            onChange={handleClientChange}
            value={project.client.email || ""}
          />
        </InputWrapper>
        <InputWrapper label="Client Company Name">
          <TextInput
            disabled={isSaving}
            name="companyName"
            onChange={handleClientChange}
            value={project.client.companyName || ""}
          />
        </InputWrapper>
        <InputWrapper label="Client Address">
          <div className="grid grid-cols-2 gap-2 pt-4">
            <Select
              label={"Client Country"}
              asyncQuery={getCountriesAsync}
              onChange={(value) => {
                if (!value) return;
                const newCountryAddress = {
                  country: value,
                  state: undefined,
                  city: undefined,
                };
                updateDropdowns({
                  ...newCountryAddress,
                });
              }}
              value={country || null}
            />
            <Select
              label={"Client State/Region"}
              asyncQuery={getStatesAsync}
              onChange={(value) => {
                if (!value) return;

                const newRegionAddress = {
                  state: value,
                  city: undefined,
                };
                updateDropdowns({
                  ...newRegionAddress,
                });
              }}
              value={state ?? null}
            />
            <Select
              label={"Client City"}
              asyncQuery={getCitiesAsync}
              onChange={(value) => {
                if (!value) return;
                updateDropdowns({
                  city: value,
                });
              }}
              value={city ?? null}
            />
            <div>
              <FieldLabel text={"Street"} />
              <TextInput
                disabled={isSaving}
                name={"streetAddress"}
                onChange={handleClientChange}
                value={project.client.streetAddress || ""}
              />
            </div>
            <div>
              <FieldLabel text={"Zip Code"} />
              <TextInput
                disabled={isSaving}
                name={"zip"}
                onChange={handleClientChange}
                value={project.client.zip || ""}
              />
            </div>
          </div>
        </InputWrapper>
      </div>

      <InfoHeader text={"INDUSTRY"} />

      <div className="items-center gap-6">
        <div className="mx-6 pb-12">
          <Controller
            name="industries"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                label="Industry"
                onChange={onChange}
                options={industryOptions}
                value={value.key ? value : null}
              />
            )}
          />
        </div>
      </div>

      <InfoHeader text="TECH STACK" />

      <div className="items-center gap-6">
        <div className="mx-6 pb-12">
          <Controller
            name="techStacks"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultiselectGrouped
                label="Tech Stack"
                onChange={onChange}
                options={techStackOptions}
                value={value}
              />
            )}
          />
        </div>
      </div>

      <InfoHeader text="LINKS" />

      <div className="px-6 font-semibold text-dark-900">
        <LinkWrapper label="Gitlab" icon={GitlabIcon}>
          <TextInput
            disabled={isSaving}
            name="gitlab"
            onChange={handleChange}
            value={project.gitlab}
          />
        </LinkWrapper>
        <LinkWrapper label="Redmine" icon={RedmineIcon}>
          {isWithRedmine ? (
            <p className="cursor-pointer text-sm font-normal italic text-dark-900/70 hover:underline">
              {project.redmine}
            </p>
          ) : (
            <TextInput
              disabled={isSaving}
              name="redmine"
              onChange={handleChange}
              value={project.redmine}
            />
          )}
        </LinkWrapper>
        <LinkWrapper label="Google Drive" icon={GoogleIcon}>
          {isWithGdrive ? (
            <p className="cursor-pointer text-sm font-normal italic text-dark-900/70 hover:underline">
              {project.googleDrive}
            </p>
          ) : (
            <TextInput
              disabled={isSaving}
              name="googleDrive"
              onChange={handleChange}
              value={project.googleDrive}
            />
          )}
        </LinkWrapper>
        <LinkWrapper label="Figma" icon={FigmaIcon}>
          <TextInput
            disabled={isSaving}
            name="figma"
            onChange={handleChange}
            value={project.figma}
          />
        </LinkWrapper>
        <LinkWrapper label="Mattermost Channel" icon={MattermostIcon}>
          {isWithMattermost ? (
            <p className="cursor-pointer text-sm font-normal italic text-dark-900/70 hover:underline">
              {project.mattermostChannelUrl}
            </p>
          ) : (
            <>
              <TextInput
                disabled={isSaving}
                name="mattermostChannelUrl"
                onChange={handleChange}
                value={project.mattermostChannelUrl}
              />
              <p className="mt-2 text-sm font-normal leading-5 text-dark-900/70">
                Mattermost Channel ID
              </p>
              <TextInput
                disabled={isSaving}
                name="mattermostChannelId"
                onChange={handleChange}
                value={project.mattermostChannelId || ""}
              />
            </>
          )}
        </LinkWrapper>
        {project.customLinks.map((customLink) => (
          <div
            className="flex flex-row items-center gap-6 py-4"
            key={customLink.id}
          >
            <CustomLinkIcon />
            <div className="col-span-5 flex grow break-words pr-2">
              <div className="flex grow flex-col gap-3">
                <input
                  className="color-slate placeholder:color-slate-lighter h-7 rounded-lg border border-gray-300 px-3 py-2 text-xs font-normal focus:outline-none"
                  type="text"
                  defaultValue={customLink.name}
                  onChange={(e) =>
                    handleCustomLinkChange(
                      "name",
                      e.target.value,
                      customLink.id
                    )
                  }
                />
                <input
                  className="color-slate placeholder:color-slate-lighter h-10 rounded-lg border border-gray-300 px-3 py-2 text-base font-normal focus:outline-none"
                  defaultValue={customLink.url}
                  type="text"
                  onChange={(e) =>
                    handleCustomLinkChange("url", e.target.value, customLink.id)
                  }
                />
              </div>
              <div className="flex">
                <button
                  className="material-icons self-end px-4 pb-2 text-base"
                  onClick={() => handleCustomLinkRemove(customLink.id)}
                  type="button"
                >
                  close
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="pb-7 pt-10">
          <button
            className="flex gap-2 text-blue hover:text-blue-hover"
            onClick={handleCustomLinkAdd}
            type="button"
          >
            <span className="material-icons">add_circle_outline</span>
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ProjectInfoEdit);

import React, { useState } from "react";

import { TableColHeader } from "types";

import SortTableModal from "components/modals/SortTableModal";

type TableFiltersProps = {
  isEditing?: boolean;
  setNameFilter: React.Dispatch<React.SetStateAction<string>>;
  columnHeaders?: TableColHeader[];
  fetchAndUpdateMembersList: (col: TableColHeader, idx: number) => void;
};

const TableFilters: React.FC<TableFiltersProps> = ({
  isEditing,
  setNameFilter,
  columnHeaders,
  fetchAndUpdateMembersList,
}) => {
  const [isShowSortTableModal, setIsShowSortTableModal] =
    useState<boolean>(false);

  return (
    <div className="flex h-[88px] items-center gap-4 border-solid border-[#E0E0E0] bg-[#FFFFFF] p-4 md:border-b">
      <div className="flex grow gap-2">
        <span className="material-icons pt-[6.5px] md:pt-0">search</span>
        <input
          type="text"
          placeholder="Search"
          className="w-full bg-transparent text-base leading-6 tracking-[0.15px] outline-none placeholder:text-slate/70 disabled:text-slate/70"
          size={16}
          disabled={isEditing}
          onChange={(event) => setNameFilter(event.currentTarget.value)}
        />
        <div className="flex justify-center md:hidden">
          <SortTableModal
            showModal={isShowSortTableModal}
            columnHeaders={columnHeaders ?? []}
            onButtonClick={() => setIsShowSortTableModal((prev) => !prev)}
            onApply={(col: TableColHeader | undefined) => {
              if (!col) return;

              const idx = (columnHeaders ?? [])
                .map((item) => item.title)
                .indexOf(col.title);
              fetchAndUpdateMembersList(col, idx);
            }}
            onCancel={() => setIsShowSortTableModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default TableFilters;

import { registerRootStore } from "mobx-keystone";
import React from "react";

import AuthStore from "./AuthStore";
import RootStore from "./RootStore";
import UserStore from "./UserStore";
import ProjectStore from "./ProjectStore";
import IndustryStore from "./IndustryStore";
import TechStackStore from "./TechStackStore";
import ProjectSearchFilter from "models/ProjectSearchFilter";

export const StoreContext = React.createContext<RootStore>({} as RootStore);
export const useStore = () => React.useContext(StoreContext);
export const { Provider: StoreProvider } = StoreContext;

export function createRootStore() {
  const authStore = new AuthStore({});
  const industryStore = new IndustryStore({});
  const projectStore = new ProjectStore({
    searchFilters: new ProjectSearchFilter({}),
  });
  const techStackStore = new TechStackStore({});
  const userStore = new UserStore({});

  const rootStore = new RootStore({
    authStore,
    industryStore,
    projectStore,
    techStackStore,
    userStore,
  });

  registerRootStore(rootStore);
  return rootStore;
}

import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { useStore } from "stores";
import { Project } from "models";

import { ProjectMemberEdit, ProjectMember, EditMemberRolesInput } from "types";
import ActionMenu from "../../ActionMenu";
import NumericInput from "../../NumericInput";
import Multiselect from "components/inputs/Multiselect";
import RoleBadge from "../../RoleBadge";

import { formatToUSD } from "utils";
import Avatar from "components/Avatar";

type TableRowMobileProps = {
  member: ProjectMember;
  openRateHistoryModal: () => void;
  openRemoveMemberModal: () => void;
  setMember: React.Dispatch<React.SetStateAction<ProjectMember | undefined>>;
};

const TableRowMobile: React.FC<TableRowMobileProps> = ({
  member,
  openRateHistoryModal,
  openRemoveMemberModal,
  setMember,
}) => {
  const { authStore, projectStore } = useStore();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const project = projectStore.projects.get(projectId) as Project;

  const { memberEdits, isEditing } = project;
  const memberEdit = memberEdits.find(
    (e) => e._id === member._id
  ) as ProjectMemberEdit;

  const updateMemberEdits = (update: Partial<ProjectMemberEdit>) => {
    project.updateMemberEdit(member._id, update);
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRate = event.currentTarget.valueAsNumber;
    if (newRate < 0) return;

    updateMemberEdits({ rate: { amount: newRate } });
  };

  const initialPositions = (member.roles ?? []).map((item) => {
    return {
      key: item,
      label: item,
    };
  });
  const { control, watch } = useForm<EditMemberRolesInput>({
    defaultValues: { selectedPositions: initialPositions },
  });

  const selectedPositions = watch("selectedPositions");

  useEffect(() => {
    const roles = selectedPositions.map((item) => item.label);
    updateMemberEdits({ roles });
    // eslint-disable-next-line
  }, [selectedPositions]);

  const openAndSetMemberForRateHistoryModal = () => {
    setMember(member);
    openRateHistoryModal();
  };

  const openAndSetRemoveMemberModal = () => {
    setMember(member);
    openRemoveMemberModal();
  };

  const {
    user: { hrPortalId, fullName, mahusaiEmail },
    roles,
    rate: { amount },
  } = member;

  const isCurrentUserProjectMemberOrAdmin =
    authStore.currentUser?.isManager ||
    projectStore.isProjectMember(member.projectId, authStore.currentUser?._id);

  return (
    <div className="flex flex-row">
      <div className="flex grow flex-col gap-2 border-b border-r p-4">
        <Avatar user={member.user} width="w-[34px]" fontSize="text-base" />
        <div className="flex w-full flex-col">
          <div className="text-sm">{fullName}</div>
          {mahusaiEmail && (
            <div className="text-sm text-slate/70">{mahusaiEmail}</div>
          )}
        </div>
        <hr className="my-2" />
        <div className="flex flex-row items-center justify-between">
          <div className="grow text-sm text-slate/70">Roles</div>
          <div className="w-44">
            {isEditing ? (
              <Controller
                name="selectedPositions"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Multiselect
                    label="Select Position"
                    className="h-14 max-h-36 overflow-y-auto"
                    addIconClassName="top-4"
                    onChange={onChange}
                    options={project.availableMemberRoles}
                    value={value}
                    isShowLabelAtTop={false}
                    isArrowDropdown
                    isShowSelectedInInputField
                  />
                )}
              />
            ) : (
              <div
                className={twMerge(
                  "flex justify-end gap-2",
                  (roles ?? []).length > 2 && "flex-col items-end gap-0"
                )}
              >
                {(roles ?? []).map((item) => (
                  <RoleBadge
                    key={item}
                    role={item}
                    isMultipleValues={Boolean(roles?.length)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="grow text-sm text-slate/70">Rate</div>
          <div className="flex w-44 justify-end text-sm text-slate/70">
            {isEditing ? (
              <NumericInput
                onChange={handleRateChange}
                value={memberEdit.rate.amount}
              />
            ) : (
              formatToUSD(amount)
            )}
          </div>
        </div>
      </div>
      <div className="flex min-w-[58px] items-center justify-center">
        <div className="h-[32px] w-[32px] overflow-hidden rounded-full">
          <ActionMenu
            isCurrentUserProjectMemberOrAdmin={
              isCurrentUserProjectMemberOrAdmin
            }
            disabled={isEditing}
            hrPortalId={hrPortalId}
            key={member._id}
            placement="left"
            openRateHistoryModal={openAndSetMemberForRateHistoryModal}
            openRemoveMemberModal={openAndSetRemoveMemberModal}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(TableRowMobile);

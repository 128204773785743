import { SearchIcon } from "components/dashboard/Icons";
import FilterModal from "components/modals/FilterModal";
import { observer } from "mobx-react-lite";
import { ProjectSearchFilter } from "models";
import { Project } from "types";

type ProjectFiltersProps = {
  filters: ProjectSearchFilter;
  filterListedProject: (
    inFilterModal?: boolean
  ) => Promise<Project[] | undefined>;
};
const ProjectsFilters = ({
  filters,
  filterListedProject,
}: ProjectFiltersProps) => {
  const onClearFilterProject = () => {
    filters.setCurrentPage(1);
    filters.setName("");
    filterListedProject();
  };
  const { name, isMyProject } = filters;
  return (
    <div className="flex w-12 flex-row gap-4 p-4 px-[1.125rem] md:w-full">
      <div className="relative flex flex-auto items-center">
        <SearchIcon />
        <input
          className="border-none focus:border-none focus:outline-0 md:w-full md:pl-11"
          onChange={(event) => filters.setName(event.target.value)}
          onKeyUp={(event) => {
            const keyCode = event.code || event.key;
            if (keyCode === "Enter" || keyCode === "NumpadEnter") {
              filters.setCurrentPage(1);
              filterListedProject();
            }
          }}
          placeholder="Search"
          value={name}
        />
      </div>

      <label className="relative hidden items-center md:inline-flex">
        <input
          className="h-6 w-6 cursor-pointer"
          type="checkbox"
          checked={isMyProject}
          onChange={() => {
            filters.setIsMyProject(!isMyProject);
          }}
        />
        <span className="ml-3 text-base">My Projects</span>
      </label>
      <FilterModal
        onApply={filterListedProject}
        onClear={onClearFilterProject}
      />
    </div>
  );
};

export default observer(ProjectsFilters);

import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { twMerge } from "tailwind-merge";

import { useStore } from "stores";
import { TableColHeader } from "types";

import Modal from "./Modal";
import Button from "../Button";
import OverflowContainer, { OverflowButton } from "../OverflowContainer";

type SortModalProps = {
  columnHeaders: TableColHeader[];
  showModal?: boolean;
  onApply: (col: TableColHeader | undefined) => void;
  onCancel: () => void;
  onButtonClick: () => void;
};

function SortModal({
  columnHeaders,
  showModal,
  onApply,
  onCancel,
  onButtonClick,
}: SortModalProps) {
  const [selectedCol, setSelectedCol] = useState<TableColHeader>();

  const sortModalRef = useRef(null);

  const {
    projectStore: { searchFilters: filters },
  } = useStore();

  const { sortFieldName } = filters;

  const SortIcon = () => (
    <span className="material-icons-outlined text-normal text-primary-900 md:text-2xl md:leading-none">
      sort
    </span>
  );

  const CheckIcon = () => (
    <span className="material-icons-outlined text-lg font-semibold text-primary-900">
      check
    </span>
  );

  return (
    <div>
      <OverflowContainer
        className="hidden w-[21rem] md:flex md:flex-col"
        buttonContent={
          <div
            ref={sortModalRef}
            className={twMerge(
              "flex max-w-fit cursor-pointer items-center gap-2 rounded-[60px] border border-[#0C59AC] px-3 py-1 text-white lg:px-4 lg:py-2",
              "bg-white"
            )}
            onClick={() => onButtonClick()}
          >
            <span
              className={twMerge(
                "hidden text-sm font-semibold lg:flex",
                !sortFieldName ? "text-white" : "text-primary-900"
              )}
            >
              Sort
            </span>{" "}
            <SortIcon />
          </div>
        }
      />

      {/* // ***: Mobile view  */}
      {showModal && (
        <Modal
          className="md:hidden"
          handleClose={() => onCancel()}
          stickToBottom={true}
          title="Sort by"
        >
          <hr className="mb-3" />

          {columnHeaders.map((col) => (
            <OverflowButton
              key={col.fieldName}
              className={twMerge(
                "flex items-center bg-transparent pb-3 pt-2 text-sm text-dark-900/70 hover:bg-transparent",
                selectedCol === col && "justify-between text-primary-900"
              )}
              onClick={() => setSelectedCol(col)}
            >
              {col.title}
              {selectedCol === col && <CheckIcon />}
            </OverflowButton>
          ))}

          <hr />

          <OverflowButton className="flex w-full cursor-auto items-center justify-between px-5 py-5 hover:bg-transparent focus:bg-transparent">
            <Button
              category="primaryOutline"
              className="flex w-[48%] cursor-pointer items-center px-7 py-2 text-primary-900"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              category="primary"
              className="flex w-[48%] items-center px-7 py-2 text-white"
              onClick={() => {
                onApply(selectedCol);

                // @ts-ignore
                sortModalRef?.current?.click();
              }}
            >
              Apply
            </Button>
          </OverflowButton>
        </Modal>
      )}
    </div>
  );
}

export default observer(SortModal);

import { ProjectStatus } from "constants/project";

export const getStatusBg = (status: string) => {
  switch (status) {
    case ProjectStatus.Active:
      return "bg-[#D3E155] text-dark-900";
    case ProjectStatus.Archived:
      return "bg-[#E0E0E0] text-dark-900";
    case ProjectStatus.Completed:
      return "bg-[#CBF8D0] text-success-main";
    case ProjectStatus.Proposal:
      return "bg-[#C9F1FB] text-primary-900";
    default:
      return;
  }
};

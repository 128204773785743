import { ClickAwayListener, Popper, PopperOwnProps } from "@mui/base";
import React, { useCallback, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  buttonContent: React.ReactNode;
  disabled?: boolean;
  onButtonClick?: () => void;
  children?: React.ReactNode;

  popperProps?: Partial<PopperOwnProps>;
};

const OverflowContainer = ({
  buttonContent,
  disabled = false,
  onButtonClick,
  children,
  className,
  popperProps = {
    placement: "bottom-end",
  },
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (disabled) return;

      setAnchorEl((prev) =>
        prev ? null : (event.target as HTMLElement).parentElement
      );
    },
    [disabled]
  );

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <div
          onClick={handleClick}
          aria-describedby="popper"
          className="cursor-pointer"
          role="button"
        >
          {buttonContent}
        </div>
        <Popper
          id={anchorEl ? "popper" : "undefined"}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          {...popperProps}
          className="z-[50]"
          disablePortal
        >
          <div
            className={twMerge(
              "z-[50] mt-1 whitespace-nowrap rounded-md bg-white shadow-popover",
              className
            )}
            {...props}
          >
            <OverflowProvider
              value={{
                signalClose: () => setAnchorEl(null),
              }}
            >
              {children}
            </OverflowProvider>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default OverflowContainer;

type OverflowContextData = {
  signalClose: () => void;
};

export const OverflowContext = React.createContext<OverflowContextData>(
  {} as OverflowContextData
);
export const useOverflowContainer = () => React.useContext(OverflowContext);
export const { Provider: OverflowProvider } = OverflowContext;

const BASE_BUTTON_CLASSES =
  "py-[0.375rem] pl-4 pr-[.8125rem] text-left text-base leading-6 hover:bg-blue-lighter";

type OverflowButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const OverflowButton = ({
  className,
  children,
  ...props
}: OverflowButtonProps) => {
  return (
    <button
      {...props}
      className={twMerge(BASE_BUTTON_CLASSES, className)}
      type="button"
    >
      {children}
    </button>
  );
};

export const OverflowLink = ({ className, children, ...props }: LinkProps) => {
  const { signalClose } = useOverflowContainer();

  return (
    <Link
      {...props}
      className={twMerge(BASE_BUTTON_CLASSES, className)}
      onClick={signalClose}
    >
      {children}
    </Link>
  );
};

export const OverflowExternalLink = ({
  className,
  children,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { signalClose } = useOverflowContainer();

  return (
    <a
      {...props}
      className={twMerge(BASE_BUTTON_CLASSES, className)}
      onClick={signalClose}
    >
      {children}
    </a>
  );
};

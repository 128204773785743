import { computed } from "mobx";
import {
  Model,
  ModelCreationData,
  _async,
  _await,
  model,
  modelFlow,
  objectMap,
  prop,
} from "mobx-keystone";

import { Industry } from "models";
import {
  APIError,
  API_ERROR_MESSAGES,
  ErrorMessage,
} from "types/errors/APIError";
import fetchAPI from "utils/fetchAPI";

@model("portal/IndustryStore")
export default class IndustryStore extends Model({
  idToIndustryMap: prop(() => objectMap<Industry>()),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get industries(): Industry[] {
    return [...this.idToIndustryMap.values()];
  }

  @modelFlow
  fetchIndustries = _async(function* (this: IndustryStore) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI("industries"));
    } catch {
      this.errors.push({
        source: "fetchIndustries",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let results: ModelCreationData<Industry>[];
    ({ results } = yield* _await(response.json()));

    results.forEach((industry) =>
      this.idToIndustryMap.set(industry._id, new Industry(industry))
    );

    return {
      details: "Successfully fetched",
      ok: true,
      results,
    };
  });
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useStore } from "stores";

import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import { Project } from "models";
import { observer } from "mobx-react-lite";

type HeaderActionsProps = {
  setIsShowAddMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderActions: React.FC<HeaderActionsProps> = ({
  setIsShowAddMemberModal,
}) => {
  const { projectStore } = useStore();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const project = projectStore.projects.get(projectId) as Project;

  const [loading, setLoading] = useState<boolean>(false);

  const beginEdit = () => {
    project.beginMemberEdits();
    project.setIsEditing(true);
  };

  const cancelEdit = () => {
    project.setIsEditing(false);
    project.clearMemberEdits();
  };

  const saveEdit = async () => {
    if (!projectId) {
      return;
    }
    setLoading(true);

    await projectStore.editProjectMembers(project);

    setLoading(false);
    project.clearMemberEdits();
    project.setIsEditing(false);
  };

  useEffect(() => {
    projectStore.setLoading(loading);
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className="mt-6 flex gap-2 lg:mt-0">
      <Button
        category="primaryOutline"
        endIcon="edit"
        disabled={project.isEditing}
        onClick={beginEdit}
      >
        Edit Members
      </Button>
      {project.isEditing ? (
        <>
          <Button
            disabled={loading}
            category="primaryOutline"
            onClick={cancelEdit}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            category="primary"
            endIcon="check"
            onClick={saveEdit}
          >
            Save
            {loading && <LoadingIndicator />}
          </Button>
        </>
      ) : (
        <Button
          category="primary"
          endIcon="add"
          onClick={() => setIsShowAddMemberModal(true)}
        >
          Add Member
        </Button>
      )}
    </div>
  );
};

export default observer(HeaderActions);

import OverflowContainer, {
  OverflowButton,
} from "components/OverflowContainer";
import { ProjectStatus, projectClassificationOptions } from "constants/project";
import { Project } from "models";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "stores";
import { twMerge } from "tailwind-merge";
import { formatDateDisplayed, getStatusBg } from "utils";

type ProjectTableRowProps = {
  project: Project;
  setSelectedProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
  setIsShowArchiveOrRestoreModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const ProjectTableRow = ({
  project,
  setSelectedProject,
  setIsShowArchiveOrRestoreModal,
}: ProjectTableRowProps) => {
  const {
    authStore: { currentUser: { isManager, _id: currentUserId } = {} },
    projectStore,
  } = useStore();

  const navigate = useNavigate();
  const actionsRef = useRef(null);
  const formatClassificationDisplayed = (value: string) => {
    const classification = projectClassificationOptions.find(
      (item) => item.key === value
    );
    return classification?.label || "";
  };

  const getIsCurrentUserProjectMemberOrAdmin = (projectId: string) => {
    return isManager || projectStore.isProjectMember(projectId, currentUserId);
  };

  return (
    <tr key={project._id} className="border-x md:border-x-0 md:border-y-2">
      <td className="border-r-0 py-4 pl-4 text-left font-semibold md:w-[20.5rem] md:py-4">
        <a href={`/projects/${project._id}`}>{project.name}</a>
        <span
          className={twMerge(
            "mt-3 block w-fit rounded-full px-3.5 py-1 font-normal md:hidden",
            getStatusBg(project.status)
          )}
        >
          {project.status}
        </span>
      </td>
      <td className="table-cell border-r py-4 text-left md:hidden" />
      <DesktopTableData className="pl-0">
        <span
          className={twMerge(
            "rounded-full px-3.5 py-1",
            getStatusBg(project.status)
          )}
        >
          {project.status}
        </span>
      </DesktopTableData>
      <DesktopTableData>{project.client?.name || "None"}</DesktopTableData>
      <DesktopTableData>
        {formatClassificationDisplayed(project.classification)}
      </DesktopTableData>
      <DesktopTableData>
        {project.createdAt && formatDateDisplayed(project.createdAt)}
      </DesktopTableData>
      <td className="py-4 pl-2 md:w-[6rem] md:border-r-0 md:pl-4">
        <div className="flex w-12 justify-center">
          <OverflowContainer
            buttonContent={
              <span
                ref={actionsRef}
                className="material-icons-outlined cursor-pointer text-lg text-dark-900"
              >
                more_vert
              </span>
            }
            popperProps={{
              placement: "bottom-start",
            }}
          >
            <OverflowButton
              className="flex w-full items-center"
              onClick={() => {
                navigate(`/projects/${project._id}`);
              }}
            >
              View project
            </OverflowButton>
            <OverflowButton
              className={twMerge(
                "flex items-center font-normal",
                !getIsCurrentUserProjectMemberOrAdmin(project._id) &&
                  "opacity-[0.38]",
                project.status !== ProjectStatus.Archived
                  ? "text-danger-400"
                  : "text-primary-900"
              )}
              disabled={!getIsCurrentUserProjectMemberOrAdmin(project._id)}
              onClick={() => {
                setSelectedProject(project);
                setIsShowArchiveOrRestoreModal(true);
              }}
            >
              {project.status !== ProjectStatus.Archived
                ? "Archive Project"
                : "Restore Project"}
            </OverflowButton>
          </OverflowContainer>
        </div>
      </td>
    </tr>
  );
};

export default ProjectTableRow;

type TableCellProps = React.TableHTMLAttributes<HTMLTableCellElement>;

const DesktopTableData = ({ className, children }: TableCellProps) => (
  <td
    className={twMerge("hidden py-4 pl-4 text-left md:table-cell", className)}
  >
    {children}
  </td>
);

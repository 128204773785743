import { MultiselectOption } from "components/inputs/Multiselect";
import { MemberRoles } from "constants/project";

export const membersTableColumnHeaders = [
  { title: "Name", fieldName: "name" },
  { title: "Role", fieldName: "roles" },
  { title: "Rate", fieldName: "rate" },
  { title: "Date Joined", fieldName: "createdAt" },
  { title: "Actions", fieldName: null },
];

export const roleOptions: MultiselectOption[] = Object.values(MemberRoles).map(
  (e) => ({
    label: e,
    value: e,
    key: e,
  })
);

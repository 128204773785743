interface Props {
  icon: React.ReactNode;
  label: React.ReactNode;
  value?: number;
}

export default function ProjectStat({ icon, label, value = 0 }: Props) {
  return (
    <div className="flex items-center justify-start rounded-lg px-3 py-[0.71875rem] text-primary-900 shadow-card">
      <div className="mr-5 flex h-12 w-12 items-center justify-center rounded-full border-4 border-[#D3E155] bg-[#C8DA2B]/50  text-white">
        {icon}
      </div>
      <div>
        <h5 className="text-xs font-normal text-dark-900">{label}</h5>
        <div className="text-h5 font-semibold">{value}</div>
      </div>
    </div>
  );
}

import { TableColHeader } from "types";

export const projectsTableColumnHeaders: TableColHeader[] = [
  { title: "Project Name", fieldName: "name" },
  { title: "Project Status", fieldName: "status" },
  { title: "Client Name", fieldName: "client" },
  { title: "Classification", fieldName: "classification" },
  { title: "Date Created", fieldName: "createdAt" },
  { title: "Actions", fieldName: null },
];

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "stores";

import { Project } from "models";

import Root from "components/layout/Root";
import ArchiveProjectModal from "components/modals/ArchiveOrRestoreProjectModal";
import CreateNewProjectModal from "components/modals/CreateNewProjectModal";
import Button from "components/Button";

import ProjectStat from "components/ProjectStat";

import {
  CheckIcon,
  FolderIcon,
  MyFolderIcon,
} from "components/dashboard/Icons";

import Greeting from "components/dashboard/Greeting";
import ProjectsTable from "../components/dashboard/ProjectsTable";

function Dashboard() {
  const { authStore, projectStore, industryStore, techStackStore } = useStore();

  const [projectHasBeenAdded, setProjectHasBeenAdded] =
    useState<boolean>(false);
  const [showArchiveOrRestoreModal, setShowArchiveOrRestoreModal] =
    useState<boolean>(false);
  const [showCreateNewProjectModal, setShowCreateNewProjectModal] =
    useState<boolean>(false);

  const [selectedProject, setSelectedProject] = useState<Project>();

  const { nickname = "" } = authStore?.currentUser || {};

  useEffect(() => {
    (async () => {
      await projectStore.listProjects();
      await industryStore.fetchIndustries();
      await techStackStore.fetchTechStacks();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Root contentClassName="overflow-x-hidden">
      <div className="flex flex-auto flex-col gap-6 py-6">
        <div className="flex flex-col px-6 lg:flex-row lg:items-center lg:justify-between">
          <Greeting nickname={nickname} />
          <div className="xs:shrink-0 flex w-full flex-row flex-wrap items-center gap-x-2 gap-y-2 md:w-auto">
            <Button
              category="primary"
              className="my-2 flex max-w-[30.5rem] items-center gap-2 bg-[#0C59AC] py-2 text-[0.8125rem] font-semibold shadow-btn md:my-0 md:text-[0.9375rem]"
              onClick={() => setShowCreateNewProjectModal(true)}
            >
              Create New Project
            </Button>
            <CreateNewProjectModal
              open={showCreateNewProjectModal}
              setOpen={setShowCreateNewProjectModal}
              setProjectHasBeenAdded={() => setProjectHasBeenAdded(true)}
            />
          </div>
        </div>

        <div className="grid max-w-[100vw] gap-4 px-6 md:grid-cols-3 md:gap-6">
          <ProjectStat
            icon={<FolderIcon />}
            label="Total Projects"
            value={projectStore.projectsList.length}
          />
          <ProjectStat
            icon={<MyFolderIcon />}
            label="My Projects"
            value={projectStore.myProjectsCount(authStore.currentUser?._id)}
          />
          <ProjectStat
            icon={<CheckIcon />}
            label="Active Projects"
            value={projectStore.activeProjectsCount(authStore.currentUser?._id)}
          />
        </div>
        <div className="mx-6 rounded-2xl shadow-md md:border md:border-slate-100">
          <ProjectsTable
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            projectHasBeenAdded={projectHasBeenAdded}
            setProjectHasBeenAdded={setProjectHasBeenAdded}
            isShowArchiveOrRestoreModal={showArchiveOrRestoreModal}
            setIsShowArchiveOrRestoreModal={setShowArchiveOrRestoreModal}
          />
        </div>

        <ArchiveProjectModal
          project={selectedProject}
          showModal={showArchiveOrRestoreModal}
          onClose={() => setShowArchiveOrRestoreModal(false)}
        />
      </div>
    </Root>
  );
}

export default observer(Dashboard);

import { computed } from "mobx";
import { addDays, format } from "date-fns";

import {
  Model,
  ModelCreationData,
  getRootStore,
  model,
  modelAction,
  prop,
  timestampToDateTransform,
} from "mobx-keystone";

import { ProjectFilters } from "types";
import { MultiselectOption } from "components/inputs/Multiselect";

@model("portal/ProjectSearchFilter")
export default class ProjectSearchFilter extends Model({
  name: prop<string>("").withSetter(),
  // members: prop<string[]>(() => []).withSetter(),

  industryOptions: prop<MultiselectOption[] | undefined>().withSetter(),
  statusOptions: prop<MultiselectOption[] | undefined>().withSetter(),

  createdAtStart: prop<number | undefined>()
    .withTransform(timestampToDateTransform())
    .withSetter(),
  createdAtEnd: prop<number | undefined>()
    .withTransform(timestampToDateTransform())
    .withSetter(),

  isMyProject: prop<boolean>(true).withSetter(),

  rowsPerPage: prop<number>(0).withSetter(), // ***: by default: all my projects are displayed
  currentPage: prop<number>(1).withSetter(),

  modalFiltersActive: prop<boolean>(false).withSetter(),

  // ***: For sorting of tables via table columns
  sortFieldName: prop<string | undefined>().withSetter(),
  sortDirection: prop<number | undefined>().withSetter(),
}) {
  @modelAction
  update(data: ModelCreationData<ProjectSearchFilter>) {
    Object.assign(this, data);
  }

  @modelAction
  clearModalFilters() {
    this.statusOptions = [];
    this.industryOptions = [];
    this.createdAtStart = undefined;
    this.createdAtEnd = undefined;
  }

  @computed
  get industries() {
    if (this.industryOptions?.length) {
      return this.industryOptions.map((e) => e.key);
    }
    return [];
  }

  @computed
  get status() {
    if (this.statusOptions?.length) {
      return this.statusOptions.map((e) => e.key);
    }
    return [];
  }

  @computed
  get createdAt__gte() {
    const { createdAtStart } = this;
    return createdAtStart && format(createdAtStart, "yyyy-MM-dd");
  }

  @computed
  get createdAt__lte() {
    const { createdAtEnd } = this;
    const midnightOfNextDay = createdAtEnd && addDays(createdAtEnd, 1);
    return midnightOfNextDay && format(midnightOfNextDay, "yyyy-MM-dd");
  }

  @computed
  get queryParams() {
    const {
      status,
      industries,
      createdAt__gte,
      createdAt__lte,
      currentPage,
      rowsPerPage,
      name,
      sortFieldName,
      sortDirection,
    } = this;

    const queryParams: ProjectFilters = {
      name,
      ...(status.length && { status }),
      ...(industries.length && { industries }),
      ...(createdAt__gte && { createdAt__gte }),
      ...(createdAt__lte && { createdAt__lte }),
      ...(sortFieldName && { sortFieldName }),
      ...(sortDirection && { sortDirection }),
      limit: rowsPerPage,
      page: currentPage,
    };

    if (this.isMyProject) {
      const root: any = getRootStore(this);
      const currentUser = root && root.authStore.currentUser._id;

      queryParams["members.user"] = currentUser;
    }

    return queryParams;
  }
}

import { observer } from "mobx-react-lite";

import { useStore } from "stores";

import { ReactComponent as MahusaiLogo } from "assets/svg/Mahusai-Logo.svg";

import Avatar from "components/Avatar";
import OverflowContainer, {
  OverflowExternalLink,
  OverflowLink,
} from "components/OverflowContainer";

interface Props {
  toggleMenu: () => void;
}

const UserDropdown = observer(() => {
  const {
    authStore: { currentUser },
  } = useStore();

  return (
    <div className="flex items-center gap-2">
      <OverflowContainer
        buttonContent={
          <Avatar user={currentUser} width="w-10" fontSize="text-base" />
        }
      >
        <OverflowExternalLink
          href={`${process.env.REACT_APP_HR_PORTAL_WEBSITE_URL}/members/${currentUser?.hrPortalId}`}
          target="_blank"
          className="flex w-[14.25rem] items-center gap-3 py-4"
        >
          <Avatar user={currentUser} width="w-8" fontSize="text-[12.8px]" />
          <div>
            <div className="w-[9rem] truncate text-sm font-semibold">
              {currentUser?.fullName}
            </div>
            <div className="w-[9rem] truncate text-sm text-slate-500">
              {currentUser?.mahusaiEmail}
            </div>
          </div>
        </OverflowExternalLink>
        <hr />
        <OverflowLink
          to="/logout"
          className="flex w-[14.25rem] items-center gap-3 py-3"
        >
          <span className="material-icons-outlined person_2 text-base text-dark-900/70">
            logout
          </span>
          <span className="">Sign out</span>
        </OverflowLink>
      </OverflowContainer>
    </div>
  );
});

const Navigation = ({ toggleMenu }: Props) => (
  <div className="fixed z-[99] flex h-16 w-full flex-row items-center justify-between bg-white px-4 py-2 lg:px-6">
    <button
      className="material-icons py-3 text-dark-900/70 lg:hidden"
      onClick={toggleMenu}
    >
      menu
    </button>

    <MahusaiLogo className="hover:cursor-pointer" />

    <div className="flex items-center gap-4">
      <UserDropdown />
    </div>
  </div>
);

export default Navigation;

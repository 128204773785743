import { computed, observable } from "mobx";
import {
  Model,
  _async,
  _await,
  objectMap,
  model,
  modelAction,
  modelFlow,
  ModelCreationData,
  prop,
} from "mobx-keystone";

import {
  ErrorMessage,
  API_ERROR_MESSAGES,
  APIError,
} from "types/errors/APIError";
import fetchAPI from "utils/fetchAPI";
import { Project } from "models";
import { ProjectStatus } from "constants/project";
import {
  ActivityLogType,
  MemberRateHistory,
  MembersFilter,
  ProjectFilters,
  ProjectMemberEdit,
  ProjectMember,
  ProjectInvoiceResponse,
  ProjectCreationData,
  ProjectEditData,
  ProjectInvoiceQuery,
} from "types";
import ProjectSearchFilter from "models/ProjectSearchFilter";

@model("portal/ProjectStore")
export default class ProjectStore extends Model({
  projects: prop(() => objectMap<Project>()),
  count: prop<number>(0).withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
  searchFilters: prop<ProjectSearchFilter>().withSetter(),
  selectedProjectId: prop<string | null>(null).withSetter(),
}) {
  @observable
  loading = false;

  @computed
  get selectedProject(): Project | null {
    if (this.selectedProjectId === null) {
      return null;
    }
    return this.projects.get(this.selectedProjectId) ?? null;
  }

  @computed
  get projectsList(): Project[] {
    return Array.from(this.projects.values());
  }

  @modelAction
  setLoading(value: boolean) {
    this.loading = value;
  }

  @modelAction
  myProjectsCount(userId: string | undefined) {
    if (!userId) return 0;

    let count = 0;
    Array.from(this.projects.values()).forEach((project) => {
      if (project.isAllUsersAreMembers) {
        count = count + 1;
        return;
      }

      const cond = project.members?.find((item) =>
        typeof item.user === "string"
          ? item.user === userId
          : item.user?._id === userId
      );
      if (cond) count = count + 1;
    });

    return count;
  }

  @modelAction
  isProjectMember(projectId: string | undefined, userId: string | undefined) {
    if (!userId || !projectId) return false;

    const project = this.projects.get(projectId)!;
    const usersMembersIdsList = project.members?.map((item) => item.user?._id);

    return usersMembersIdsList.includes(userId);
  }

  @modelAction
  activeProjectsCount(userId: string | undefined) {
    if (!userId) return 0;

    let count = 0;
    Array.from(this.projects.values()).forEach((project) => {
      const cond =
        project.status === ProjectStatus.Active &&
        project.members?.find((item) =>
          typeof item.user === "string"
            ? item.user === userId
            : item.user?._id === userId
        );
      if (cond) count = count + 1;
    });

    return count;
  }

  @modelAction
  createOrUpdateProject(data: ModelCreationData<Project>) {
    const id = `${data._id}`;

    let project: Project;
    if (this.projects.has(id)) {
      project = this.projects.get(id)!;
      project.update(data);
    } else {
      project = new Project(data);
      this.projects.set(id, project);
    }

    return project;
  }

  @modelFlow
  listProjects = _async(function* (
    this: ProjectStore,
    filters?: ProjectFilters
  ) {
    let response: Response;

    const filterQuery = new URLSearchParams(
      filters as URLSearchParams
    ).toString();
    const projectUrl = `projects?${filterQuery}`;

    try {
      response = yield* _await(fetchAPI(projectUrl));
    } catch {
      this.errors.push({
        source: "listProjects",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let items: ModelCreationData<Project>[];
    let count: number;
    ({ results: items, count } = yield* _await(response.json()));

    // ***: Check how may projects with isAllUsersAreMembers
    const isAllUsersAreMembersCount = items.filter(
      (proj: ModelCreationData<Project>) => proj.isAllUsersAreMembers
    ).length;

    items = items.filter(
      (proj: ModelCreationData<Project>) => !proj.isAllUsersAreMembers
    );

    let idList: string[] = [];
    items.forEach((data: ModelCreationData<Project>) => {
      this.createOrUpdateProject(data);
      idList.push(data._id);
    });
    const newCount = count || 0;
    this.setCount(
      isAllUsersAreMembersCount > 0 && newCount > 0
        ? newCount - isAllUsersAreMembersCount
        : newCount
    );

    return {
      details: "Successfully fetched",
      ok: true,
      results: items as Project[],
    };
  });

  @modelFlow
  getProject = _async(function* (this: ProjectStore, _id: string) {
    this.setSelectedProjectId(null);
    const detailUrl = `projects/${_id}`;
    let response: Response;

    try {
      response = yield* _await(fetchAPI(detailUrl));
    } catch {
      this.errors.push({
        source: "getProject",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let retrievedProject: ModelCreationData<Project>;
    ({ response: retrievedProject } = yield* _await(response.json()));
    this.createOrUpdateProject(retrievedProject);
    this.setSelectedProjectId(_id);

    return {
      details: "Successfully fetched project",
      ok: true,
      results: retrievedProject,
    };
  });

  @modelFlow
  listProjectMembers = _async(function* (
    this: ProjectStore,
    _id: string,
    filters?: MembersFilter
  ) {
    const filterQuery = new URLSearchParams(filters).toString();
    const projectMembersUrl = `projects/${_id}/members?${filterQuery}`;

    let response: Response;

    try {
      response = yield* _await(fetchAPI(projectMembersUrl));
    } catch {
      this.errors.push({
        source: "listProjectMembers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let projectMembers: ProjectMember[];
    ({ results: projectMembers } = yield* _await(response.json()));

    const results = projectMembers.filter((item) => !item.user.isDeactivated);

    return {
      details: "Successfully fetched project members",
      ok: true,
      results,
    };
  });

  @modelFlow
  getInvoice = _async(function* (
    this: ProjectStore,
    _id: string,
    query: ProjectInvoiceQuery
  ) {
    let response: Response;

    const queryString = new URLSearchParams(query).toString();
    try {
      response = yield* _await(
        fetchAPI(`projects/${_id}/invoice?${queryString}`)
      );
    } catch {
      this.errors.push({
        source: "createProject",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if ([404, 400].includes(response.status)) {
      const responseJSON = yield* _await(response.json());
      const { error } = responseJSON;
      return { details: error, ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let invoiceResponse: ProjectInvoiceResponse;
    invoiceResponse = yield* _await(response.json());
    const { error, ...results } = invoiceResponse;

    return {
      details: "Successfully created the invoice",
      ok: true,
      results,
    };
  });

  @modelFlow
  createProject = _async(function* (
    this: ProjectStore,
    project: ProjectCreationData
  ) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI("projects", {
          method: "POST",
          body: JSON.stringify(project),
          headers: { "Content-Type": "application/json" },
        })
      );
    } catch {
      this.errors.push({
        source: "createProject",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      ({ error: response } = yield* _await(response.json()));
      return {
        details: `${response}` || "Unable to create the project",
        ok: false,
      };
    }

    let createdProject: ModelCreationData<Project>;
    ({ response: createdProject } = yield* _await(response.json()));
    this.createOrUpdateProject(createdProject);

    return {
      details: "Successfully created project",
      ok: true,
      results: createdProject,
    };
  });

  @modelFlow
  editProject = _async(function* (
    this: ProjectStore,
    projectId: string,
    data: ProjectEditData
  ) {
    let response: Response;
    let errorMessage: String;

    try {
      response = yield* _await(
        fetchAPI(`projects/${projectId}`, {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
        })
      );
    } catch {
      this.errors.push({
        source: "editProject",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      ({ message: errorMessage } = yield* _await(response.json()));
      return { details: errorMessage, ok: false };
    }

    let updatedProject: ModelCreationData<Project>;
    ({ response: updatedProject } = yield* _await(response.json()));
    this.createOrUpdateProject(updatedProject);

    return {
      details: "Successfully updated project",
      ok: true,
      results: updatedProject,
    };
  });

  @modelFlow
  archiveProject = _async(function* (this: ProjectStore, _id: string) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI(`projects/${_id}/archive`, { method: "POST" })
      );
    } catch {
      this.errors.push({
        source: "archiveProject",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let archivedProject: ModelCreationData<Project>;
    ({ response: archivedProject } = yield* _await(response.json()));
    this.createOrUpdateProject(archivedProject);

    return {
      details: "Successfully archived project",
      ok: true,
      results: archivedProject,
    };
  });

  @modelFlow
  unarchiveProject = _async(function* (this: ProjectStore, _id: string) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI(`projects/${_id}/unarchive`, { method: "POST" })
      );
    } catch {
      this.errors.push({
        source: "archiveProject",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let restoredProject: ModelCreationData<Project>;
    ({ response: restoredProject } = yield* _await(response.json()));
    this.createOrUpdateProject(restoredProject);

    return {
      details: "Successfully restored project",
      ok: true,
      results: restoredProject,
    };
  });

  @modelFlow
  addProjectMembers = _async(function* (
    this: ProjectStore,
    id: string,
    newUsersEmail: string[]
  ) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI(`projects/${id}/members/add`, {
          method: "POST",
          body: JSON.stringify({ members: newUsersEmail }),
          headers: { "Content-Type": "application/json" },
        })
      );
    } catch {
      this.errors.push({
        source: "addProjectMembers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let responseData: ModelCreationData<Project>;
    ({ response: responseData } = yield* _await(response.json()));
    this.createOrUpdateProject(responseData);

    return {
      details: "Successfully fetched",
      ok: true,
      results: responseData,
    };
  });

  @modelFlow
  editProjectMembers = _async(function* (this: ProjectStore, project: Project) {
    let response: Response;

    try {
      const { _id: projectId, memberEdits } = project;
      response = yield* _await(
        fetchAPI(`projects/${projectId}/members/edit`, {
          method: "PATCH",
          body: JSON.stringify({ memberEdits }),
          headers: { "Content-Type": "application/json" },
        })
      );
    } catch {
      this.errors.push({
        source: "editProjectMembers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let responseData: Pick<ModelCreationData<Project>, "_id" | "members">;
    ({ response: responseData } = yield* _await(response.json()));
    this.createOrUpdateProject(responseData as Project);

    return {
      details: "Successfully fetched",
      ok: true,
      results: responseData,
    };
  });

  @modelFlow
  removeProjectMembers = _async(function* (
    this: ProjectStore,
    id: string,
    removeMembersIds: string[]
  ) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI(`projects/${id}/members/remove`, {
          method: "DELETE",
          body: JSON.stringify({ members: removeMembersIds }),
          headers: { "Content-Type": "application/json" },
        })
      );
    } catch {
      this.errors.push({
        source: "removeProjectMembers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let responseData: ModelCreationData<Project>;
    ({ response: responseData } = yield* _await(response.json()));
    this.createOrUpdateProject(responseData);

    return {
      details: "Successfully removed",
      ok: true,
      results: responseData,
    };
  });

  @modelFlow
  getMemberRateHistory = _async(function* (
    this: ProjectStore,
    memberId: string
  ) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI(`members/${memberId}/rate-history`));
    } catch {
      this.errors.push({
        source: "getMemberRateHistory",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let rateHistoryList: MemberRateHistory[];
    ({ response: rateHistoryList } = yield* _await(response.json()));

    return {
      details: "Successfully fetched member rate history",
      ok: true,
      results: rateHistoryList,
    };
  });

  @modelFlow
  listProjectActivityLogs = _async(function* (this: ProjectStore, _id: string) {
    let response: Response;
    try {
      response = yield* _await(fetchAPI(`projects/${_id}/logs`));
    } catch {
      this.errors.push({
        source: "listProjectActivityLogs",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let activityLogs: ActivityLogType[];
    ({ results: activityLogs } = yield* _await(response.json()));

    return {
      details: "Successfully fetched project activity logs",
      ok: true,
      results: activityLogs,
    };
  });

  @modelFlow
  createGitlabRepo = _async(function* (this: ProjectStore, _id: string) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI(`projects/${_id}/create-gitlab`, { method: "POST" })
      );
    } catch {
      this.errors.push({
        source: "createGitlabRepo",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let project: ModelCreationData<Project>;
    ({ response: project } = yield* _await(response.json()));
    this.createOrUpdateProject(project);

    return {
      details: "Successfully created gitlab for the project",
      ok: true,
      results: project,
    };
  });
}

import { Button, Popper, useAutocomplete } from "@mui/base";
import Avatar from "components/Avatar";
import { User } from "models";
import React from "react";
import { twMerge } from "tailwind-merge";

export type MultiselectOption = {
  label: string;
  key: string;
  category: string;
  sublabel?: string;
  icon?: string;
  user?: User;
} & Record<string, unknown>;

export type MultiselectGroup = {
  group: string;
  key: number;
  index: number;
  options: MultiselectOption[];
} & Record<string, unknown>;

type MultiselectProps = {
  label: string;
  onChange: (value: MultiselectOption[]) => void;
  options: MultiselectOption[];
  value: MultiselectOption[];
  className?: string;
  addIconClassName?: string;
  isShowLabelAtTop?: boolean;
  isOptionsWithIcon?: boolean;
};

const POPPER_MARGIN_BOTTOM_PX = 16;

const MultiselectGrouped = React.forwardRef<HTMLInputElement, MultiselectProps>(
  (
    {
      label,
      onChange,
      options,
      value,
      className,
      addIconClassName,
      isOptionsWithIcon = false,
      isShowLabelAtTop = true,
    },
    _ref
  ) => {
    const autocomplete = useAutocomplete<MultiselectOption, true>({
      componentName: "Multiselect",
      isOptionEqualToValue: (option, value) => option.key === value.key,
      multiple: true,
      onChange: () => {},
      options,
      value,
      groupBy: (option) => option.category,
    });

    const anchorRef = React.useRef<HTMLDivElement>(null);

    const selectedOptionKeys = value.map((item) => item.key);

    const selectOption = (optionToSelect: MultiselectOption) => {
      if (selectedOptionKeys.includes(optionToSelect.key)) {
        return;
      }
      onChange([...value, optionToSelect]);
    };

    const unselectOption = (optionToUnselect: MultiselectOption) => {
      if (!selectedOptionKeys.includes(optionToUnselect.key)) {
        return;
      }
      onChange(
        value.filter(
          (selectedOption) => selectedOption.key !== optionToUnselect.key
        )
      );
    };

    const toggleOption = (optionToToggle: MultiselectOption) => {
      selectedOptionKeys.includes(optionToToggle.key)
        ? unselectOption(optionToToggle)
        : selectOption(optionToToggle);
    };

    const [popperMaxHeight, setPopperMaxHeight] = React.useState<number>(0);

    React.useEffect(() => {
      const handleResize = () => {
        if (anchorRef.current === null) {
          return;
        }
        setPopperMaxHeight(
          window.innerHeight -
            anchorRef.current.getBoundingClientRect().bottom -
            POPPER_MARGIN_BOTTOM_PX
        );
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [anchorRef.current]);

    return (
      <div>
        <div className="mb-1">
          {isShowLabelAtTop && (
            <label className="mb-2 text-sm font-semibold">
              <span className="text-slate opacity-70">{label}</span>
            </label>
          )}
        </div>
        <div
          {...autocomplete.getRootProps()}
          className="relative flex flex-col"
        >
          <div className="relative flex" ref={anchorRef}>
            <input
              {...autocomplete.getInputProps()}
              className={twMerge(
                "color-slate placeholder:color-slate-lighter h-10 max-w-[100%] grow rounded-lg border border-gray-300 px-3 py-2 text-base focus:outline-none",
                className
              )}
              placeholder={`Select ${label}`}
              type="text"
            />
            <Button
              {...autocomplete.getPopupIndicatorProps()}
              className={twMerge("absolute right-2 top-2", addIconClassName)}
            >
              <span
                className={twMerge(
                  "material-icons text-dark-900 text-opacity-70 transition-transform",
                  autocomplete.popupOpen && "rotate-180"
                )}
              >
                add_circle_outline
              </span>
            </Button>
          </div>
        </div>
        <div className="mt-3 flex flex-wrap justify-start gap-2 empty:hidden">
          {value.map((option) => (
            <div
              className={twMerge(
                "relative flex h-8 items-center rounded-2xl border border-[#0C59AC80] bg-[#F9FBFE] p-10 py-1 pl-3.5 text-xs font-normal",
                isOptionsWithIcon && "pl-1"
              )}
              key={option.key}
            >
              {isOptionsWithIcon &&
                (option.icon ? (
                  <img
                    alt={option.label}
                    src={option.icon}
                    className="mr-2 h-6 w-6 rounded-full"
                  />
                ) : (
                  <Avatar
                    user={option.user}
                    width="w-6"
                    className="mr-2 h-6"
                    fontSize="text-base"
                  />
                ))}
              <span>{option.label}</span>
              <span
                className="material-icons absolute right-1 cursor-pointer text-primary-900"
                onClick={() => unselectOption(option)}
              >
                cancel
              </span>
            </div>
          ))}
        </div>
        {anchorRef.current && (
          <Popper
            anchorEl={anchorRef.current}
            disablePortal
            open={autocomplete.popupOpen}
            slotProps={{
              root: {
                className:
                  "bg-white rounded-lg box-border z-[510] overflow-y-hidden shadow-dropdown",
              },
            }}
            modifiers={[
              { name: "flip", enabled: false },
              { name: "preventOverflow", enabled: false },
            ]}
          >
            <ul
              {...autocomplete.getListboxProps()}
              className="overflow-y-auto p-2"
              style={{
                width: anchorRef.current.clientWidth,
                maxHeight: popperMaxHeight,
              }}
            >
              {(autocomplete.groupedOptions as MultiselectGroup[]).map(
                ({ group, index, key, options }) => {
                  const groupLabel = (
                    <li
                      className="flex cursor-default flex-row truncate rounded-lg px-1 py-2 font-bold"
                      key={key}
                    >
                      {group}
                    </li>
                  );

                  const groupOptions = options.map((option, index) => {
                    const optionProps = autocomplete.getOptionProps({
                      option,
                      index,
                    });

                    return (
                      <li
                        {...optionProps}
                        className={twMerge(
                          "flex cursor-pointer flex-row truncate rounded-lg px-4 py-2",
                          selectedOptionKeys.includes(option.key) &&
                            "bg-[#C8DA2B14]"
                        )}
                        key={option.key}
                        onClick={(event) => {
                          toggleOption(option);
                          optionProps.onClick!(event);
                        }}
                      >
                        {isOptionsWithIcon &&
                          (option.icon ? (
                            <img
                              alt={option.label}
                              src={option.icon}
                              className="mr-2 h-10 w-10 rounded-full"
                            />
                          ) : (
                            <Avatar
                              user={option.user}
                              width="w-10"
                              className="mr-2 h-10"
                              fontSize="text-base"
                            />
                          ))}
                        <div>
                          {option.label}
                          {option.sublabel && (
                            <>
                              <br />{" "}
                              <span className="text-xs">{option.sublabel}</span>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  });

                  return (
                    <React.Fragment key={key}>
                      {groupLabel}
                      {groupOptions}
                    </React.Fragment>
                  );
                }
              )}
              {autocomplete.groupedOptions.length === 0 && (
                <li className="truncate rounded-lg px-3 py-2">No results</li>
              )}
            </ul>
          </Popper>
        )}
      </div>
    );
  }
);

export default MultiselectGrouped;

// @ts-nocheck
import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { twMerge } from "tailwind-merge";

import { useStore } from "stores";

import { ProjectStatus } from "constants/project";

import Modal from "./Modal";
import Button from "../Button";
import OverflowContainer, { OverflowButton } from "../OverflowContainer";

import { formatDateRange } from "components/dateRangePicker/dates";
import DateRangePicker, {
  CustomInput,
} from "components/dateRangePicker/DateRangePicker";
import Multiselect from "components/inputs/Multiselect";

type FilterModalProps = {
  onApply: (inFilterModal?: boolean) => void;
  onClear: () => void;
  showModal?: boolean;
  onButtonClick?: () => void;
};

function FilterModal({
  onApply,
  onClear,
  showModal,
  onButtonClick,
}: FilterModalProps) {
  const filterModalRef = useRef(null);
  const statusButtonRef = useRef(null);
  const industryButtonRef = useRef(null);

  const {
    industryStore,
    projectStore: { searchFilters: filters },
  } = useStore();

  const useClickOutside = (callback: Function) => {
    const ref = useRef<any>(null);

    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
          callback();
        }
      };

      document.addEventListener("click", handleClick, true);

      return () => {
        document.removeEventListener("click", handleClick, true);
      };
    }, [ref, callback]);

    return ref;
  };

  function handleClickOutside() {
    setIsShowDatePicker(false);
  }

  const dateRangePickerRef = useClickOutside(handleClickOutside);

  const {
    statusOptions: selectedStatusOptions = [],
    industryOptions: selectedIndustryOptions = [],
    createdAtStart,
    createdAtEnd,
    isMyProject,
    modalFiltersActive,
  } = filters;

  const allowFiltering =
    selectedStatusOptions ||
    selectedIndustryOptions ||
    createdAtStart ||
    createdAtEnd;

  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);

  const FilterIcon = () => (
    <span
      className={twMerge(
        "material-icons-outlined text-normal md:text-2xl md:leading-none",
        modalFiltersActive ? "text-white" : "text-primary-900"
      )}
    >
      filter_list
    </span>
  );

  const clearFilters = () => {
    filters.clearModalFilters();
    filters.setModalFiltersActive(false);
    setIsShowDatePicker(false);
    onClear();
  };

  const industryOptions = useMemo(
    () =>
      industryStore.industries.map((item) => ({
        label: item.name,
        key: item._id,
      })),
    [industryStore.industries]
  );
  const statusOptions = useMemo(
    () =>
      Object.values(ProjectStatus).map((item) => ({
        label: item,
        key: item,
      })),
    []
  );

  const ModalContent = () => (
    <>
      {!showModal && (
        <OverflowButton className="pointer-events-none flex items-center py-4 text-lg font-semibold text-dark-900">
          Filters
        </OverflowButton>
      )}
      <div
        className={twMerge(
          "flex-col pl-4 pr-[.8125rem] hover:bg-transparent focus:bg-transparent md:flex",
          isShowDatePicker && "hidden"
        )}
      >
        <Multiselect
          label="Status"
          onChange={(value) => {
            filters.setStatusOptions(value);
            filters.setCurrentPage(1);
            statusButtonRef?.current?.click();
          }}
          options={statusOptions}
          value={selectedStatusOptions}
        />
      </div>

      <OverflowButton className="pointer-events-none flex items-center pb-[0.1rem] pt-2 text-sm font-semibold text-dark-900/70">
        Date Created
      </OverflowButton>
      <OverflowButton className="flex w-full flex-col items-center overflow-y-auto overflow-x-hidden hover:bg-transparent focus:bg-transparent">
        <CustomInput
          className={twMerge("md:flex", isShowDatePicker && "hidden")}
          value={formatDateRange(createdAtStart, createdAtEnd)}
          placeholder="Date"
          onClick={() => setIsShowDatePicker(!isShowDatePicker)}
        />
        {isShowDatePicker && (
          <div ref={dateRangePickerRef}>
            <DateRangePicker
              ref={dateRangePickerRef}
              onSubmit={(start, end) => {
                filters.setCreatedAtStart(start);
                filters.setCreatedAtEnd(end);
                setIsShowDatePicker(false);
              }}
              onCancel={() => setIsShowDatePicker(false)}
            />
          </div>
        )}
      </OverflowButton>
      <div className="flex flex-col pl-4 pr-[.8125rem] hover:bg-transparent focus:bg-transparent focus:pb-14">
        <Multiselect
          label="Industry"
          onChange={(value) => {
            filters.setIndustryOptions(value);
            filters.setCurrentPage(1);
            industryButtonRef?.current?.click();
          }}
          options={industryOptions}
          value={selectedIndustryOptions}
        />
      </div>
      <OverflowButton className="flex items-center pb-7 pt-3 lg:hidden">
        <input
          className="h-6 w-6 cursor-pointer"
          type="checkbox"
          checked={isMyProject}
          onChange={() => {
            filters.setIsMyProject(!isMyProject);
          }}
        />
        <span className="pl-3 text-base font-normal text-dark-900">
          View My Projects Only
        </span>
      </OverflowButton>
      <div className="flex w-full cursor-auto items-center justify-between px-5 py-7 hover:bg-transparent focus:bg-transparent">
        <Button
          category="primaryOutline"
          className="flex w-[48%] cursor-pointer items-center px-7 py-2 text-primary-900"
          onClick={() => clearFilters()}
        >
          Clear Filters
        </Button>
        <Button
          category="primary"
          className="flex w-[48%] items-center px-7 py-2 text-white"
          onClick={() => {
            if (allowFiltering) {
              filters.setModalFiltersActive(true);
              onApply(true);
            }
            filterModalRef?.current?.click();
          }}
        >
          Apply filter
        </Button>
      </div>
    </>
  );

  return (
    <div>
      {/* // ***: Desktop view  */}
      <OverflowContainer
        className="hidden w-[21rem] md:flex md:flex-col"
        buttonContent={
          <div
            ref={filterModalRef}
            className={twMerge(
              "flex max-w-fit cursor-pointer items-center gap-2 rounded-[60px] border border-[#0C59AC] px-3 py-1 text-white lg:px-4 lg:py-2",
              modalFiltersActive ? "bg-[#0C59AC]" : "bg-white"
            )}
            onClick={() => !!onButtonClick && onButtonClick()}
          >
            <span
              className={twMerge(
                "hidden text-sm font-semibold lg:flex",
                modalFiltersActive ? "text-white" : "text-primary-900"
              )}
            >
              Filter
            </span>{" "}
            <FilterIcon />
          </div>
        }
      >
        <ModalContent />
      </OverflowContainer>

      {/* // ***: Mobile view  */}
      {showModal && (
        <Modal
          className="md:hidden"
          handleClose={() => !!onButtonClick && onButtonClick()}
          stickToBottom={true}
          title="Filters"
        >
          <ModalContent />
        </Modal>
      )}
    </div>
  );
}

export default observer(FilterModal);

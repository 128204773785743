import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Modal } from "@mui/base/Modal";

import { useStore } from "stores";
import { useToast } from "components/toast/context";

import { projectClassificationOptions, ProjectStatus } from "constants/project";

import Backdrop from "./Backdrop";
import Button from "components/Button";
import MultiselectGrouped, {
  MultiselectOption,
} from "components/inputs/MultiselectGrouped";
import Select, { SelectOption } from "components/inputs/Select";
import TextField from "components/inputs/TextField";
import LoadingIndicator from "components/LoadingIndicator";
import Fade from "components/transitions/Fade";

type CreateNewProjectModalInputs = {
  clientName: string;
  classification: SelectOption | null;
  industry: SelectOption | null;
  projectName: string;
  techStacks: MultiselectOption[];
  isWithGitlabRepo?: boolean;
  isWithMattermostChannel?: boolean;
  isWithGDrive?: boolean;
};

type CreateNewProjectModalProps = {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectHasBeenAdded?: () => void;
};

const CreateNewProjectModal: React.FC<CreateNewProjectModalProps> = ({
  open,
  setOpen = () => {},
  setProjectHasBeenAdded = () => {},
}) => {
  const { showToastVariant } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({ name: "" });

  const { industryStore, projectStore, techStackStore } = useStore();

  const { control, handleSubmit, reset, watch } =
    useForm<CreateNewProjectModalInputs>({
      defaultValues: {
        clientName: "",
        classification: null,
        industry: null,
        projectName: "",
        techStacks: [],
        isWithGitlabRepo: false,
        isWithMattermostChannel: true,
        isWithGDrive: true,
      },
    });
  const projectName = watch("projectName");

  const handleCreateNewProject: SubmitHandler<
    CreateNewProjectModalInputs
  > = async ({
    clientName,
    classification,
    industry,
    projectName,
    techStacks,
    isWithGitlabRepo,
    isWithMattermostChannel,
    isWithGDrive,
  }) => {
    setLoading(true);
    setErrors({ name: "" });

    const industries = industry === null ? [] : [industry];
    const createProjectData = {
      client: {
        name: clientName,
        email: undefined,
      },
      classification: classification?.key,
      industries: industries.map((item) => item.key),
      name: projectName,
      techStacks: techStacks.map((item) => item.key),
      status: ProjectStatus.Proposal,
      isWithGitlabRepo,
      isWithMattermostChannel,
      isWithGDrive,
    };
    const response = await projectStore.createProject(createProjectData);

    if (!response.ok) {
      setLoading(false);
      setErrors({ name: response.details });
      return;
    }

    setLoading(false);
    setOpen(false);
    reset();
    setErrors({ name: "" });
    setProjectHasBeenAdded();
    showToastVariant({
      variant: "success",
      title: "Success",
      subtitle: "Project successfully created",
    });
  };

  const industryOptions = React.useMemo(
    () =>
      industryStore.industries.map((item) => ({
        label: item.name,
        key: item._id,
      })),
    [industryStore.industries]
  );

  const techStackOptions = React.useMemo(
    () =>
      techStackStore.techStacks.map((item) => ({
        label: item.name,
        key: item._id,
        category: item.category,
      })),
    [techStackStore.techStacks]
  );

  return (
    <Fade open={open}>
      <Modal
        className="fixed inset-0 z-[500] flex items-center justify-center"
        disableAutoFocus
        disablePortal
        onClose={() => setOpen(false)}
        open
        slots={{
          backdrop: Backdrop,
        }}
      >
        <div className="fixed bottom-0 w-screen rounded-2xl bg-white shadow-dialog !outline-none md:static md:w-[600px]">
          <div className="box-content flex h-11 items-center border-b border-gray-300 px-4 py-2">
            <div className="text-primary flex grow flex-col gap-5 text-lg font-semibold">
              Create New Project
            </div>
            <button
              className="material-icons p-3 text-[20px] text-dark-900 text-opacity-70"
              onClick={() => setOpen(false)}
            >
              close
            </button>
          </div>
          <div
            className="flex flex-col gap-5 overflow-y-auto p-4"
            style={{ maxHeight: "calc(100vh - 128px" }}
          >
            <Controller
              name="projectName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label="Project Name"
                  required
                  errors={errors.name}
                  {...field}
                />
              )}
            />
            <Controller
              name="clientName"
              control={control}
              render={({ field }) => (
                <TextField label="Client Name" {...field} />
              )}
            />
            <Controller
              name="classification"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Classification"
                  isRadio
                  onChange={onChange}
                  options={projectClassificationOptions}
                  value={value}
                />
              )}
            />
            <Controller
              name="industry"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Industry"
                  onChange={onChange}
                  options={industryOptions}
                  value={value}
                />
              )}
            />
            <Controller
              name="techStacks"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultiselectGrouped
                  label="Tech Stack"
                  onChange={onChange}
                  options={techStackOptions}
                  value={value}
                />
              )}
            />
            <Controller
              name="isWithGitlabRepo"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-row">
                  <input
                    id="gitlab-check"
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                  />
                  <label
                    htmlFor="gitlab-check"
                    className="cursor-pointer pl-3 pt-1 text-sm font-semibold"
                  >
                    <span className="text-slate opacity-70">
                      Generate Gitlab Repository?
                    </span>
                  </label>
                </div>
              )}
            />
            <Controller
              name="isWithMattermostChannel"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-row">
                  <input
                    id="mattermost-check"
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                  />
                  <label
                    htmlFor="mattermost-check"
                    className="cursor-pointer pl-3 pt-1 text-sm font-semibold"
                  >
                    <span className="text-slate opacity-70">
                      Generate Mattermost Channel?
                    </span>
                  </label>
                </div>
              )}
            />
            <Controller
              name="isWithGDrive"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-row">
                  <input
                    id="gdrive-check"
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                  />
                  <label
                    htmlFor="gdrive-check"
                    className="cursor-pointer pl-3 pt-1 text-sm font-semibold"
                  >
                    <span className="text-slate opacity-70">
                      Generate Gdrive folder
                    </span>
                  </label>
                </div>
              )}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 p-4">
            <Button category="primaryOutline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              category="primary"
              disabled={!projectName.length || loading}
              onClick={handleSubmit(handleCreateNewProject)}
            >
              {!loading ? (
                <>Create Project</>
              ) : (
                <>
                  Creating Project
                  <LoadingIndicator />
                </>
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </Fade>
  );
};

export default observer(CreateNewProjectModal);

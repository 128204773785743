import {
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  endOfYear,
  startOfYear,
} from "date-fns";

export const naiveDate = (dateStr?: Date | string | null): Date => {
  let date = new Date();

  if (dateStr) {
    date = new Date(dateStr);
  }
  const naive = date.toISOString().slice(0, -1);

  return new Date(naive);
};

export const formatDateRange = (
  from: Date | undefined,
  to: Date | undefined,
  withYear: boolean = true
) => {
  if (!from || !to) return "";

  const yearFormat = withYear ? ", yyyy" : "";

  if (isSameDay(from, startOfYear(from)) && isSameDay(to, endOfYear(to))) {
    return format(from, "yyyy");
  } else if (!isSameYear(from, to)) {
    return `${format(from, "MMM dd, yyyy")} - ${format(to, "MMM dd, yyyy")}`;
  } else if (!isSameMonth(from, to)) {
    return `${format(from, "MMM dd")} - ${format(to, "MMM dd" + yearFormat)}`;
  } else if (!isSameDay(from, to)) {
    return `${format(from, "MMM dd")} - ${format(to, "dd" + yearFormat)}`;
  }

  return `${format(from, "MMM dd, yyyy")}`;
};

export function areSameDates(a: Date, b: Date) {
  return a.getTime() === b.getTime();
}

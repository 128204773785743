import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Project } from "models";
import { useStore } from "stores";
import { ProjectInfoEditInputs, ProjectInvoiceSettings } from "types";

import { invoiceSplitOptions, visibleColumnOptions } from "constants/invoice";
import { ProjectStatus, projectClassificationOptions } from "constants/project";

import Root from "components/layout/Root";
import ArchiveProjectModal from "components/modals/ArchiveOrRestoreProjectModal";
import AddMemberModal from "components/modals/AddMemberModal";
import ProjectActivity from "components/projectDetail/ProjectActivity";
import ProjectInfo from "components/projectDetail/ProjectInfo";
import ProjectInfoEdit from "components/projectDetail/ProjectInfoEdit";
import ProjectMembers from "components/projectDetail/ProjectMembers";
import ProjectInfoHeaderActions from "components/projectDetail/ProjectInfoHeaderActions";
import ProjectMembersHeaderActions from "components/projectDetail/ProjectMembersComponents/HeaderActions";
import ProjectInvoiceGenerator from "components/projectDetail/ProjectInvoiceGenerator";
import InvoiceHeaderActions from "components/projectDetail/ProjectInvoiceHeaderAction";
import TabTitle from "components/projectDetail/TabTitle";
import { useToast } from "components/toast/context";

type Tabs = "info" | "members" | "activity" | "invoice";

type TabDataType = { name: string; screen: Tabs };

const ProjectDetail = () => {
  const navigate = useNavigate();
  const { authStore, industryStore, projectStore, techStackStore } = useStore();
  const { showToastVariant } = useToast();
  const { projectId } = useParams<{ projectId: string }>();
  const [searchParams] = useSearchParams();
  const toBeArchived = searchParams.get("archive") || "";
  let tab = (searchParams.get("tab") as Tabs) || "";
  if (["info", "members", "activity", "invoice"].indexOf(tab) < 0) tab = "info";

  const [isProjectMemberOrAdmin, setIsProjectMemberOrAdmin] =
    useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isShowArchiveOrRestoreModal, setIsShowArchiveOrRestoreModal] =
    useState<boolean>(["true", "false"].indexOf(toBeArchived) >= 0);
  const [isShowAddMemberModal, setIsShowAddMemberModal] =
    useState<boolean>(false);
  const [isInfoEditing, setIsInfoEditing] = useState<boolean>(false);
  const [isInfoSaving, setIsInfoSaving] = useState<boolean>(false);

  const [projectEdits, setProjectEdits] = useState<ProjectInfoEditInputs>(
    {} as ProjectInfoEditInputs
  );

  const [invoiceSettings, setInvoiceSettings] =
    useState<ProjectInvoiceSettings>({
      splitType: invoiceSplitOptions,
      visibleColumns: visibleColumnOptions,
    });

  const [currentTab, setCurrentTab] = useState<Tabs>(tab);

  const {
    _id: currentUserId = "",
    fullName = "",
    isManager = false,
  } = authStore?.currentUser || {};
  const { selectedProject: project } = projectStore;

  const tabData: TabDataType[] = [
    {
      name: "Project Info",
      screen: "info",
    },
    {
      name: "Project Members",
      screen: "members",
    },
    {
      name: "Activity Log",
      screen: "activity",
    },
    {
      name: "Invoice Generator",
      screen: "invoice",
    },
  ];

  const setProjectDefaults = (project: Project, addLink?: boolean) => {
    const classification = projectClassificationOptions.find(
      (item) => item.key === project.classification
    ) ?? { key: "", label: "" };
    const industry =
      project.industries.length > 0
        ? { key: project.industries[0]._id, label: project.industries[0].name }
        : { key: "", label: "" };
    const techStacks = project.techStacks.map((ts) => ({
      key: ts._id,
      label: ts.name,
      category: ts.category,
    }));

    let customLinks = project.customLinks.map((cl, idx) => ({
      id: `${idx}`,
      name: cl.name,
      url: cl.url,
    }));

    // Make sure the addLink is the boolean true and not Synthetic event
    if (addLink === true) {
      customLinks = [...customLinks, { id: "-a", name: "", url: "" }];
    }

    setProjectEdits({
      name: project.name,
      status: project.status,
      client: project.client || { name: "", email: "" },
      classification,
      industries: industry,
      techStacks: techStacks,

      redmine: project.redmine,
      mattermostChannelUrl: project.mattermostChannelUrl,
      googleDrive: project.googleDrive,
      figma: project.figma,
      gitlab: project.gitlab,
      customLinks: customLinks,
    });
  };

  const fetchProject = useCallback(async () => {
    setIsFetching(true);
    if (projectId === undefined) {
      setIsFetching(false);
      return;
    }
    const response = await projectStore.getProject(projectId);
    if (!response.ok) navigate("/");

    const isMember = projectStore.isProjectMember(projectId, currentUserId);
    setIsProjectMemberOrAdmin(isManager || isMember);

    setIsFetching(false);
  }, [projectId, projectStore, currentUserId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    (async () => {
      await industryStore.fetchIndustries();
      await techStackStore.fetchTechStacks();
    })();
    // eslint-disable-next-line
  }, []);

  // ***: Hide Invoice generator tab to production for now
  useEffect(() => {
    if (
      currentTab === "invoice" &&
      (!isProjectMemberOrAdmin || process.env.REACT_APP_ENV === "production")
    ) {
      setCurrentTab("info");
    }
  }, [currentTab, isProjectMemberOrAdmin]);

  const toggleInfoEdit = (addLink?: boolean) => {
    if (project) {
      setIsInfoEditing((prev) => !prev);
      setProjectDefaults(project, addLink);
    }
  };

  const archiveProject = () => {
    setIsShowArchiveOrRestoreModal(true);
  };

  const submitInfoEdit = async () => {
    if (!projectId) {
      return;
    }
    setIsInfoSaving(true);

    const { mattermostChannelUrl, mattermostChannelId, classification } =
      projectEdits;
    if (
      !project?.mattermostChannelUrl &&
      ((mattermostChannelUrl && !mattermostChannelId) ||
        (!mattermostChannelUrl && mattermostChannelId))
    ) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle:
          "Validation failed: mattermostChannelUrl and mattermostChannelId should be both set.",
      });
      setIsInfoSaving(false);
      return;
    }

    const industryData = projectEdits.industries.key
      ? [projectEdits.industries.key]
      : [];
    const techStackData = projectEdits.techStacks.map((ts) => ts.key);
    const customLinkData = projectEdits.customLinks.map((cl) => ({
      name: cl.name,
      url: cl.url,
    }));

    const editData = {
      ...projectEdits,
      classification: classification?.key,
      industries: industryData,
      techStacks: techStackData,
      customLinks: customLinkData,
    };

    const response = await projectStore.editProject(projectId, editData);
    setIsInfoSaving(false);

    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: response.details as string,
      });
      return;
    }

    showToastVariant({
      variant: "success",
      title: "Success",
      subtitle: "Project successfully updated",
    });
    setIsInfoEditing(false);
  };

  const projectIsArchived = project?.status === ProjectStatus.Archived;

  return (
    <Root contentClassName="overflow-x-hidden">
      {!isFetching && project && (
        <div className="flex flex-auto flex-col gap-6 py-6">
          <div className="flex flex-col px-6 md:flex-row md:items-center md:justify-between">
            <div>
              <h2 className="text-lg font-semibold md:text-2xl">{fullName}</h2>
              <div className="flex flex-wrap items-center gap-2 text-sm md:text-base">
                <p
                  className="text-primary-900 hover:cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  Project Dashboard
                </p>
                <span className="material-icons text-sm font-semibold">
                  chevron_right
                </span>
                <span className="text-dark-900/70">{project?.name}</span>
              </div>
            </div>
            {isProjectMemberOrAdmin && currentTab === "info" && (
              <ProjectInfoHeaderActions
                isArchived={project.status === ProjectStatus.Archived}
                isEditing={isInfoEditing}
                isSaving={isInfoSaving}
                onArchiveProject={archiveProject}
                onRestoreProject={archiveProject}
                onToggleEdit={toggleInfoEdit}
                onCancelEdit={toggleInfoEdit}
                onSubmitEdit={submitInfoEdit}
              />
            )}
            {isProjectMemberOrAdmin &&
              !projectIsArchived &&
              currentTab === "members" && (
                <ProjectMembersHeaderActions
                  setIsShowAddMemberModal={setIsShowAddMemberModal}
                />
              )}
            {isProjectMemberOrAdmin &&
              !projectIsArchived &&
              currentTab === "invoice" && (
                <InvoiceHeaderActions invoiceSettings={invoiceSettings} />
              )}
          </div>

          <div className="w-screen md:w-full md:px-6">
            <div className="mx-0 flex flex-row gap-3 overflow-y-auto border-b pt-3 md:mx-0 md:pt-6">
              {tabData.map(({ name, screen }) => {
                // ***: Invoice generator tab is only visible to project's members, and the admins
                // ***: Hide Invoice generator tab to production for now
                if (
                  screen === "invoice" &&
                  (!isProjectMemberOrAdmin ||
                    process.env.REACT_APP_ENV === "production")
                )
                  return <></>;
                return (
                  <TabTitle
                    key={name}
                    isActive={currentTab === screen}
                    onClick={() => {
                      setCurrentTab(screen);
                      navigate(`/projects/${projectId}?tab=${screen}`);
                    }}
                  >
                    {name}
                  </TabTitle>
                );
              })}
            </div>
          </div>
          <div className="grid">
            {currentTab === "info" && !isInfoEditing && (
              <ProjectInfo
                project={project}
                isEditable={isProjectMemberOrAdmin}
                onToggleEdit={toggleInfoEdit}
              />
            )}
            {currentTab === "info" && isInfoEditing && (
              <ProjectInfoEdit
                isSaving={isInfoSaving}
                isWithMattermost={!!project.mattermostChannelUrl}
                isWithRedmine={!!project.redmine}
                isWithGdrive={!!project.googleDrive}
                project={projectEdits}
                setProjectEdits={setProjectEdits}
              />
            )}
            {currentTab === "members" && <ProjectMembers project={project} />}
            {currentTab === "activity" && <ProjectActivity project={project} />}
            {isProjectMemberOrAdmin && currentTab === "invoice" && (
              <ProjectInvoiceGenerator
                invoiceSettings={invoiceSettings}
                setInvoiceSettings={setInvoiceSettings}
              />
            )}
          </div>
        </div>
      )}

      {project && (
        <>
          <ArchiveProjectModal
            project={project}
            showModal={isShowArchiveOrRestoreModal}
            onClose={() => setIsShowArchiveOrRestoreModal(false)}
          />
          <AddMemberModal
            currentMemberIds={project.members
              .filter((item) => !item.user.isDeactivated)
              .map((membership) => membership.user._id)}
            projectId={project._id}
            showModal={isShowAddMemberModal}
            onClose={() => setIsShowAddMemberModal(false)}
          />
        </>
      )}
    </Root>
  );
};

export default observer(ProjectDetail);

import { useEffect, useRef } from "react";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";

type LoadingSuspenseProps = {
  barColor?: string;
};

export default function LoadingSuspense({
  barColor = "#C8DA2B",
}: LoadingSuspenseProps) {
  const ref = useRef<LoadingBarRef>(null);

  // ref.current.continuousStart();
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.continuousStart();
  //   }
  // }, []);
  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
  }, []);
  return (
    // <div className=" mx-auto grid h-screen max-w-[82.5rem] place-items-center">
    //   <div className="flex justify-center">
    //     <MahusaiLogo className="animate-pulse" width="98.4" height="48" />
    //   </div>
    // </div>
    <div>
      <LoadingBar color={barColor} ref={ref} shadow />
    </div>
  );
}

import { Input } from "@mui/base";
import React from "react";

type NumericInputProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: number;
};

const DollarSignAdornment: React.FC = () => {
  return (
    <div className="inline-flex items-center justify-center text-slate">$</div>
  );
};

const NumericInput: React.FC<NumericInputProps> = ({ onChange, value }) => {
  const slotProps = {
    input: { className: "w-full border-none outline-none text-slate" },
    root: {
      className:
        "flex border border-slate-lighter p-2 rounded-lg overflow-hidden",
    },
  };

  return (
    <Input
      slotProps={slotProps}
      startAdornment={<DollarSignAdornment />}
      type="number"
      onChange={onChange}
      value={value}
    />
  );
};

export default NumericInput;

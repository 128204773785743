export const FieldLabel = ({ text }: { text: string }) => (
  <label className="mb-2 text-sm font-semibold">
    <span className="text-slate opacity-70">{text}</span>
  </label>
);

export const InfoHeader = ({ text }: { text: string }) => (
  <div className="flex items-center gap-4 border-t px-6 pb-6 pt-12">
    <p className="text-sm font-semibold tracking-[1px] text-dark-900">{text}</p>
  </div>
);

export const InputWrapper = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="mx-6 flex flex-col pb-6">
    <FieldLabel text={label} />
    {children}
  </div>
);

export const LinkWrapper = ({
  label,
  children,
  icon: Icon,
}: {
  label?: string;
  children: React.ReactNode;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}) => (
  <div className="flex flex-row items-center gap-6 py-4">
    <div className="h-[36px] w-[36px]">
      <Icon />
    </div>
    <div className="col-span-5 w-full break-words pr-2">
      <p className="text-sm font-normal leading-5 text-dark-900/70">{label}</p>
      {children}
    </div>
  </div>
);

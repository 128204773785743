const Greeting = ({ nickname }: { nickname: string }) => {
  const getGreeting = () => {
    const currentHourTime = new Date().getHours();
    if (currentHourTime <= 12) {
      return "Good morning";
    } else if (currentHourTime > 12 && currentHourTime < 18) {
      return "Good afternoon";
    }
    return "Good evening";
  };

  return (
    <div className="greeting">
      <h2 className="text-lg font-semibold lg:text-2xl">
        {getGreeting()}, {nickname}
      </h2>
      <p className="text-xs leading-5 text-dark-900/70">
        Here's the overview of your projects
      </p>
    </div>
  );
};

export default Greeting;

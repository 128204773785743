import { useState } from "react";
import { Project, ProjectSearchFilter } from "models";

import { membersTableColumnHeaders } from "components/projectDetail/ProjectMembersComponents/Table/common";
import { TableColHeader } from "types";

import { SearchIcon } from "components/dashboard/Icons";
import FilterModal from "components/modals/FilterModal";
import SortTableModal from "components/modals/SortTableModal";

type MobileHeaderProps = {
  filters: ProjectSearchFilter;
  filterListedProject: (
    inFilterModal?: boolean
  ) => Promise<Project[] | undefined>;
  fetchAndUpdateProjectsList: (col: TableColHeader, idx: number) => void;
};

const MobileHeader = ({
  filters,
  filterListedProject,
  fetchAndUpdateProjectsList,
}: MobileHeaderProps) => {
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [isShowSortTableModal, setIsShowSortTableModal] =
    useState<boolean>(false);

  const { name } = filters;

  const onClearFilterProject = () => {
    filters.setCurrentPage(1);
    filters.setName("");
    filterListedProject();
  };

  return (
    <thead className="table-header-group rounded-tl-sm rounded-tr-sm border md:hidden md:bg-[#F9FBFE]">
      <tr>
        <th
          className="flex flex-row py-4 pl-4 text-left font-normal"
          scope="col"
        >
          <SearchIcon />
          <input
            className="w-3/4 focus:border-none focus:outline-0"
            onChange={(event) => filters.setName(event.target.value)}
            onKeyUp={(event) => {
              const keyCode = event.code || event.key;
              if (keyCode === "Enter" || keyCode === "NumpadEnter") {
                filterListedProject();
              }
            }}
            placeholder="Search"
            value={name}
          />
        </th>
        <th className="py-4 text-left font-normal" scope="col">
          <div className="flex justify-center">
            <FilterModal
              showModal={isShowFilterModal}
              onButtonClick={() => setIsShowFilterModal((prev) => !prev)}
              onApply={filterListedProject}
              onClear={onClearFilterProject}
            />
          </div>
        </th>
        <th className="px-2 py-4 text-left font-normal" scope="col">
          <div className="flex justify-center">
            <SortTableModal
              showModal={isShowSortTableModal}
              columnHeaders={membersTableColumnHeaders.slice(
                0,
                membersTableColumnHeaders.length - 1
              )}
              onButtonClick={() => setIsShowSortTableModal((prev) => !prev)}
              onApply={(col: TableColHeader | undefined) => {
                if (!col) return;

                const idx = membersTableColumnHeaders
                  .map((item) => item.title)
                  .indexOf(col.title);
                fetchAndUpdateProjectsList(col, idx);
              }}
              onCancel={() => setIsShowSortTableModal(false)}
            />
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default MobileHeader;

import { ReactComponent as NoProjectsIcon } from "assets/svg/NoProjectsIcon.svg";

const NoProjectTableRow = () => {
  return (
    <tr className="border-t border-[#E0E0E0]">
      <td colSpan={8}>
        <div className="relative py-24">
          <div>
            <NoProjectsIcon className="mx-auto" />
            <p className="mt-6 text-center text-sm font-semibold text-dark-900/[0.38]">
              No project here yet
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NoProjectTableRow;

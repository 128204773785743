import { format, parseISO } from "date-fns";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

import { Project } from "models";
import { useStore } from "stores";

import { getStatusBg } from "utils";

import { projectClassificationOptions } from "constants/project";

import Button from "components/Button";
import { useToast } from "components/toast/context";

import { ReactComponent as CustomLinkIcon } from "assets/svg/circle-custom-link.svg";
import { ReactComponent as FigmaIcon } from "assets/svg/circle-figma.svg";
import { ReactComponent as GitlabIcon } from "assets/svg/circle-gitlab.svg";
import { ReactComponent as GitlabBlue } from "assets/svg/gitlab-blue.svg";
import { ReactComponent as GoogleIcon } from "assets/svg/circle-google.svg";
import { ReactComponent as MattermostIcon } from "assets/svg/circle-mattermost.svg";
import { ReactComponent as RedmineIcon } from "assets/svg/circle-redmine.svg";
import { InfoHeader, LinkWrapper } from "./InputWrappers";

type Props = {
  project: Project;
  isEditable: boolean;
  onToggleEdit: (addLink?: boolean) => void;
};

const ProjectInfo = ({ project, isEditable, onToggleEdit }: Props) => {
  const { showToastVariant } = useToast();
  const { projectStore } = useStore();

  const [loading, setLoading] = useState(false);

  const createGitlabRepo = async () => {
    setLoading(true);
    try {
      const response = await projectStore.createGitlabRepo(project._id);

      if (!response.ok) {
        showToastVariant({
          variant: "error",
          title: "Error",
          subtitle: "Unable create Gitlab repository",
        });
        setLoading(false);
        return;
      }

      showToastVariant({
        variant: "success",
        title: "Success",
        subtitle: "Gitlab repository successfully created",
      });
    } catch (error) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: error as string,
      });
    }

    setLoading(false);
  };

  const renderItem = (name: string, value: string) => (
    <div className="mx-6 last:pb-12">
      <div className="flex flex-row gap-6 py-3">
        <p
          className={twMerge(
            "w-full max-w-[159px] text-base font-normal leading-6 text-dark-900/70 md:max-w-[200px]"
          )}
        >
          {name}
        </p>
        {/* // ***: Desktop version */}
        <div className="hidden text-sm font-semibold leading-5 text-dark-900 md:block md:flex-1">
          {value ? (
            <p>{value}</p>
          ) : (
            <p
              className={twMerge(
                "text-sm font-normal italic text-dark-900/70",
                isEditable && "cursor-pointer hover:underline"
              )}
              onClick={isEditable ? () => onToggleEdit() : () => {}}
            >
              {isEditable ? `Add ${name}` : "No data specified"}
            </p>
          )}
        </div>

        {/* // ***: Mobile version */}
        <div className="max-w-[159px] break-words text-sm font-semibold leading-5 text-dark-900 md:hidden">
          {value ? (
            <p>{value}</p>
          ) : (
            <p
              className={twMerge(
                "text-sm font-normal italic text-dark-900/70",
                isEditable && "cursor-pointer hover:underline"
              )}
              onClick={isEditable ? () => onToggleEdit() : () => {}}
            >
              {isEditable ? `Add ${name}` : "No data specified"}
            </p>
          )}
        </div>
      </div>
    </div>
  );

  const formatDateDisplayed = (dateCreated: string) =>
    format(parseISO(dateCreated), "MMMM dd, yyyy");

  const formatIndustriesDisplayed = (industriesList: any) => {
    if (!industriesList || !industriesList.length) return "";
    const industryNamesList = industriesList.map(
      (industry: { name: string }) => industry.name
    );
    return industryNamesList.join(", ");
  };

  const LoadingIndicator = () => (
    <div
      className="flex h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    />
  );

  const classification = projectClassificationOptions.find(
    (item) => item.key === project.classification
  );
  const { streetAddress, city, state, country, zip } = project?.client || {};
  const clientAddress = [streetAddress, city, state, country, zip]
    .filter((e) => e)
    .join(", ");

  return (
    <div className="pb-12">
      <InfoHeader text={"PROJECT INFO"} />

      <div className="items-center gap-6">
        {renderItem("Project Name", project.name)}
        {renderItem("Classification", classification?.label || "")}
        <div className="mx-6">
          <div className="flex flex-row gap-6 py-6 md:mr-16">
            <p className="w-full max-w-[159px] text-base font-normal leading-6 text-dark-900/70 md:mr-0 md:max-w-[200px] ">
              Project Status
            </p>
            <div className="flex-1 text-sm font-semibold leading-5 text-dark-900">
              <span
                className={twMerge(
                  "block w-fit rounded-full px-3.5 py-1 font-normal",
                  getStatusBg(project.status)
                )}
              >
                {project.status}
              </span>
            </div>
          </div>
        </div>
        {renderItem(
          "Date Created",
          project.createdAt ? formatDateDisplayed(project.createdAt) : ""
        )}
      </div>

      <InfoHeader text={"CLIENT"} />

      <div className="items-center gap-6">
        {renderItem(
          "Client Name",
          project.client?.name ? project.client.name : ""
        )}
        {renderItem(
          "Client Email",
          project.client?.email ? project.client.email : ""
        )}
        {renderItem(
          "Client Company Name",
          project.client?.companyName ? project.client.companyName : ""
        )}
        {renderItem("Client Address", clientAddress ?? "")}
      </div>

      <InfoHeader text={"INDUSTRY"} />

      <div className="items-center gap-6">
        {renderItem("Industry", formatIndustriesDisplayed(project.industries))}
      </div>

      <InfoHeader text="TECH STACK" />

      <div className="items-center gap-6">
        <div className="mx-6 last:pb-12">
          <div className="flex flex-col gap-6 py-3 md:mr-16 md:flex-row">
            <p className="hidden w-full max-w-[159px] text-base font-normal leading-6 text-dark-900/70 md:block md:max-w-[200px]">
              Tech Stack
            </p>
            <div className="flex flex-1 flex-wrap justify-start gap-2 empty:hidden">
              {project.techStacks.map((option: any) => (
                <div
                  className="relative flex h-8 items-center rounded-2xl border border-[#0C59AC80] bg-[#F9FBFE] px-3.5 py-1 text-xs font-normal"
                  key={option._id}
                >
                  <span>{option.name}</span>
                </div>
              ))}
            </div>
          </div>
          {isEditable && (
            <div>
              <button
                className="flex gap-2 text-blue hover:text-blue-hover"
                onClick={() => onToggleEdit()}
                type="button"
              >
                <span className="material-icons">add_circle_outline</span>
                <span>Add</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <InfoHeader text="LINKS" />

      <div className="px-6 font-semibold text-dark-900">
        <div className="flex flex-col justify-between md:flex-row">
          <LinkWrapper label="Gitlab" icon={GitlabIcon}>
            {project.gitlab ? (
              <a
                href={project.gitlab}
                target="_blank"
                className="block hover:underline md:w-[unset]"
                rel="noreferrer"
              >
                {project.gitlab}
              </a>
            ) : (
              <p
                className={twMerge(
                  "text-sm font-normal italic text-dark-900/70",
                  isEditable && "cursor-pointer hover:underline"
                )}
                onClick={isEditable ? () => onToggleEdit() : () => {}}
              >
                {isEditable ? "Add Repository" : "No data specified"}
              </p>
            )}
          </LinkWrapper>

          {!project.gitlab && (
            <Button
              disabled={loading}
              category="primaryOutline"
              className="my-auto h-fit cursor-pointer items-center py-2 text-primary-900"
              onClick={() => createGitlabRepo()}
            >
              {loading ? "Generating..." : "Generate Repository"}
              {loading ? <LoadingIndicator /> : <GitlabBlue />}
            </Button>
          )}
        </div>
        <LinkWrapper label="Redmine" icon={RedmineIcon}>
          {project.redmine ? (
            <a
              href={project.redmine}
              target="_blank"
              className="block hover:underline md:w-[unset]"
              rel="noreferrer"
            >
              {project.redmine}
            </a>
          ) : (
            <p
              className={twMerge(
                "text-sm font-normal italic text-dark-900/70",
                isEditable && "cursor-pointer hover:underline"
              )}
              onClick={isEditable ? () => onToggleEdit() : () => {}}
            >
              {isEditable ? "Add Redmine" : "No data specified"}
            </p>
          )}
        </LinkWrapper>
        <LinkWrapper label="Google Drive" icon={GoogleIcon}>
          {project.googleDrive ? (
            <a
              href={project.googleDrive}
              target="_blank"
              className="block hover:underline md:w-[unset]"
              rel="noreferrer"
            >
              {project.googleDrive}
            </a>
          ) : (
            <p
              className={twMerge(
                "text-sm font-normal italic text-dark-900/70",
                isEditable && "cursor-pointer hover:underline"
              )}
              onClick={isEditable ? () => onToggleEdit() : () => {}}
            >
              {isEditable ? "Add Google Drive" : "No data specified"}
            </p>
          )}
        </LinkWrapper>
        <LinkWrapper label="Figma" icon={FigmaIcon}>
          {project.figma ? (
            <a
              href={project.figma}
              target="_blank"
              className="block hover:underline md:w-[unset]"
              rel="noreferrer"
            >
              {project.figma}
            </a>
          ) : (
            <p
              className={twMerge(
                "text-sm font-normal italic text-dark-900/70",
                isEditable && "cursor-pointer hover:underline"
              )}
              onClick={isEditable ? () => onToggleEdit() : () => {}}
            >
              {isEditable ? "Add Figma" : "No data specified"}
            </p>
          )}
        </LinkWrapper>
        <LinkWrapper label="Mattermost Channel" icon={MattermostIcon}>
          {project.mattermostChannelUrl ? (
            <a
              href={project.mattermostChannelUrl}
              target="_blank"
              className="block hover:underline md:w-[unset]"
              rel="noreferrer"
            >
              {project.mattermostChannelUrl}
            </a>
          ) : (
            <p
              className={twMerge(
                "text-sm font-normal italic text-dark-900/70",
                isEditable && "cursor-pointer hover:underline"
              )}
              onClick={isEditable ? () => onToggleEdit() : () => {}}
            >
              {isEditable ? "Add Mattermost Channel" : "No data specified"}
            </p>
          )}
        </LinkWrapper>
        <div className="divide-y border-y">
          {project.customLinks.map((customLink, idx) => (
            <div
              key={`cl-${idx}`}
              className="flex flex-row items-center gap-6 py-4"
            >
              <CustomLinkIcon />
              <div className="col-span-5 break-all pr-2">
                <p className="text-sm font-normal leading-5 text-dark-900/70">
                  {customLink.name}
                </p>
                <a
                  href={customLink.url}
                  target="_blank"
                  className="hover:underline"
                  rel="noreferrer"
                >
                  {customLink.url}
                </a>
              </div>
            </div>
          ))}
          {isEditable && (
            <div className="pb-7 pt-10">
              <button
                className="flex gap-2 text-blue hover:text-blue-hover"
                onClick={() => onToggleEdit(true)}
                type="button"
              >
                <span className="material-icons">add_circle_outline</span>
                <span>Add</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;

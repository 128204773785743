import { prepareForSlot } from "@mui/base";
import { twMerge } from "tailwind-merge";

type BackdropProps = React.HTMLAttributes<HTMLDivElement>;

const Backdrop: React.FC<BackdropProps> = prepareForSlot<
  React.FC<BackdropProps>
>((props) => {
  const className = twMerge(
    "fixed inset-0 z-[-1] bg-slate-lighter",
    props.className
  );
  return <div {...props} className={className}></div>;
});

export default Backdrop;

import { MemberRoles, shortenedRoleMap } from "constants/project";
import { twMerge } from "tailwind-merge";

const RoleBadge = ({
  role,
  isMultipleValues = false,
}: {
  role: string;
  isMultipleValues?: boolean;
}) => {
  let backgroundColor = "";
  let textColor = "";

  const { Designer, ProductManager, Engineer } = MemberRoles;

  const roleType = role.replace("Lead ", "");

  switch (roleType) {
    case Designer:
      backgroundColor = "bg-[#D3E155]";
      textColor = "text-[#2C4258]";
      break;
    case ProductManager:
      backgroundColor = "bg-[#C9F1FB]";
      textColor = "text-[#2C4258]";
      break;
    case Engineer:
      backgroundColor = "bg-[#3C7ABC]";
      textColor = "text-[#FFFFFF]";
      break;
  }

  const shortenedRole = shortenedRoleMap.get(role as MemberRoles);
  return (
    <span
      className={twMerge(
        "block truncate rounded-2xl px-3.5 py-1 text-center text-xs font-normal leading-5",
        backgroundColor,
        textColor,
        isMultipleValues && "my-1"
      )}
    >
      {shortenedRole}
    </span>
  );
};

export default RoleBadge;

import { useNavigate, useSearchParams } from "react-router-dom";

import { useStore } from "stores";

import LoadingScreen from "components/LoadingScreen";
import { useOnMountUnsafe } from "hooks/useOnMountUnsafe";

const GitlabOauthCallback = () => {
  const [searchParams] = useSearchParams();

  const { authStore } = useStore();
  const navigate = useNavigate();

  const effectCallback = () => {
    if (!searchParams.has("code")) {
      console.warn("[DEBUG] code is required!");
      navigate("/login");
      return;
    }
    (async () => {
      // ***: Redirect to next path if set
      const nextPath = localStorage.getItem("next") || "/";

      const code = searchParams.get("code");
      if (!code) {
        return;
      }
      await authStore.postLoginViaGitlab(code);
      navigate(nextPath);

      localStorage.removeItem("next");
    })();
  };

  // ***: Called to prevent calling useEffect twice on development
  useOnMountUnsafe(effectCallback);

  return <LoadingScreen />;
};

export default GitlabOauthCallback;

import { computed } from "mobx";
import {
  Model,
  ModelCreationData,
  model,
  modelAction,
  prop,
} from "mobx-keystone";

import { isMahusaiEmail } from "utils";

export const UserRole = {
  Employee: 0,
  Manager: 1,
  Administrator: 2,
} as const;

export const UserPosition = {
  ENG: "Engineer",
  DES: "Designer",
  PM: "Product Manager",
} as const;

type UserRoleType = (typeof UserRole)[keyof typeof UserRole];

@model("portal/User")
export default class User extends Model({
  _id: prop<string>().withSetter(),
  username: prop<string>("").withSetter(),
  nickname: prop<string>("").withSetter(),
  firstName: prop<string>("").withSetter(),
  lastName: prop<string>("").withSetter(),

  isDeactivated: prop<boolean>(false).withSetter(),

  primaryEmail: prop<string>("").withSetter(),
  mahusaiPhEmail: prop<string>("").withSetter(),
  mahusaiGlobalEmail: prop<string>("").withSetter(),

  hrPortalId: prop<string>("").withSetter(),

  role: prop<UserRoleType>().withSetter(),
  position: prop<keyof typeof UserPosition>().withSetter(),
  // isActive: prop<boolean>(true).withSetter(),
}) {
  @modelAction
  update(data: ModelCreationData<User>) {
    Object.assign(this, data);
  }

  @computed
  get fullName() {
    if (!(this.firstName && this.lastName)) {
      return "-";
    }
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get avatarUrl() {
    if (!this.hrPortalId) return "";

    const avatarUrl =
      process.env.REACT_APP_HR_PORTAL_API_URL +
      `/auth/project-generator-users` +
      `/${this.hrPortalId}` +
      `/avatar`;
    return avatarUrl;
  }

  @computed
  get isManager() {
    return this.role > UserRole.Employee;
  }

  @computed
  get mahusaiEmail() {
    return isMahusaiEmail(this.primaryEmail)
      ? this.primaryEmail
      : this.mahusaiPhEmail || this.mahusaiGlobalEmail || "";
  }
}

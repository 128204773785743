import { format, parseISO } from "date-fns";
import { FC, useEffect, useState } from "react";
import { Modal } from "@mui/base";

import { useStore } from "stores";
import { MemberRateHistory } from "types";

import Backdrop from "./Backdrop";

import { useToast } from "components/toast/context";
import { twMerge } from "tailwind-merge";
import Fade from "components/transitions/Fade";
import Avatar from "components/Avatar";
import { User } from "models";

type RateHistoryModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | undefined;
};

type TableRow = {
  rate: string;
  projectName: string;
  dateEffective: string;
};

const tableHeaders = ["Rate", "Project", "Date Effective"];

const RateHistoryModal: FC<RateHistoryModalProps> = ({
  open,
  setOpen,
  user,
}) => {
  const { _id: userId, fullName, mahusaiEmail } = user || {};

  const { showToastVariant } = useToast();
  const { projectStore } = useStore();

  const [rateHistoryList, setRateHistoryList] = useState<MemberRateHistory[]>(
    []
  );

  const close = () => setOpen(false);

  const getMemberRateHistory = async () => {
    if (!userId) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: `No member provided to fetch rate history`,
      });
      return;
    }

    const response = await projectStore.getMemberRateHistory(userId);

    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: `Unable to fetch member's rate history`,
      });
      return;
    }

    if (response.results) setRateHistoryList(response.results);
  };

  const formatDateDisplayed = (dateJoined: string) =>
    format(parseISO(dateJoined), "MMMM dd, yyyy");

  useEffect(() => {
    (async () => {
      if (open && user?._id) await getMemberRateHistory();
    })();
    // eslint-disable-next-line
  }, [open, user]);

  const rows: TableRow[] = rateHistoryList.map((item) => {
    const rate = `$${item.amount || "0"}`;
    const projectName = item.projectName || "-";
    const dateEffective = item.createdAt
      ? formatDateDisplayed(item.createdAt)
      : "-";
    return {
      rate,
      projectName,
      dateEffective,
    };
  });

  return (
    <Fade open={open}>
      <Modal
        className="fixed inset-0 z-[500] flex items-center justify-center"
        disableAutoFocus
        disablePortal
        onClose={close}
        open
        slots={{
          backdrop: Backdrop,
        }}
      >
        <div className="fixed bottom-0 w-screen rounded-2xl bg-white shadow-dialog !outline-none md:static md:w-[600px]">
          <div className="box-content flex h-11 items-center border-b border-gray-300 px-4 py-2">
            <div className="text-primary flex grow flex-col gap-5 text-lg font-semibold">
              Rate History
            </div>
            <button
              className="material-icons p-3 text-[20px] text-dark-900 text-opacity-70"
              onClick={close}
            >
              close
            </button>
          </div>
          <div className="p-4">
            <div className="flex items-center gap-5">
              <Avatar user={user} width="w-[3.5rem]" fontSize="text-[25px]" />
              <div className="flex flex-col">
                <div className="text-sm">{fullName}</div>
                <div className="text-sm text-[#2C4258B2]">{mahusaiEmail}</div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-[12px]">
              <div className="flex flex-row gap-[20px] text-left">
                {tableHeaders.map((header, index) => (
                  <div
                    key={index}
                    className={twMerge(
                      "mr-[25.5px] w-[170px] py-[10px] font-bold",
                      index === 0 && "mr-0 w-[137px]"
                    )}
                  >
                    {header}
                  </div>
                ))}
              </div>
              {rows.map((row) => (
                <div className="flex flex-row gap-[20px] text-left">
                  {[row.rate, row.projectName, row.dateEffective].map(
                    (data, index) => (
                      <div
                        key={index}
                        className={twMerge(
                          "mr-[25.5px] w-[170px] py-[10px]",
                          index === 0 && "mr-0 w-[137px]"
                        )}
                      >
                        {data}
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </Fade>
  );
};

export default RateHistoryModal;

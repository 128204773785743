import Modal from "./Modal";
import Button from "components/Button";
import { useStore } from "stores";
import { useToast } from "components/toast/context";
import { Project } from "models";
import { ProjectStatus } from "constants/project";

type ArchiveOrRestoreProjectModalProps = {
  showModal: boolean;
  project: Project | undefined;
  onClose: () => void;
};

export default function ArchiveOrRestoreProjectModal({
  project,
  showModal,
  onClose,
}: ArchiveOrRestoreProjectModalProps) {
  const { projectStore } = useStore();

  const { showToastVariant } = useToast();

  const toArchive = project?.status !== ProjectStatus.Archived;

  const handleArchiveOrRestoreProject = async () => {
    if (!project) return;

    let response;

    if (toArchive) {
      response = await projectStore.archiveProject(project._id);
    } else {
      response = await projectStore.unarchiveProject(project._id);
    }

    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: `Unable to ${toArchive ? "archive" : "restore"} the project`,
      });
      return;
    }

    onClose();
    showToastVariant({
      variant: "success",
      title: "Success",
      subtitle: `Project successfully ${toArchive ? "archived" : "restored"}`,
    });
  };

  const WarningAvatar = () => (
    <span className="material-icons flex h-10 w-10 items-center justify-center rounded-[20px] bg-red-100 text-red-700">
      warning
    </span>
  );

  return (
    <div>
      {showModal && (
        <Modal
          handleClose={() => onClose()}
          stickToBottom={true}
          title={toArchive ? "Archive project" : "Restore project"}
          bodyClassName="md:rounded-t-2xl w-[552px]"
          titleClassName="hidden"
        >
          <div className="mx-6 mb-4 mt-6 flex gap-4">
            <div className="flex-auto">
              <WarningAvatar />
            </div>
            <div className="flex flex-col gap-2">
              <p className="color text-lg font-semibold text-dark-900">
                Are you sure you want to {toArchive ? "archive" : "restore"}{" "}
                this project?{" "}
              </p>
              <p className="color text-sm font-normal text-dark-900/70">
                Removing this project will remove all of the details that are
                attributed to it.{" "}
              </p>
            </div>
          </div>
          <div className="mx-6 my-3 flex flex-row justify-end">
            <div className="flex-1" />
            <div className="flex-2 flex gap-2.5">
              <Button
                category="primaryOutline"
                className="cursor-pointer items-center px-7 py-2 text-primary-900"
                onClick={() => onClose()}
              >
                Keep Project
              </Button>
              <Button
                category={toArchive ? "danger" : "primary"}
                className="items-center px-7 py-2 text-white"
                onClick={handleArchiveOrRestoreProject}
              >
                {toArchive ? "Discard Project" : "Restore Project"}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

import {
  format,
  getISODay,
  isSameDay,
  isWithinInterval,
  max,
  min,
  addDays,
  addMonths,
  addQuarters,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";
import { useOverflowContainer } from "components/OverflowContainer";
import { WithError } from "types/form";

import { naiveDate } from "./dates";
import { generateCalendar } from "./generateCalendar";

type SelectProps = WithError<React.SelectHTMLAttributes<HTMLSelectElement>>;
const Select = ({ className, children, error, ...props }: SelectProps) => {
  return (
    <div className="grid gap-[3px]" tabIndex={-1}>
      <select
        style={{
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          backgroundRepeat: "no-repeat no-repeat",
          backgroundPosition: "top 50% right 0.5rem",
        }}
        className={twMerge(
          `overflow-auto rounded-lg border border-dark-100 ${
            error ? "border-danger-400" : ""
          }`,
          className
        )}
        {...props}
      >
        {children}
      </select>
      {error && (
        <p className="text-xs leading-5 text-danger-400">{error.message}</p>
      )}
    </div>
  );
};

type CustomInputProps = {
  value: string | undefined;
  placeholder: string | undefined;
  onClick: () => void;
  className?: string;
};

export const CustomInput = forwardRef(
  ({ value, placeholder, onClick, className }: CustomInputProps) => (
    <div
      className={twMerge(
        "flex w-full justify-between rounded-lg border p-2",
        className
      )}
      onClick={onClick}
    >
      <span
        className={`text-left font-normal text-dark-900 ${
          !value && "opacity-[0.35]"
        }`}
      >
        {value || placeholder}
      </span>
      <span className="material-icons-outlined text-dark-900/70">
        calendar_month
      </span>
    </div>
  )
);

type DateRange = {
  start: Date;
  end: Date;
  isFinal: boolean;
};

type DateRangePickerProps = {
  initialRange?: Omit<DateRange, "isFinal">;
  onSubmit: (start: Date, end: Date, type: string) => void;
  onCancel: () => void;
  isRange?: boolean;
  cancelOrSubmitToClose?: boolean;
};

type Screen = "YearSelect" | "MonthSelect" | "DateRange";

export default function DateRangePicker({
  initialRange,
  onSubmit,
  onCancel,
  isRange = true,
  cancelOrSubmitToClose = false,
}: DateRangePickerProps) {
  const getFilterByLabel = (label: string) => {
    const selectedFilter = dateRanges.find((obj) => obj.label === label);

    return selectedFilter;
  };

  const onDropdownChange = useCallback((value: string) => {
    const selectedFilter = getFilterByLabel(value);

    if (!selectedFilter || !selectedFilter.range) {
      return;
    }

    setCurrRange(selectedFilter.range);
    setDateType(selectedFilter.type);
    setFilter({
      month: selectedFilter.range.start.getMonth(),
      year: selectedFilter.range.start.getFullYear(),
    });
  }, []);

  const [currRange, setCurrRange] = useState<DateRange | undefined>(
    initialRange ? { ...initialRange, isFinal: true } : undefined
  );
  const [dateType, setDateType] = useState<string>("");
  const { signalClose } = useOverflowContainer();

  const [screen, setScreen] = useState<Screen>("DateRange");
  const [filter, setFilter] = useState<{ month: number; year: number }>({
    month: initialRange
      ? initialRange.start.getMonth()
      : naiveDate().getMonth(),
    year: initialRange
      ? initialRange.start.getFullYear()
      : naiveDate().getFullYear(),
  });
  const calendar = useMemo(
    () => generateCalendar(filter.month, filter.year),
    [filter.year, filter.month]
  );

  const today = startOfToday();

  const currentWeek: DateRange = useMemo(() => {
    return {
      start: startOfWeek(today, { weekStartsOn: 1 }),
      end: endOfWeek(today, { weekStartsOn: 1 }),
      isFinal: true,
    };
  }, [today]);

  const prevWeek: DateRange = useMemo(() => {
    return {
      start: startOfWeek(subDays(today, 7), { weekStartsOn: 1 }),
      end: endOfWeek(subDays(today, 7), { weekStartsOn: 1 }),
      isFinal: true,
    };
  }, [today]);

  const nextWeek: DateRange = useMemo(() => {
    return {
      start: startOfWeek(addDays(today, 7), { weekStartsOn: 1 }),
      end: endOfWeek(addDays(today, 7), { weekStartsOn: 1 }),
      isFinal: true,
    };
  }, [today]);

  const currentMonth: DateRange = useMemo(() => {
    return {
      start: startOfMonth(today),
      end: endOfMonth(today),
      isFinal: true,
    };
  }, [today]);

  const prevMonth: DateRange = useMemo(() => {
    return {
      start: startOfMonth(subMonths(today, 1)),
      end: endOfMonth(subMonths(today, 1)),
      isFinal: true,
    };
  }, [today]);

  const nextMonth: DateRange = useMemo(() => {
    return {
      start: startOfMonth(addMonths(today, 1)),
      end: endOfMonth(addMonths(today, 1)),
      isFinal: true,
    };
  }, [today]);

  const currentQuarter: DateRange = useMemo(() => {
    return {
      start: startOfQuarter(today),
      end: endOfQuarter(today),
      isFinal: true,
    };
  }, [today]);

  const prevQuarter: DateRange = useMemo(() => {
    return {
      start: startOfQuarter(subQuarters(today, 1)),
      end: endOfQuarter(subQuarters(today, 1)),
      isFinal: true,
    };
  }, [today]);

  const nextQuarter: DateRange = useMemo(() => {
    return {
      start: startOfQuarter(addQuarters(today, 1)),
      end: endOfQuarter(addQuarters(today, 1)),
      isFinal: true,
    };
  }, [today]);

  const currentYear: DateRange = useMemo(() => {
    return {
      start: startOfYear(today),
      end: endOfYear(today),
      isFinal: true,
    };
  }, [today]);

  const prevYear: DateRange = useMemo(() => {
    return {
      start: startOfYear(subYears(today, 1)),
      end: endOfYear(subYears(today, 1)),
      isFinal: true,
    };
  }, [today]);

  const nextYear: DateRange = useMemo(() => {
    return {
      start: startOfYear(addYears(today, 1)),
      end: endOfYear(addYears(today, 1)),
      isFinal: true,
    };
  }, [today]);

  const entireYear: DateRange = useMemo(() => {
    const yearDate = new Date(`${filter.year}-01-01`);

    return {
      start: startOfYear(yearDate),
      end: endOfYear(yearDate),
      isFinal: true,
    };
  }, [filter]);

  const entireMonth: DateRange = useMemo(() => {
    const monthDate = new Date(`${filter.year}-${filter.month + 1}-01`);

    return {
      start: startOfMonth(monthDate),
      end: endOfMonth(monthDate),
      isFinal: true,
    };
  }, [filter]);

  const entireQuarter: DateRange = useMemo(() => {
    const quarterDate = new Date(`${filter.year}-${filter.month + 1}-01`);

    return {
      start: startOfQuarter(quarterDate),
      end: endOfQuarter(quarterDate),
      isFinal: true,
    };
  }, [filter]);

  const dateRanges = [
    { label: "This Week", range: currentWeek, type: "Week" },
    { label: "Previous Week", range: prevWeek, type: "Week" },
    { label: "Next Week", range: nextWeek, type: "Week" },
    { label: "This Month", range: currentMonth, type: "Month" },
    { label: "Previous Month", range: prevMonth, type: "Month" },
    { label: "Next Month", range: nextMonth, type: "Month" },
    { label: "This Quarter", range: currentQuarter, type: "Quarter" },
    { label: "Previous Quarter", range: prevQuarter, type: "Quarter" },
    { label: "Next Quarter", range: nextQuarter, type: "Quarter" },
    { label: "This Year", range: currentYear, type: "Year" },
    { label: "Previous Year", range: prevYear, type: "Year" },
    { label: "Next Year", range: nextYear, type: "Year" },
    { label: "Entire Month", range: entireMonth, type: "Month" },
    { label: "Entire Quarter", range: entireQuarter, type: "Quarter" },
    { label: "Entire Year", range: entireYear, type: "Year" },
  ];

  const handleClick = useCallback((date: Date) => {
    setCurrRange((prev) => {
      if (!isRange) {
        return { start: date, end: date, isFinal: true };
      }
      if (!!prev && !prev.isFinal) {
        return { ...prev, isFinal: !prev.isFinal };
      }
      return { start: date, end: date, isFinal: false };
    });
    setDateType("Custom");
  }, []);

  const handleMonthChange = useCallback((offset: -1 | 1) => {
    setFilter((prev) => {
      const month = (((prev.month + offset) % 12) + 12) % 12;
      const year = prev.year + (month !== prev.month + offset ? offset : 0);

      return { month, year };
    });
  }, []);

  const handleHover = useCallback((date: Date) => {
    setCurrRange((prev) =>
      !!prev && !prev.isFinal
        ? {
            ...prev,
            end: date,
          }
        : prev
    );
    setDateType("Custom");
  }, []);

  const handleSubmit = useCallback(() => {
    if (!currRange) {
      return;
    }
    onSubmit(
      min([currRange.start, currRange.end]),
      max([currRange.start, currRange.end]),
      dateType
    );
    if (!isRange || cancelOrSubmitToClose) {
      signalClose();
    }
  }, [currRange, onSubmit, dateType]);

  return (
    <div className="flex flex-col rounded-2xl text-sm">
      <div
        className={twMerge(
          "grid place-items-center py-3",
          screen !== "DateRange" ? "border-b" : ""
        )}
      >
        <div className="flex w-full flex-1 items-center justify-center px-4">
          {/** Month Select */}
          <button
            type="button"
            className={twMerge(
              "material-icons flex aspect-square h-10 w-10 items-center justify-center bg-basic-primary px-4 text-primary-900",
              screen !== "DateRange" ? "opacity-0" : "opacity-100"
            )}
            onClick={() => handleMonthChange(-1)}
          >
            chevron_left
          </button>
          <div className="flex flex-1 justify-center gap-2">
            <div className="flex-1" />
            <button
              type="button"
              className="flex flex-1 flex-row items-center justify-center gap-2 px-2"
              onClick={() =>
                setScreen((prev) =>
                  prev === "MonthSelect" ? "DateRange" : "MonthSelect"
                )
              }
            >
              <span
                className={twMerge(
                  "font-semibold text-dark-900",
                  screen === "YearSelect" ? "opacity-70" : "opacity-100"
                )}
              >
                {format(new Date(filter.year, filter.month), "MMMM")}
              </span>
              <div className="material-icons aspect-square w-[1.125rem] text-xl leading-none text-dark-900/70">
                expand_more
              </div>
            </button>
            {/** Year select */}
            <button
              type="button"
              className="flex flex-1 flex-row items-center justify-center gap-2 px-2"
              onClick={() =>
                setScreen((prev) =>
                  prev === "YearSelect" ? "DateRange" : "YearSelect"
                )
              }
            >
              <span
                className={twMerge(
                  "font-semibold text-dark-900",
                  screen === "MonthSelect" ? "opacity-70" : "opacity-100"
                )}
              >
                {filter.year}
              </span>
              <div className="material-icons aspect-square w-[1.125rem] text-xl leading-none text-dark-900/70">
                expand_more
              </div>
            </button>
            <div className="flex-1" />
          </div>
          <button
            type="button"
            className={twMerge(
              "material-icons flex aspect-square w-10 items-center justify-center bg-basic-primary px-4 text-primary-900",
              screen !== "DateRange" ? "opacity-0" : "opacity-100"
            )}
            onClick={() => handleMonthChange(1)}
          >
            chevron_right
          </button>
        </div>
      </div>
      {screen === "MonthSelect" && (
        <SelectMonth
          currentMonth={filter.month}
          onSelectMonth={(month) => {
            setScreen("DateRange");
            setFilter((prev) => ({ ...prev, month }));
          }}
        />
      )}
      {screen === "YearSelect" && (
        <SelectYear
          currentYear={filter.year}
          onSelectYear={(year) => {
            setScreen("DateRange");
            setFilter((prev) => ({ ...prev, year }));
          }}
        />
      )}
      <hr className="block" />
      {screen === "DateRange" && (
        <div className="p-4">
          <div className="grid grid-cols-7 px-4">
            <span className="grid aspect-square place-items-center">Su</span>
            <span className="grid aspect-square place-items-center">Mo</span>
            <span className="grid aspect-square place-items-center">Tu</span>
            <span className="grid aspect-square place-items-center">We</span>
            <span className="grid aspect-square place-items-center">Th</span>
            <span className="grid aspect-square place-items-center">Fr</span>
            <span className="grid aspect-square place-items-center">Sa</span>
            {calendar.map(({ date }) => (
              <CalendarCell
                key={date.toISOString()}
                date={date}
                range={currRange}
                filter={filter}
                onClick={() => handleClick(date)}
                onMouseOver={() => handleHover(date)}
              />
            ))}
          </div>
        </div>
      )}
      <hr className="block" />
      <div className="hidden flex-col gap-2 py-2 pb-5 pt-3 md:flex">
        <div className="p-3 text-center font-semibold text-dark-900">
          Quick Filters
        </div>
        <div className="hidden grid-cols-3 items-start py-1 pl-0 pr-1 lg:grid">
          {dateRanges.map(({ label, range, type }) => (
            <button
              key={label}
              type="button"
              className="row-span-1 py-1 pl-2 pr-2 text-start text-[12px] font-semibold text-primary-900"
              onClick={() => {
                setCurrRange(range);
                setDateType(type);
                setFilter({
                  month: range.start.getMonth(),
                  year: range.start.getFullYear(),
                });
              }}
            >
              {label}
            </button>
          ))}
        </div>
        <div className="flex w-full flex-1 justify-center px-4 py-2 lg:hidden">
          <Select
            className="w-full"
            onChange={(e: any) => onDropdownChange(e.currentTarget.value)}
            placeholder="Apply a Quick Filter"
          >
            {dateRanges.map(({ label, range }) => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <hr className="block" />
      <div className="flex justify-end gap-5 px-4 py-3">
        <button
          type="button"
          className="text-[13px] font-semibold text-dark-900"
          onClick={cancelOrSubmitToClose ? () => signalClose() : onCancel}
        >
          Cancel
        </button>
        <Button
          type="button"
          category="primaryOutline"
          className="text-[13px] font-semibold text-primary-900"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

const START_YEAR = 1970;

type SelectMonthProps = {
  currentMonth: number;
  onSelectMonth: (month: number) => void;
};

const SelectMonth = ({ onSelectMonth, currentMonth }: SelectMonthProps) => {
  return (
    <div className="flex max-h-80 flex-col overflow-y-auto">
      {new Array(12)
        .fill(0)
        .map((v, idx) => idx)
        .map((v) => (
          <button
            key={v}
            type="button"
            onClick={() => onSelectMonth(v)}
            className={twMerge(
              "flex items-center gap-4 p-4 text-left hover:bg-sky-50",
              v === currentMonth
                ? "rounded-lg bg-secondary-900/[0.08] font-semibold text-primary-900"
                : ""
            )}
          >
            <div className="h-6 w-6">
              {v === currentMonth && (
                <span className="material-icons">check</span>
              )}
            </div>
            {format(new Date(START_YEAR, v), "MMMM")}
          </button>
        ))}
    </div>
  );
};

type SelectYearProps = {
  currentYear: number;
  onSelectYear: (year: number) => void;
};

const SelectYear = ({ onSelectYear, currentYear }: SelectYearProps) => {
  const indexOfCurrYear = useMemo(
    () => new Date().getFullYear() - START_YEAR,
    []
  );
  const [maxYearDist, setMaxYearDist] = useState(indexOfCurrYear * 2);

  return (
    <div className="overflow-hidden">
      <InfiniteLoader
        isItemLoaded={(index) => maxYearDist > index + 1}
        loadMoreItems={() => setMaxYearDist((prev) => prev + 10)}
        itemCount={maxYearDist}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            onItemsRendered={onItemsRendered}
            ref={ref}
            width="100%"
            itemSize={50}
            initialScrollOffset={indexOfCurrYear * 50}
            itemCount={maxYearDist}
            height={320}
          >
            {({ index, style }) => (
              <li
                style={style}
                className={twMerge(
                  "flex list-none items-center hover:bg-sky-50",
                  index === currentYear - START_YEAR
                    ? "bg-secondary-900/[0.08] font-semibold text-primary-900"
                    : ""
                )}
              >
                <button
                  type="button"
                  className="flex flex-1 items-center gap-4 p-4 text-left"
                  onClick={() => onSelectYear(START_YEAR + index)}
                >
                  <div className="h-6 w-6">
                    {index === currentYear - START_YEAR && (
                      <span className="material-icons">check</span>
                    )}
                  </div>
                  {START_YEAR + index}
                </button>
              </li>
            )}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  );
};

type CalendarCellProps = {
  date: Date;
  range?: DateRange;
  filter: {
    month: number;
    year: number;
  };
  onClick: () => void;
  onMouseOver: () => void;
};

const CalendarCell = ({
  date,
  range,
  filter: { month, year },
  onClick,
  onMouseOver,
}: CalendarCellProps) => {
  const properRange = useMemo(() => {
    if (range === undefined) {
      return undefined;
    }

    const start = min([range.start, range.end]);
    const end = max([range.start, range.end]);

    return { ...range, start, end };
  }, [range]);

  const endpointCell = useMemo(() => {
    if (!properRange) {
      return undefined;
    }
    return (
      (isSameDay(date, properRange.start) !==
        isSameDay(date, properRange.end) ||
        (isSameDay(properRange.start, properRange.end) &&
          isSameDay(properRange.start, date))) && (
        <div className="absolute left-1/2 top-1/2 z-[2] grid aspect-square w-full -translate-x-1/2 -translate-y-1/2 place-items-center rounded-full bg-primary-900 text-primary-50">
          {date.getDate()}
        </div>
      )
    );
  }, [date, properRange]);

  const isoDay = useMemo(() => getISODay(date), [date]);

  const isInRange = useMemo(
    () => !!properRange && isWithinInterval(date, properRange),
    [date, properRange]
  );

  const isInMonth = useMemo(() => date.getMonth() === month, [date, month]);

  return (
    <button
      type="button"
      className={twMerge(
        "relative border-transparent",
        !isInRange
          ? "hover:rounded-full hover:border hover:border-primary-900"
          : ""
      )}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <span
        className={twMerge(
          "grid aspect-square place-items-center rounded-full",
          determineShading(date, properRange),
          isInRange &&
            isoDay === 7 &&
            !!properRange &&
            !isSameDay(properRange.start, date)
            ? "-ml-3 -mr-0 aspect-auto h-full pl-3"
            : "",
          isInRange &&
            isoDay === 6 &&
            !!properRange &&
            !isSameDay(properRange.end, date)
            ? "-ml-0 -mr-3 aspect-auto h-full pr-3"
            : "",
          !!properRange && isSameDay(date, properRange.start)
            ? "rounded-l-full"
            : "",
          !!properRange && isSameDay(date, properRange.end)
            ? "rounded-r-full"
            : "",
          isInMonth ? "" : "text-dark-900/70"
        )}
      >
        {date.getDate()}
      </span>
      {endpointCell}
    </button>
  );
};

function determineShading(date: Date, range?: DateRange) {
  if (range === undefined || !isWithinInterval(date, range)) {
    return "";
  }
  return "bg-[#D9E3F8] text-primary-900 rounded-none";
}

import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useStore } from "stores";

type Props = {
  children: React.ReactNode;
};

const ProtectedRoute = ({ children }: Props) => {
  const { authStore, userStore } = useStore();
  const location = useLocation();

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      (async () => {
        await authStore.checkAuthentication();

        // Ensure that both userStore and authStore have at least
        // the current user from the start
        if (
          authStore.currentUser &&
          !userStore.users.has(authStore.currentUser?._id)
        ) {
          await userStore.fetchOneUser(authStore.currentUser._id);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore]);

  if (!authStore.isAuthenticated) {
    const redirectPath =
      `/login?next=${location.pathname}${location.search}` || "/login";
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

export default observer(ProtectedRoute);

import React, { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export type ModalProps = {
  handleClose: () => void;
  disabled?: boolean;
  title: string;
  titleComponent?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  bodyClassName?: string;
  stickToBottom?: boolean;
  children?: React.ReactNode;
};

export default function Modal({
  title,
  titleComponent,
  titleClassName,
  className,
  bodyClassName,
  stickToBottom = false,
  children,
  handleClose,
  disabled = false,
}: ModalProps) {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [isCloseClicked, setIsCloseClicked] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
      clearTimeout(timer);
    };
  }, [timer]);

  const handleClickClose = useCallback(() => {
    if (disabled) return;

    setIsCloseClicked(true);
    setTimer(setTimeout(handleClose, 200));

    // eslint-disable-next-line
  }, [handleClose]);

  return (
    <div
      className={`"block fixed inset-0 z-[999] bg-[#00000040] ${
        isCloseClicked
          ? "animate-[fadeOut_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]"
          : "animate-[fadeIn_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]"
      }`}
      data-open="true"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClickClose();
        }
      }}
    >
      <ModalProvider
        value={{
          signalClose: handleClickClose,
        }}
      >
        <div
          className={twMerge(
            stickToBottom
              ? `fixed bottom-0 flex w-[100%] min-w-[350px] flex-col lg:bottom-auto lg:left-1/2 lg:top-1/2 lg:w-[600px] lg:max-w-[600px] lg:-translate-x-1/2 lg:-translate-y-1/2 ${
                  isCloseClicked
                    ? "animate-[bottomSlideDown_0.2s_forwards] lg:animate-[slideDown_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]"
                    : "animate-[bottomSlideUp_0.2s_forwards] lg:animate-[slideUp_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]"
                }`
              : `fixed left-1/2 top-1/2 flex w-[98%] min-w-[350px] max-w-[600px] -translate-x-1/2 -translate-y-1/2 flex-col lg:w-[600px] ${
                  isCloseClicked
                    ? "animate-[slideDown_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]"
                    : "animate-[slideUp_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]"
                }`,
            className
          )}
        >
          {titleComponent || (
            <div
              className={twMerge(
                "flex w-full flex-row items-center justify-between bg-white p-4 lg:h-full",
                titleClassName
              )}
            >
              <div className="flex items-center justify-between gap-2">
                <p className="text-lg font-semibold">{title}</p>
              </div>
              <button
                className="material-icons ml-2 rounded-full px-1 py-1 hover:bg-slate-400/10"
                type="button"
                onClick={handleClickClose}
              >
                close
              </button>
            </div>
          )}
          <div
            className={twMerge(
              `flex flex-1 flex-col ${
                stickToBottom
                  ? "lg:max-h-[80vh] lg:rounded-b-2xl"
                  : "max-h-[80vh] rounded-b-2xl"
              } bg-white`,
              bodyClassName
            )}
          >
            {children}
          </div>
        </div>
      </ModalProvider>
    </div>
  );
}

type ModalContextData = {
  signalClose: () => void;
};

export const ModalContext = React.createContext<ModalContextData>(
  {} as ModalContextData
);
export const useModal = () => React.useContext(ModalContext);
export const { Provider: ModalProvider } = ModalContext;

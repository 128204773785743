import { format, parseISO } from "date-fns";

export * from "./project";

export const timeout = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const isMahusaiEmail = (email: string | undefined) => {
  if (!email) return false;

  return (
    email.endsWith("@mahusai.ph") ||
    email.endsWith("@mahusai.global") ||
    email.endsWith("@mahusai.co")
  );
};

export const formatDateDisplayed = (dateCreated: string) =>
  format(parseISO(dateCreated), "MMMM dd, yyyy");

export const formatTimeDisplayed = (dateCreated: string) =>
  format(parseISO(dateCreated), "hh:mm a");

export const limitEmailDisplayed = (
  email: string | undefined,
  limit: number = 22
) => {
  if (!email) return "";
  return email.length > limit ? `${email.slice(0, limit + 1)}....` : email;
};

export const formatToUSD = (amount: number) => {
  const USDFormat = new Intl.NumberFormat("en-us", {
    currency: "USD",
    style: "currency",
  });
  return USDFormat.format(amount);
};

export const capitalizeFirstLetter = (sentence?: string) => {
  if (!sentence) return "";

  return sentence
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

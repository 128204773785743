import { MultiselectOption } from "components/inputs/Multiselect";
import { capitalizeFirstLetter } from "utils";

export enum InvoiceSplitOption {
  Service = "SERVICE",
  Role = "ROLE",
}
export const invoiceSplitOptions: MultiselectOption[] = Object.values(
  InvoiceSplitOption
).map((e) => ({ key: e, label: capitalizeFirstLetter(e) }));

export enum InvoiceOptionalColumn {
  Amount = "AMOUNT",
  Rate = "RATE",
  Hours = "HOURS",
}
export const visibleColumnOptions: MultiselectOption[] = Object.values(
  InvoiceOptionalColumn
).map((e) => ({ key: e, label: capitalizeFirstLetter(e) }));

import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "stores";

import { TableColHeader } from "types";
import { Project } from "models";

import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { projectsTableColumnHeaders } from "components/dashboard/common";

import ProjectsFilters from "./ProjectsFilter";
import NoProjectTableRow from "./NoProjectTableRow";
import ProjectTableRow from "./ProjectTableRow";
import ProjectsTableFooter from "./TableFooter";

type ProjectsTableProps = {
  selectedProject?: Project;
  projectHasBeenAdded: boolean;
  setProjectHasBeenAdded: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
  isShowArchiveOrRestoreModal: boolean;
  setIsShowArchiveOrRestoreModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectsTable = ({
  selectedProject,
  setSelectedProject,
  projectHasBeenAdded,
  setProjectHasBeenAdded,
  isShowArchiveOrRestoreModal,
  setIsShowArchiveOrRestoreModal,
}: ProjectsTableProps) => {
  const { projectStore } = useStore();
  const { searchFilters: filters } = projectStore;
  const { rowsPerPage, isMyProject, currentPage } = filters;

  const [projectsList, setProjectsList] = useState<Project[]>([]);

  const [selectedSortFilter, setSelectedSortFilter] = useState<string>("");
  const [colsArrowDown, setColsArrowDown] = useState<boolean[]>(
    Array(projectsTableColumnHeaders.length - 1).fill(true)
  );

  const updateColsArrowDown = (idx: number) => {
    const value = projectsTableColumnHeaders.map((_, i) =>
      i !== idx ? true : !colsArrowDown[idx]
    );
    setColsArrowDown(value);
  };

  const fetchAndUpdateProjectsList = (col: TableColHeader, idx: number) => {
    updateColsArrowDown(idx);
    if (!col.fieldName) return;

    filters.setSortFieldName(col.fieldName);
    filters.setSortDirection(colsArrowDown[idx] ? 1 : -1);
    setSelectedSortFilter(col.fieldName);
    filterListedProject();
  };

  // ***: Function for infinite scrolling in mobile
  const observerTarget = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          projectsList.length < projectStore.count
        ) {
          (async () => {
            const { results: projects } = await projectStore.listProjects({
              limit: 10,
              page: currentPage + 1,
            });

            if (!projects) {
              filters.setCurrentPage(currentPage - 1);
              return;
            }

            filters.setCurrentPage(currentPage + 1);

            // ***: Remove duplicate entries when merging
            const newProjectsList = [...projectsList, ...projects].filter(
              (value, index, self) =>
                index === self.findIndex((proj) => proj.name === value.name)
            );

            setProjectsList(newProjectsList);
          })();
        }
      },
      { threshold: 0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [currentPage, observerTarget, projectStore, projectsList]);

  useEffect(() => {
    filterListedProject();
    // eslint-disable-next-line
  }, [rowsPerPage, isMyProject]);

  const filterListedProject = async () => {
    const { queryParams } = filters;
    const { results: projects } = await projectStore.listProjects(queryParams);

    setProjectsList(projects || []);
    if (projectHasBeenAdded) setProjectHasBeenAdded(false);

    return projects;
  };

  useEffect(() => {
    if (!selectedProject || isShowArchiveOrRestoreModal) return;

    const projectId = selectedProject._id;
    const projectWithUpdatedStatus = projectStore.projects.get(projectId);
    if (!projectWithUpdatedStatus) return;

    const updatedProjectsList = [...projectsList].map((project) =>
      project._id === selectedProject._id ? projectWithUpdatedStatus : project
    );

    setProjectsList(updatedProjectsList);
    // eslint-disable-next-line
  }, [isShowArchiveOrRestoreModal]);

  useEffect(() => {
    if (projectHasBeenAdded) filterListedProject();
    // eslint-disable-next-linesetIsLoading
  }, [projectHasBeenAdded]);

  return (
    <>
      <div className="hidden gap-2 rounded-lg bg-transparent shadow-card md:block md:shadow-none ">
        <ProjectsFilters
          filters={filters}
          filterListedProject={filterListedProject}
        />
      </div>
      <div className="relative overflow-x-auto break-words">
        <table className="bottom-0 m-auto w-full bg-transparent md:m-0">
          <DesktopHeader
            colsArrowDown={colsArrowDown}
            selectedSortFilter={selectedSortFilter}
            fetchAndUpdateProjectsList={fetchAndUpdateProjectsList}
          />
          <MobileHeader
            filters={filters}
            filterListedProject={filterListedProject}
            fetchAndUpdateProjectsList={fetchAndUpdateProjectsList}
          />
          <tbody>
            {!projectsList?.length ? (
              <NoProjectTableRow />
            ) : (
              projectsList.map((project: Project) => (
                <ProjectTableRow
                  key={project._id}
                  project={project}
                  setIsShowArchiveOrRestoreModal={
                    setIsShowArchiveOrRestoreModal
                  }
                  setSelectedProject={setSelectedProject}
                />
              ))
            )}

            {/* // ***: Detector for infinite scrolling for mobile view */}
            <tr
              className="table-row h-1.5 border-x md:hidden"
              ref={observerTarget}
            >
              <td className="border-r" />
              <td />
            </tr>
          </tbody>
          <ProjectsTableFooter
            projectsList={projectsList}
            allProjectsInStore={projectStore.count}
            filters={filters}
            filterListedProject={filterListedProject}
          />
        </table>
      </div>
    </>
  );
};

export default observer(ProjectsTable);

import { useEffect, useRef, useState } from "react";

import { createRootStore } from "../stores";
import RootStore from "../stores/RootStore";

export const useInit = () => {
  const [store, setStore] = useState<RootStore>();
  const [ready, setReady] = useState<boolean>(false);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      (async () => {
        const store = createRootStore();

        await store.load();
        setStore(store);
        setTimeout(function () {
          setReady(true);
        }, 1000);
      })();
    }
  }, []);

  return {
    store,
    ready,
  };
};

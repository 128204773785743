import { observer } from "mobx-react-lite";
import { twMerge } from "tailwind-merge";

export type TabTitleProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive: boolean;
};
const TabTitle = ({
  isActive,
  children,
  className,
  ...props
}: TabTitleProps) => {
  return (
    <button
      className={twMerge(
        "w-36 flex-1 border-b-[3px] py-3 text-sm font-semibold leading-6",
        isActive
          ? "border-secondary-900 text-primary-900"
          : "border-transparent text-dark-900/[.7]",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default observer(TabTitle);

import ProjectMembersTable from "components/projectDetail/ProjectMembersComponents/Table";
import { Project } from "models";

type Props = {
  project: Project;
};

const ProjectMembers = ({ project }: Props) => (
  <ProjectMembersTable project={project} />
);

export default ProjectMembers;

import { ClickAwayListener, Popper, PopperOwnProps } from "@mui/base";
import React from "react";
import { twMerge } from "tailwind-merge";

type ActionMenuProps = {
  isCurrentUserProjectMemberOrAdmin: boolean;
  disabled?: boolean;
  hrPortalId: string;
  placement?: PopperOwnProps["placement"];
  openRateHistoryModal: () => void;
  openRemoveMemberModal: () => void;
};

const ActionMenu: React.FC<ActionMenuProps> = ({
  isCurrentUserProjectMemberOrAdmin,
  disabled = false,
  hrPortalId,
  placement = "bottom",
  openRateHistoryModal,
  openRemoveMemberModal,
}) => {
  const [popperIsOpen, setPopperIsOpen] = React.useState<boolean>(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const togglePopper = () => setPopperIsOpen((prevState) => !prevState);

  const closePopper = () => setPopperIsOpen(false);

  const handleViewProfileClick = () => {
    window.alert("Not implemented!");
    closePopper();
  };

  const handleOpenRateHistoryModal = () => {
    openRateHistoryModal();
    closePopper();
  };

  const handleOpenRemoveMemberModal = () => {
    openRemoveMemberModal();
    closePopper();
  };

  return (
    <>
      <ClickAwayListener onClickAway={closePopper}>
        <div id="click-away-listener">
          <div className="h-[32px] w-[32px] overflow-hidden rounded-full">
            <button
              ref={buttonRef}
              className={twMerge(
                "material-icons h-[32px] w-[32px] text-slate/70",
                disabled && "text-slate/40"
              )}
              disabled={disabled}
              onClick={togglePopper}
            >
              more_vert
            </button>
          </div>
          <Popper
            id="actions-popper"
            open={popperIsOpen}
            anchorEl={buttonRef.current}
            modifiers={[
              { name: "flip", enabled: false },
              { name: "preventOverflow", enabled: false },
            ]}
            placement={placement}
          >
            <div className="flex flex-col rounded-xl bg-white p-2 shadow-dropdown">
              <a
                className="px-4 py-1.5 text-left leading-6"
                href={`${process.env.REACT_APP_HR_PORTAL_WEBSITE_URL}/members/${hrPortalId}`}
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => setPopperIsOpen(false)}
              >
                View profile
              </a>
              <button
                className="px-4 py-1.5 text-left leading-6"
                onClick={handleOpenRateHistoryModal}
              >
                View rate history
              </button>
              <button
                disabled={!isCurrentUserProjectMemberOrAdmin}
                className={twMerge(
                  "px-4 py-1.5 text-left leading-6 text-error-main",
                  !isCurrentUserProjectMemberOrAdmin && "opacity-[0.38]"
                )}
                onClick={handleOpenRemoveMemberModal}
              >
                Remove member
              </button>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ActionMenu;

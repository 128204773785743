import { TableProps } from "types";
import TableRowMobile from "./TableRowMobile";

const TableMobile: React.FC<TableProps> = ({
  members,
  openRateHistoryModal,
  openRemoveMemberModal,
  setMember,
}) => {
  return (
    <div className="mb-16 md:hidden">
      {members.map((member) => (
        <TableRowMobile
          key={member._id}
          member={member}
          openRateHistoryModal={openRateHistoryModal}
          openRemoveMemberModal={openRemoveMemberModal}
          setMember={setMember}
        />
      ))}
    </div>
  );
};

export default TableMobile;

import { Input } from "@mui/base";
import React from "react";

type TextInputProps = {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
};

const TextInput: React.FC<TextInputProps> = ({
  disabled,
  name,
  placeholder,
  onChange,
  value,
}) => {
  const slotProps = {
    input: { className: "w-full border-none outline-none text-slate" },
    root: {
      className:
        "flex border border-slate-lighter p-2 rounded-lg overflow-hidden",
    },
  };

  return (
    <Input
      placeholder={placeholder}
      slotProps={slotProps}
      disabled={disabled}
      type="text"
      name={name}
      onChange={onChange}
      value={value}
    />
  );
};

export default TextInput;

import React from "react";
import { twMerge } from "tailwind-merge";

type State = "hidden" | "fadingIn" | "visible" | "fadingOut";

type FadeProps = {
  open: boolean;
  children: React.ReactNode;
};

const Fade: React.FC<FadeProps> = ({ children, open }) => {
  const [state, setState] = React.useState<State>("hidden");

  React.useEffect(() => {
    if (open && state === "hidden") {
      setState("fadingIn");
      setTimeout(() => {
        setState("visible");
      }, 100);
    } else if (!open && state === "visible") {
      setState("fadingOut");
      setTimeout(() => {
        setState("hidden");
      }, 100);
    }
  }, [open]);

  return state !== "hidden" ? (
    <div
      className={twMerge(
        "z-[1000]",
        state === "fadingIn" && "animate-[fadeIn_100ms_linear_forwards]",
        state === "fadingOut" && "animate-[fadeOut_100ms_linear_forwards]"
      )}
    >
      {children}
    </div>
  ) : null;
};

export default Fade;

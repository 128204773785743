import { useState } from "react";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";

import { TableColHeader } from "types";
import { projectsTableColumnHeaders } from "../common";

type DesktopHeaderProps = {
  fetchAndUpdateProjectsList: (col: TableColHeader, idx: number) => void;
  colsArrowDown: boolean[];
  selectedSortFilter: string;
};

const DesktopHeader = ({
  fetchAndUpdateProjectsList,
  colsArrowDown,
  selectedSortFilter,
}: DesktopHeaderProps) => {
  const [hideArrow, setHideArrow] = useState<boolean[]>(
    Array(projectsTableColumnHeaders.length - 1).fill(true)
  );

  const updateHideArrowDown = (idx: number) => {
    const value = projectsTableColumnHeaders.map((_, i) =>
      i !== idx ? true : false
    );
    setHideArrow(value);
  };

  return (
    <thead className="hidden border-y-2 md:table-header-group md:bg-[#F9FBFE]">
      <tr>
        {projectsTableColumnHeaders.map((col, idx) => (
          <th
            key={`th-${col.title}`}
            className={twMerge(
              "py-4 pl-4 text-left md:table-cell",
              col.title === "Project Name" && "w-[20.5rem]",
              col.title === "Project Status" && "pl-0",
              col.title === "Client Name" && "hidden"
            )}
          >
            <Button
              category="borderless"
              className={twMerge(
                "flex w-[90%] justify-start bg-transparent px-0 text-dark-900 hover:opacity-100",
                col.title === "Actions" &&
                  "w-[6rem] text-center hover:cursor-auto"
              )}
              onClick={() => fetchAndUpdateProjectsList(col, idx)}
              onMouseEnter={() => updateHideArrowDown(idx)}
              onMouseLeave={() =>
                setHideArrow(Array(hideArrow.length).fill(true))
              }
            >
              <div className="text-base">{col.title}</div>
              {col.title !== "Actions" && (
                <span
                  className={twMerge(
                    "material-icons-outlined text-2xl",
                    hideArrow[idx] &&
                      col.fieldName !== selectedSortFilter &&
                      "opacity-0"
                  )}
                >
                  {colsArrowDown[idx] ? "arrow_drop_down" : "arrow_drop_up"}
                </span>
              )}
            </Button>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default DesktopHeader;

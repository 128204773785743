import { twMerge } from "tailwind-merge";

export const FolderIcon = () => (
  <span className="material-icons-outlined text-3xl text-primary-900">
    folder_copy
  </span>
);
export const MyFolderIcon = () => (
  <span className="material-icons-outlined text-3xl text-primary-900">
    folder_shared
  </span>
);
export const CheckIcon = () => (
  <span className="material-icons-outlined text-3xl text-primary-900">
    file_download_done
  </span>
);
export const SearchIcon = () => (
  <span className="material-icons-outlined left-0 shrink-0 pr-2 text-2xl text-dark-900/70 md:absolute md:!block">
    search
  </span>
);

export const RightArrowIcon = ({ disabled }: { disabled: boolean }) => (
  <span
    className={twMerge(
      "material-icons-outlined text-dark-900",
      disabled && "opacity-[0.38]"
    )}
  >
    chevron_right
  </span>
);

export const LeftArrowIcon = ({ disabled }: { disabled: boolean }) => (
  <span
    className={twMerge(
      "material-icons-outlined text-dark-900",
      disabled && "opacity-[0.38]"
    )}
  >
    chevron_left
  </span>
);

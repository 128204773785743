import { computed } from "mobx";
import {
  Model,
  model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";
import {
  ActivityLogType,
  Client,
  ProjectCustomLink,
  ProjectMember,
  ProjectMemberEdit,
} from "types";
import Industry from "./Industry";
import TechStack from "./TechStack";

import { ProjectStatus } from "constants/project";
import { roleOptions } from "components/projectDetail/ProjectMembersComponents/Table/common";
import { MultiselectOption } from "components/inputs/Multiselect";

@model("portal/Project")
export default class Project extends Model({
  _id: prop<string>().withSetter(),
  name: prop<string>("").withSetter(),
  status: prop<ProjectStatus>().withSetter(),
  client: prop<Client | null>().withSetter(),
  classification: prop<string>("").withSetter(),
  industries: prop<Industry[]>(() => []).withSetter(),
  techStacks: prop<TechStack[]>(() => []).withSetter(),
  members: prop<ProjectMember[]>(() => []).withSetter(),
  isAllUsersAreMembers: prop<boolean>(false).withSetter(),
  memberEdits: prop<ProjectMemberEdit[]>(() => []).withSetter(),
  isEditing: prop<boolean>(false).withSetter(),
  mattermostChannelUrl: prop<string>("").withSetter(),
  googleDrive: prop<string>("").withSetter(),
  figma: prop<string>("").withSetter(),
  gitlab: prop<string>("").withSetter(),
  redmine: prop<string>("").withSetter(),
  createdAt: prop<string>("").withSetter(),
  updatedAt: prop<string>("").withSetter(),
  logs: prop<ActivityLogType[] | undefined>().withSetter(),
  customLinks: prop<ProjectCustomLink[]>(() => []).withSetter(),
}) {
  @computed
  get isArchived() {
    return this.status === ProjectStatus.Archived;
  }

  @modelAction
  update(data: ModelCreationData<Project>) {
    Object.assign(this, data);
  }

  @computed
  get membersUserIds(): string[] | undefined {
    return this.members
      ?.filter((item) => !item.user.isDeactivated)
      .map((item) => item.user._id);
  }

  @computed
  get availableMemberRoles(): MultiselectOption[] {
    if (!this.memberEdits.length) return [];

    const allLeadRoles = this.memberEdits.reduce<string[]>(
      (allLeadRoles, { roles }) => {
        const nonProxyRoles = [...roles];
        const leadRoles = nonProxyRoles.filter((role) =>
          role.startsWith("Lead")
        );
        return allLeadRoles.concat(leadRoles);
      },
      []
    );
    const uniqueLeadRoles = Array.from(new Set(allLeadRoles));

    const remainingRoles = roleOptions.map((option) => {
      option.errored = uniqueLeadRoles.includes(option.key);
      return option;
    });

    return remainingRoles;
  }

  @modelAction
  beginMemberEdits() {
    const edits: ProjectMemberEdit[] = [];
    this.members.forEach(({ _id, rate, roles }) => {
      const editWithoutProxies: ProjectMemberEdit = {
        _id,
        rate: { ...rate },
        roles: [...roles],
      };

      edits.push(editWithoutProxies);
    });

    this.memberEdits = edits;
  }

  @modelAction
  clearMemberEdits() {
    this.memberEdits = [];
  }

  @modelAction
  updateMemberEdit(memberId: string, update: Partial<ProjectMemberEdit>) {
    const memberIndex = this.memberEdits.findIndex(
      ({ _id }) => _id === memberId
    );
    const edit = this.memberEdits[memberIndex];

    this.memberEdits.splice(memberIndex, 1, { ...edit, ...update });
  }
}

import { observable } from "mobx";
import {
  Model,
  _async,
  _await,
  objectMap,
  model,
  modelFlow,
  prop,
  ModelCreationData,
  modelAction,
} from "mobx-keystone";
import User from "../models/User";

import {
  ErrorMessage,
  API_ERROR_MESSAGES,
  APIError,
} from "types/errors/APIError";
import fetchAPI from "utils/fetchAPI";

@model("portal/UserStore")
export default class UserStore extends Model({
  users: prop(() => objectMap<User>()),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @observable
  loading = false;

  @modelAction
  getOrCreateUser(data: ModelCreationData<User>) {
    const id = `${data._id}`;

    let user: User;
    if (this.users.has(id)) {
      user = this.users.get(id)!;
    } else {
      user = new User(data);
      this.users.set(id, user);
    }

    return user;
  }

  @modelAction
  createOrUpdateUser(data: ModelCreationData<User>) {
    const id = `${data._id}`;

    let user: User;
    if (this.users.has(id)) {
      user = this.users.get(id)!;
      user.update(data);
    } else {
      user = new User(data);
      this.users.set(id, user);
    }

    return user;
  }

  @modelFlow
  fetchOneUser = _async(function* (this: UserStore, id: string) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI("users/profile"));
    } catch {
      this.errors.push({
        source: "fetchOneUser",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    const user = yield* _await(response.json());
    const newUser = new User({
      ...user,
      id: user?._id,
    });

    this.users.set(id, newUser);
    return { details: "Successfully fetched", ok: true };
  });

  @modelFlow
  listUsers = _async(function* (this: UserStore) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI("users/"));
    } catch {
      this.errors.push({
        source: "listUsers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let items: ModelCreationData<User>[];
    ({ results: items } = yield* _await(response.json()));

    const results = items.map((data: ModelCreationData<User>) => {
      return this.createOrUpdateUser(data);
    });

    return { details: "Successfully fetched", ok: true, results };
  });
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useStore } from "stores";
import { ProjectInvoiceSettings } from "types";

import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import { useToast } from "components/toast/context";

import { format } from "date-fns";

type HeaderActionsProps = {
  invoiceSettings: ProjectInvoiceSettings;
};

const InvoiceHeaderActions: React.FC<HeaderActionsProps> = ({
  invoiceSettings,
}) => {
  const { projectStore } = useStore();
  const { showToastVariant } = useToast();

  const { projectId = "" } = useParams<{ projectId: string }>();

  const [invoiceUrl, setInvoiceUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const buttonText = loading ? "Generating Invoice" : "Generate Invoice";

  const { startDate, endDate, splitType, visibleColumns } = invoiceSettings;

  const settingsIncomplete = !(startDate && endDate && splitType?.length);

  const generateInvoice = async () => {
    setLoading(true);

    if (settingsIncomplete) {
      return;
    }

    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    const split = splitType.map((e) => e.key).join(",");
    const columns = visibleColumns?.map((e) => e.key).join(",");

    const query = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      split,
      columns,
    };

    const response = await projectStore.getInvoice(projectId, query);
    const { details, results } = response;
    const { message, url = "" } = results || {};

    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: details,
      });
      setLoading(false);
      return;
    }

    showToastVariant({
      variant: "success",
      title: "Success",
      subtitle: message,
    });
    setInvoiceUrl(url);
    setLoading(false);
  };

  const LinkButton = () => (
    <a href={invoiceUrl} target="_blank" rel="noreferrer">
      <Button category="secondary" className="w-full items-center text-white">
        View Invoice
        <div className="material-icons aspect-square w-[1.125rem] text-xl leading-none text-dark-900/70">
          receipt
        </div>
      </Button>
    </a>
  );

  useEffect(() => {
    projectStore.setLoading(loading);
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className="mt-6 flex gap-2 lg:mt-0">
      {invoiceUrl ? (
        <LinkButton />
      ) : (
        <Button
          category="primary"
          endIcon={loading ? null : "receipt"}
          disabled={settingsIncomplete || loading}
          onClick={async () => {
            await generateInvoice();
          }}
        >
          {buttonText}
          {loading && <LoadingIndicator />}
        </Button>
      )}
    </div>
  );
};

export default InvoiceHeaderActions;

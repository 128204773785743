import { computed } from "mobx";
import {
  Model,
  _async,
  _await,
  model,
  modelFlow,
  objectMap,
  prop,
} from "mobx-keystone";

import { TechStack } from "models";
import {
  APIError,
  API_ERROR_MESSAGES,
  ErrorMessage,
} from "types/errors/APIError";
import { TechStackResponse } from "types/techStack";
import fetchAPI from "utils/fetchAPI";

@model("portal/TechStackStore")
export default class TechStackStore extends Model({
  idToTechStackMap: prop(() => objectMap<TechStack>()),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get techStacks(): TechStack[] {
    return [...this.idToTechStackMap.values()].sort((a, b) =>
      b.category.toLowerCase() < a.category.toLowerCase()
        ? 1
        : b.category.toLowerCase() > a.category.toLowerCase()
        ? -1
        : 0
    );
  }

  @modelFlow
  fetchTechStacks = _async(function* (this: TechStackStore) {
    let response: Response;

    try {
      response = yield* _await(fetchAPI("techStacks"));
    } catch {
      this.errors.push({
        source: "fetchTechStacks",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }

    let results: TechStackResponse[];
    ({ results } = yield* _await(response.json()));

    results.forEach(({ name, _id, category: { name: categoryName } }) => {
      this.idToTechStackMap.set(
        _id,
        new TechStack({ name, _id, category: categoryName })
      );
    });

    return {
      details: "Successfully fetched",
      ok: true,
      results,
    };
  });
}

export const APIError = {
  COULD_NOT_CONNECT: 0,
  COULD_NOT_AUTHENTICATE: 1,
  COULD_NOT_ADD_MEMBER: 2,
  COULD_NOT_REMOVE_MEMBER: 3,
  // ...more
} as const;

export const API_ERROR_MESSAGES: Record<
  number,
  { title: string; message: string }
> = {
  [APIError.COULD_NOT_CONNECT]: {
    title: "Server Connection Error",
    message: "A connection to the server could not be made.",
  },
};

export type ErrorMessage = {
  source: string;
  title: string;
  message: string;
};

import moment from "moment";
import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { Controller, useForm } from "react-hook-form";

import { useStore } from "stores";
import { ProjectMemberEdit, ProjectMember, EditMemberRolesInput } from "types";

import ActionMenu from "../../ActionMenu";
import NumericInput from "../../NumericInput";
import RoleBadge from "../../RoleBadge";

import Avatar from "components/Avatar";
import Multiselect from "components/inputs/Multiselect";
import { formatToUSD } from "utils";
import { useParams } from "react-router-dom";
import { Project } from "models";
import { observer } from "mobx-react-lite";

type TableRowDesktopProps = {
  member: ProjectMember;
  setMember: React.Dispatch<React.SetStateAction<ProjectMember | undefined>>;
  openRateHistoryModal: () => void;
  openRemoveMemberModal: () => void;
};

const TableRowDesktop: React.FC<TableRowDesktopProps> = ({
  member,
  setMember,
  openRateHistoryModal,
  openRemoveMemberModal,
}) => {
  const { authStore, projectStore } = useStore();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const project = projectStore.projects.get(projectId) as Project;

  const { memberEdits, isEditing } = project;
  const memberEdit = memberEdits.find(
    (e) => e._id === member._id
  ) as ProjectMemberEdit;

  const {
    user: { hrPortalId, fullName, mahusaiEmail },
    roles,
    createdAt: joinDate,
    rate: { amount },
  } = member;

  const isCurrentUserProjectMemberOrAdmin =
    authStore.currentUser?.isManager ||
    projectStore.isProjectMember(member.projectId, authStore.currentUser?._id);

  const updateMemberEdits = (update: Partial<ProjectMemberEdit>) => {
    project.updateMemberEdit(member._id, update);
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRate = event.currentTarget.valueAsNumber;
    if (newRate < 0) return;

    updateMemberEdits({ rate: { amount: newRate } });
  };

  const initialPositions = (roles ?? []).map((item) => {
    return {
      key: item,
      label: item,
    };
  });

  const { control, watch } = useForm<EditMemberRolesInput>({
    defaultValues: { selectedPositions: initialPositions },
  });
  const selectedPositions = watch("selectedPositions");

  useEffect(() => {
    const roles = selectedPositions.map((item) => item.label);
    updateMemberEdits({ roles });
    // eslint-disable-next-line
  }, [selectedPositions]);

  const openAndSetMemberForRateHistoryModal = () => {
    setMember(member);
    openRateHistoryModal();
  };

  const openAndSetRemoveMemberModal = () => {
    setMember(member);
    openRemoveMemberModal();
  };

  return (
    <tr className="h-[72px] border-b border-solid border-[#E0E0E0] bg-[#FFFFFF]">
      <td className="flex items-center gap-2 p-4">
        <div className="shrink-0">
          <Avatar user={member.user} width="w-[34px]" fontSize="text-base" />
        </div>
        <div className="flex flex-col">
          <p className="text-sm">{fullName}</p>
          <div className="text-sm text-[#2C4258B2]">{mahusaiEmail}</div>
        </div>
      </td>
      <td className="p-4 text-sm text-slate">
        {isEditing ? (
          <Controller
            name="selectedPositions"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Multiselect
                label="Select Position"
                className="h-14 max-h-36 overflow-y-auto"
                addIconClassName="top-4"
                onChange={onChange}
                options={project.availableMemberRoles}
                value={value}
                isShowLabelAtTop={false}
                optionsAreCheckboxes
                isArrowDropdown
                isShowSelectedInInputField
              />
            )}
          />
        ) : (
          <div className="flex flex-wrap gap-1">
            {(roles ?? []).map((item) => (
              <RoleBadge
                key={item}
                role={item}
                isMultipleValues={roles && roles.length > 1}
              />
            ))}
          </div>
        )}
      </td>

      <td className="p-4 text-sm text-slate">
        {isEditing ? (
          <NumericInput
            onChange={handleRateChange}
            value={memberEdit?.rate.amount}
          />
        ) : (
          formatToUSD(amount)
        )}
      </td>

      <td
        className={twMerge(
          "p-4 text-sm text-slate",
          isEditing && "text-slate/40"
        )}
      >
        {moment(joinDate).format("MMMM DD, YYYY")}
      </td>
      <td className="flex justify-center p-4 text-center">
        <ActionMenu
          isCurrentUserProjectMemberOrAdmin={isCurrentUserProjectMemberOrAdmin}
          disabled={isEditing}
          hrPortalId={hrPortalId}
          key={member._id}
          openRateHistoryModal={openAndSetMemberForRateHistoryModal}
          openRemoveMemberModal={openAndSetRemoveMemberModal}
        />
      </td>
    </tr>
  );
};

export default observer(TableRowDesktop);

import { Button, Popper, useAutocomplete } from "@mui/base";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export type SelectOption = {
  label: string;
  key: string;
};

type SelectProps = {
  label: string;
  onChange: (value: SelectOption | null) => void;
  options?: SelectOption[];
  value: SelectOption | null;
  isRadio?: boolean;
  asyncQuery?: () => Promise<SelectOption[]>;
  queryOnFocus?: Boolean;
};

const POPPER_MARGIN_BOTTOM_PX = 16;

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      label,
      onChange,
      options = [],
      value,
      isRadio = false,
      asyncQuery,
      queryOnFocus = true,
    },
    _ref
  ) => {
    const [selectOptions, setSelectOptions] = useState<SelectOption[]>(options);

    const autocomplete = useAutocomplete<SelectOption>({
      componentName: "Select",
      isOptionEqualToValue: (option, value) => option.key === value.key,
      onChange: () => {},
      options: selectOptions,
      value,
    });

    const anchorRef = React.useRef<HTMLDivElement>(null);

    const getPopperMaxHeight = () => {
      if (anchorRef.current === null) {
        return;
      }
      return (
        anchorRef.current.getBoundingClientRect().top - POPPER_MARGIN_BOTTOM_PX
      );
    };
    const runAsyncQuery = async () => {
      if (queryOnFocus && asyncQuery) {
        const newOptions = await asyncQuery();
        setSelectOptions(newOptions);
      }
    };

    return (
      <div>
        <div className="mb-1">
          <label className="mb-2 text-sm font-semibold">
            <span className="text-slate opacity-70">{label}</span>
          </label>
        </div>
        <div
          {...autocomplete.getRootProps()}
          className="relative flex flex-col"
        >
          <div
            className={twMerge("relative flex", isRadio && "hidden")}
            ref={anchorRef}
          >
            <input
              {...autocomplete.getInputProps()}
              className="color-slate placeholder:color-slate-lighter h-10 max-w-[100%] grow rounded-lg border border-gray-300 px-3 py-2 text-base focus:outline-none"
              placeholder={`Select ${label}`}
              type="text"
              onClick={runAsyncQuery}
            />
            <Button
              {...autocomplete.getPopupIndicatorProps()}
              className="absolute right-2 top-2"
            >
              <span
                className={twMerge(
                  "material-icons text-dark-900 text-opacity-70 transition-transform",
                  autocomplete.popupOpen && "rotate-180"
                )}
              >
                expand_more
              </span>
            </Button>
          </div>
        </div>
        {anchorRef.current && !isRadio && (
          <Popper
            anchorEl={anchorRef.current}
            disablePortal
            open={autocomplete.popupOpen}
            slotProps={{
              root: {
                className:
                  "bg-white rounded-lg box-border z-[510] overflow-y-hidden shadow-dropdown",
              },
            }}
            modifiers={[
              { name: "flip", enabled: false },
              { name: "preventOverflow", enabled: false },
            ]}
          >
            <ul
              {...autocomplete.getListboxProps()}
              className="overflow-y-auto p-2"
              style={{
                width: anchorRef.current.clientWidth,
                maxHeight: getPopperMaxHeight(),
              }}
            >
              {(autocomplete.groupedOptions as SelectOption[]).map(
                (option, index) => {
                  const optionProps = autocomplete.getOptionProps({
                    option,
                    index,
                  });
                  return (
                    <li
                      {...optionProps}
                      className={twMerge(
                        "cursor-pointer truncate rounded-lg px-3 py-2",
                        option.key === value?.key && "bg-[#C8DA2B14]"
                      )}
                      key={option.key}
                      onClick={(event) => {
                        optionProps.onClick!(event);
                        option.key !== value?.key
                          ? onChange(option)
                          : onChange(null);
                      }}
                    >
                      {option.label}
                    </li>
                  );
                }
              )}
              {autocomplete.groupedOptions.length === 0 && (
                <li className="truncate rounded-lg px-3 py-2">No results</li>
              )}
            </ul>
          </Popper>
        )}
        {isRadio && (
          <ul className="flex w-full flex-row gap-4 overflow-y-auto pr-2">
            {selectOptions.map((item) => (
              <div key={item.key}>
                <input
                  className="cursor-pointer"
                  type="radio"
                  value={item.key}
                  checked={item.key === value?.key}
                  onChange={(event) => {
                    if (event.target.checked) onChange(item);
                  }}
                />
                <label
                  className="ml-2 cursor-pointer text-sm font-semibold"
                  onClick={() => onChange(item)}
                >
                  <span className="text-slate">{item.label}</span>
                </label>
              </div>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

export default Select;

import { add, endOfMonth, endOfWeek, isSameDay, startOfWeek } from "date-fns";

export type CalendarItem = {
  date: Date;
};

export function generateCalendar(month: number, year: number) {
  const monthStart = new Date(year, month, 1);
  let [start, end] = [
    startOfWeek(monthStart),
    endOfWeek(endOfMonth(monthStart)),
  ];

  const result: CalendarItem[] = [];

  while (!isSameDay(start, add(end, { days: 1 }))) {
    result.push({ date: start });
    start = add(start, { days: 1 });
  }

  return result;
}

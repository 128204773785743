import { Modal } from "@mui/base";
import Button from "components/Button";
import Fade from "components/transitions/Fade";
import { FC } from "react";
import { useStore } from "stores";
import Backdrop from "./Backdrop";
import { useToast } from "components/toast/context";
import { ProjectMember } from "types";

type RemoveMemberModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  memberToBeRemoved?: ProjectMember;
};

const RemoveMemberModal: FC<RemoveMemberModalProps> = ({
  open,
  setOpen,
  projectId,
  memberToBeRemoved,
}) => {
  const close = () => setOpen(false);
  const { projectStore } = useStore();

  const { showToastVariant } = useToast();

  const removeUser = async () => {
    const response = await projectStore.removeProjectMembers(projectId, [
      memberToBeRemoved?._id || "",
    ]);

    if (!response.ok) {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: `Unable to remove member`,
      });
      return;
    }

    close();
    showToastVariant({
      variant: "success",
      title: "Success",
      subtitle: "Member successfully removed",
    });
  };

  const { user: { fullName = "" } = {} } = memberToBeRemoved || {};

  return (
    <Fade open={open}>
      <Modal
        className="fixed inset-0 z-[500] flex items-center justify-center"
        disableAutoFocus
        disablePortal
        onClose={close}
        open
        slots={{
          backdrop: Backdrop,
        }}
      >
        <div className="fixed bottom-0 w-screen rounded-2xl bg-white shadow-dialog !outline-none md:static md:w-[600px]">
          <div className="box-content flex h-11 items-center border-b border-gray-300 px-4 py-2">
            <div className="text-primary flex grow flex-col gap-5 text-lg font-semibold">
              Remove User
            </div>
            <button
              className="material-icons p-3 text-[20px] text-dark-900 text-opacity-70"
              onClick={close}
            >
              close
            </button>
          </div>
          <div className="p-4">
            <p className="text-slate/70">
              Are you sure you want to remove{" "}
              <span className="font-semibold text-slate">{fullName || ""}</span>
              ? This action will result in the following consequences:
            </p>
            <ul className="list-disc p-6 text-sm text-slate/70">
              <li>The member will be removed from the Mattermost channel.</li>
              <li>Access to the GDrive folder will be revoked.</li>
              <li>
                However, please note that the member will NOT be removed from
                the Redmine project to retain logs.
              </li>
            </ul>
            <p className="text-slate/70">
              Please confirm if you want to proceed with the removal.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 border-t p-4">
            <Button category="primaryOutline" onClick={close}>
              Cancel
            </Button>
            <Button category="danger" onClick={removeUser}>
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </Fade>
  );
};

export default RemoveMemberModal;
